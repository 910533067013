import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ChatWritingUI from "../ui/ChatWritingUI";
import Typewriter from "typewriter-effect";

function WelcomeBanner({ user, createWriting }) {
  const { t } = useTranslation();
  const generateGreetings = () => {
    var currentHour = moment().format("HH");

    if (currentHour >= 3 && currentHour < 12) {
      return t("Good Morning");
    } else if (currentHour >= 12 && currentHour < 15) {
      return t("Good Afternoon");
    } else if (currentHour >= 15 && currentHour < 20) {
      return t("Good Evening");
    } else if (currentHour >= 20 && currentHour < 3) {
      return t("Good Night");
    } else {
      return t("Hello");
    }
  };

  return (
    <button
      onClick={() => {
        createWriting();
      }}
      className="w-full text-left flex flex-col sm:flex-row sm:justify-between items-center relative bg-gradient-to-r from-sky-500 to-indigo-500 p-6 rounded-lg overflow-hidden mb-8 gap-4"
    >
      <div className="w-full relative text-start">
        <h1 className="text-2xl md:text-3xl text-white font-bold mb-1">
          {generateGreetings()}, {user?.firstName}. 👋
        </h1>
        <span className="text-2xl sm:text-4xl">
          <Typewriter
            options={{
              strings: [
                t("Want to sell a course?"),
                t("Want to sell a workshop?"),
                t("Want to sell a product?"),
                t("Want to sell some snacks?"),
              ],
              autoStart: true,
              loop: true,
              delay: 50,
              deleteSpeed: 0,
              pauseFor: 3000,
              wrapperClassName: "text-white font-bold",
            }}
          />
        </span>
        <p className="text-xl text-white">
          {t("Click here to try our latest AI Copywriter")}!
        </p>
      </div>
      <div className="hidden sm:block w-full h-fit bg-white rounded-lg">
        <ChatWritingUI />
      </div>
    </button>
  );
}

export default WelcomeBanner;
