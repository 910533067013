import { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import ProcessingBtn from "../../components/btn/ProcessingBtn";
import axios from "../../api/axios";
import endpoints from "../../api/endpoints";

import Warning from "../../components/alert/Warning";
import Danger from "../../components/alert/Danger";
import useAuth from "../../hooks/useAuth";

// the hook
import { useTranslation } from "react-i18next";

const SignInComponent = ({ callback }) => {
  // i18n
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [warning, setWarning] = useState();
  const { setAuth } = useAuth();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const loginHandler = async (values) => {
    setLoading(true);
    try {
      const res = await axios.post(
        endpoints.LOGIN_URL,
        JSON.stringify(values),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      setAuth(res.data);

      // Return to previous page
      if (callback) {
        callback(res.data);
      }
    } catch (err) {
      if (!err?.response) {
        console.error("No Server res");
      } else {
        // API Error
        let error = err?.response?.data?.error;

        if (error === "WRONG_PASSWORD") {
          setWarning(<Warning title="Wrong Password" />);
        } else if (error === "USER_DOES_NOT_EXIST") {
          setWarning(<Warning title={t("User does not exist")} />);
        } else if (error === "BLOCKED_USER") {
          setWarning(<Warning title={t("User is blocked")} />);
        } else if (error === "EMAIL_NOT_VERIFIED") {
          setWarning(<Warning title={t("Please verify your email")} />);
        } else if (err.code === "ERR_NETWORK") {
          setWarning(<Danger title={t("No Network")} />);
        } else {
          setWarning(<Danger title={t("Invalid Login")} />);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      // rememberMe: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("This is not a valid email"))
        .required(t("This field is required")),
      password: Yup.string().trim().required(t("This field is required")),
      // rememberMe: Yup.boolean(),
    }),
    onSubmit: loginHandler,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <h1 className="text-3xl text-neutral-800 dark:text-white font-bold mb-6">
        {t("Sign In")} ✨
      </h1>

      {/* Warning */}
      {warning}
      <div className="space-y-4">
        <div>
          <label
            className="block text-sm font-medium mb-1 text-neutral-800 dark:text-white"
            htmlFor="email"
          >
            {t("Email")}
          </label>
          <input
            name="email"
            className="form-input w-full text-lg sm:text-base dark:text-white dark:bg-neutral-800 dark:border-neutral-600"
            type="string"
            value={formik.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <p className="text-red-600 text-sm">
            {formik.errors.email && formik.touched.email
              ? `${t("Error")}: ${formik.errors.email}`
              : null}
          </p>
        </div>
        <div>
          <label
            className="block text-sm font-medium mb-1 text-neutral-800 dark:text-white"
            htmlFor="password"
          >
            {t("Password")}
          </label>
          <div className="relative flex items-center justify-end">
            <input
              name="password"
              className="form-input w-full text-lg sm:text-base dark:text-white dark:bg-neutral-800 dark:border-neutral-600 pr-10"
              type={passwordVisible ? "text" : "password"}
              autoComplete="on"
              value={formik.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <button
              className="absolute right-[10px]"
              type="button"
              onClick={togglePasswordVisibility}
            >
              {passwordVisible ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 dark:text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 dark:text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              )}
            </button>
          </div>
          <p className="text-red-600 text-sm">
            {formik.errors.password && formik.touched.password
              ? `${t("Error")}: ${formik.errors.password}`
              : null}
          </p>
        </div>
      </div>
      <div className="flex items-center justify-end mt-6">
        <div className="mr-1">
          <Link
            className="text-sm underline hover:no-underline text-neutral-800 dark:text-white"
            to="/reset-password"
          >
            {t("Forgot Password?")}
          </Link>
        </div>
      </div>
      <div className="flex items-center justify-between mt-6">
        {!isLoading && (
          <button
            type="submit"
            className="btn bg-sky-500 hover:bg-sky-600 text-white w-full"
          >
            {t("Sign In")}
          </button>
        )}
        {isLoading && <ProcessingBtn title={t("Sign In")} />}
      </div>
    </form>
  );
};

export default SignInComponent;
