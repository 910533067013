import { useTranslation } from "react-i18next";
import { useLocalStorage } from "@mantine/hooks";

function ThemePanel(props) {
  const { t } = useTranslation();

  const [theme, setTheme] = useLocalStorage({
    key: "theme",
    defaultValue: "",
  });

  const setThemeMode = (mode) => {
    setTheme(mode);

    // On page load or when changing themes, best to add inline in `head` to avoid FOUC
    if (
      localStorage.theme.includes("dark") ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  };

  const getActiveCSS = (key) => {
    if (theme === key) {
      return "bg-sky-500 hover:bg-sky-600 text-white";
    } else {
      return "text-neutral-800 hover:bg-neutral-50 dark:hover:bg-neutral-800 dark:text-white dark:hover:text-neutral-100 space-x-2";
    }
  };

  return (
    <div id="plans" className="border-gray-200 shadow p-4 rounded-lg">
      {/* Plans */}
      <section>
        <div>
          <h2 className="text-2xl text-neutral-800 dark:text-white font-bold mb-4">
            {t("Themes")}
          </h2>
          <div className="flex items-center space-x-2">
            <button
              onClick={() => {
                setThemeMode("dark");
              }}
              className={`flex items-center w-fit rounded-md px-4 py-2 text-sm font-medium ${getActiveCSS(
                "dark"
              )}`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z"
                />
              </svg>
              <div className="text-base font-bold">{t("Dark Mode")}</div>
            </button>
            <button
              onClick={() => {
                setThemeMode("light");
              }}
              className={`flex items-center w-fit rounded-md px-4 py-2 text-sm font-medium ${getActiveCSS(
                "light"
              )}`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                />
              </svg>

              <div className="text-base font-bold">{t("Light Mode")}</div>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ThemePanel;
