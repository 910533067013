import { useTranslation } from "react-i18next";

function LanguagePanel(props) {
  const { t, i18n } = useTranslation();

  const getActiveCSS = (lang) => {
    if (i18n.language === lang) {
      return "bg-sky-500 hover:bg-sky-600 text-white";
    } else {
      return "text-neutral-800 hover:bg-neutral-50 dark:hover:bg-neutral-800 dark:text-white dark:hover:text-neutral-100 space-x-2";
    }
  };

  return (
    <div id="plans" className="border-gray-200 shadow p-4 rounded-lg">
      <section>
        <div>
          <h2 className="text-2xl text-neutral-800 dark:text-white font-bold mb-4">
            {t("Language")}
          </h2>
          <div className="flex items-center space-x-2">
            <button
              onClick={() => {
                i18n.changeLanguage("en");
              }}
              className={`flex items-center w-fit rounded-md px-4 py-2 text-sm font-medium ${getActiveCSS(
                "en"
              )}`}
            >
              <div className="text-base font-bold">{t("English")}</div>
            </button>
            <button
              onClick={() => {
                i18n.changeLanguage("zh");
              }}
              className={`flex items-center w-fit rounded-md px-4 py-2 text-sm font-medium ${getActiveCSS(
                "zh"
              )}`}
            >
              <div className="text-base font-bold">{t("繁體中文")}</div>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default LanguagePanel;
