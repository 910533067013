import React, { useState } from "react";
import DeleteWritingModal from "./DeleteWritingModal";
import DeleteWritingPreview from "../../components/DeleteWritingPreview";
import moment from "moment";
import { useTranslation } from "react-i18next";

function WritingCardTrash({ key, writing, deleteFunction, putbackFunction }) {
  const [dangerModalOpen, setDangerModalOpen] = useState(false);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);

  const { t } = useTranslation();

  const renderType = (date) => {
    const createDate = new Date(date);
    const fromNow = moment(createDate).fromNow();

    return { css: "bg-amber-100 text-amber-600", fromNow };
  };

  const deleteWriting = (e) => {
    e.stopPropagation();
    setDangerModalOpen(true);
  };

  const putbackWriting = (e) => {
    e.stopPropagation();
    putbackFunction(writing.id);
  };

  return (
    <div
      key={key}
      className="group col-span-full sm:col-span-6 xl:col-span-4 rounded-lg bg-white dark:bg-neutral-800 ring-1 ring-neutral-900/5 shadow-lg space-y-3 hover:ring-4 hover:ring-sky-500 ease-in-out duration-150"
    >
      {/* Preview Trash Modal */}
      <DeleteWritingPreview
        modalOpen={previewModalOpen}
        setModalOpen={setPreviewModalOpen}
        writing={{ title: writing.title, content: writing.content }}
        deleteWriting={deleteWriting}
        putbackWriting={putbackWriting}
      />

      {/* Modal */}
      <DeleteWritingModal
        id={writing.id}
        deleteFunction={deleteFunction}
        open={dangerModalOpen}
        setOpen={setDangerModalOpen}
      />
      <div className="flex flex-col h-full p-5 pt-0">
        <div className="grow mt-2">
          <button
            onClick={(e) => {
              e.stopPropagation();
              setPreviewModalOpen(true);
            }}
            className="text-left text-neutral-800 dark:text-white hover:text-neutral-900 mb-1"
            to={writing.link}
          >
            <h2 className="text-xl leading-snug font-semibold">
              {writing.title || t("Unitled document")}
            </h2>
            <p className="text-sm text-left line-clamp-3">
              {writing.content || t("No content")}
            </p>
          </button>
        </div>
        <footer>
          <div className="flex justify-between items-center">
            <div>
              <div className="m-1.5">
                {/* Restore Icon */}
                {/* Start */}
                <button
                  onClick={putbackWriting}
                  className="btn border-neutral-200 invisible group-hover:visible hover:border-neutral-300 dark:hover:border-neutral-600 hover:scale-105 transition ease-in-out duration-150"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    className="w-4 h-4 fill-current text-teal-500 shrink-0"
                  >
                    <path d="M18.885 3.515c-4.617-4.618-12.056-4.676-16.756-.195l-2.129-2.258v7.938h7.484l-2.066-2.191c2.82-2.706 7.297-2.676 10.073.1 4.341 4.341 1.737 12.291-5.491 12.291v4.8c3.708 0 6.614-1.244 8.885-3.515 4.686-4.686 4.686-12.284 0-16.97z" />
                  </svg>
                </button>
                {/* End */}
              </div>
            </div>
            <div>
              <div
                className={`text-xs inline-flex invisible group-hover:visible font-medium rounded-full text-center px-2.5 py-1 ${
                  renderType(writing.updatedAt).css
                }`}
              >
                Deleted {renderType(writing.updatedAt).fromNow}
              </div>
            </div>
            <div>
              <div className="m-1.5">
                {/* Delete Icon */}
                {/* Start */}
                <button
                  onClick={deleteWriting}
                  className="btn border-neutral-200 invisible group-hover:visible hover:border-neutral-300 dark:hover:border-neutral-600 hover:scale-105 transition ease-in-out duration-150"
                >
                  <svg
                    className="w-4 h-4 fill-current text-rose-500 shrink-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5 7h2v6H5V7zm4 0h2v6H9V7zm3-6v2h4v2h-1v10c0 .6-.4 1-1 1H2c-.6 0-1-.4-1-1V5H0V3h4V1c0-.6.4-1 1-1h6c.6 0 1 .4 1 1zM6 2v1h4V2H6zm7 3H3v9h10V5z" />
                  </svg>
                </button>
                {/* End */}
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default WritingCardTrash;
