import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import useAuth from "../../hooks/useAuth";
import models from "../../partials/ai/modelSelection";

import DeleteMessageModal from "../../partials/modals/DeleteMessageModal";
import AddYoutubeVideoPrompt from "../../partials/modals/AddYoutubeVideoPrompt";
import CreateSystemPromptModal from "../../partials/modals/CreateSystemPromptModal";
import EditSystemPromptModal from "../../partials/modals/EditSystemPromptModal";
import PremiumLockModal from "../../partials/modals/PremiumLockModal";
import SelectSystemPromptModal from "../../partials/modals/SelectSystemPromptModal";

import MessagesSidebar from "../../partials/messages/MessagesSidebar";
import MessagesHeader from "../../partials/messages/MessagesHeader";
import MessagesBody from "../../partials/messages/MessagesBody";
import MessagesFooter from "../../partials/messages/MessagesFooter";
import MessagesBodyEmpty from "../../partials/messages/MessagesBodyEmpty";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";
import { useLocalStorage } from "@mantine/hooks";

function Chatroom() {
  const contentArea = useRef(null);

  const { t } = useTranslation();

  // ID from url
  const { id } = useParams();
  const navigate = useNavigate();

  const [isProcessing, setIsProcessing] = useState(false);
  const [isProcessingInternet, setIsProcessingInternet] = useState(false);
  const [isProcessingSearchQuery, setIsProcessingSearchQuery] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [msgSidebarOpen, setMsgSidebarOpen] = useState(false);

  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [createSystemPromptModalOpen, setCreateSystemPromptModalOpen] =
    useState(false);
  const [youtubeOpen, setYoutubeOpen] = useState(false);
  const [deleteMessageModalOpen, setDeleteMessageModalOpen] = useState(false);
  const [premiumLockModalOpen, setPremiumLockModalOpen] = useState(false);
  const [systemPromptLibraryOpen, setSystemPromptLibraryOpen] = useState(false);

  const [quota, setQuota] = useState();

  const [model, setModel] = useLocalStorage({
    key: "model",
    defaultValue: "chatgpt",
  });

  const [deleteMsgIdx, setDeleteMsgIdx] = useState();

  const [useInternet, setUseInternet] = useLocalStorage({
    key: "useInternet",
    defaultValue: false,
  });

  const [temperature, setTemperature] = useLocalStorage({
    key: "temperature",
    defaultValue: 0.7,
  });

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  // Get User Profile
  const { auth } = useAuth();
  const user = auth.user;
  const [chatrooms, setChatrooms] = useState([]);
  const [chat, setChat] = useState();

  const [prompts, setPrompts] = useState([]);

  // Fetch Writing
  const fetchQuota = async () => {
    try {
      const res = await axiosPrivate.get(endpoints.PROFILE_URL + "/quota", {
        signal: controller.signal,
      });

      // Update title
      setQuota(res.data);

      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  const getPrompts = async () => {
    try {
      const res = await axiosPrivate.get(endpoints.PROMPTS_URL);
      setPrompts(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getChatrooms = async () => {
    const res = await axiosPrivate.get(endpoints.CHAT_URL, {
      signal: controller.signal,
    });

    setChatrooms(res.data);
  };

  const checkPremium = () => {
    const modelObj = models.find((item) => item.key === model);

    // Select Model
    if (modelObj.premium === true) {
      // Check if user is premium
      if (!user.isPremium) {
        setPremiumLockModalOpen(true);
        throw new Error("User is not premium");
      }
    }
  };

  const getChat = async () => {
    try {
      const res = await axiosPrivate.get(endpoints.CHAT_URL + "/" + id, {
        signal: controller.signal,
      });

      setChat(res.data);
    } catch (e) {
      navigate("/chat");
    }
  };

  const deleteChatRoomHandler = async (chatRoomId) => {
    await axiosPrivate.delete(endpoints.CHAT_URL + "/" + chatRoomId, {
      signal: controller.signal,
    });

    // Remove from chatrooms
    const newChatrooms = chatrooms.filter((chatroom) => {
      return chatroom.id !== chatRoomId;
    });

    setChatrooms(newChatrooms);

    if (chatRoomId === id) {
      navigate("/chat");
    }
  };

  const [stream, setStream] = useState("");
  const [streamIdx, setStreamIdx] = useState(-1);

  const messagesEndRef = React.createRef();

  const scrollToBottom = (useSmooth = false) => {
    messagesEndRef.current?.scrollIntoView({
      behavior: useSmooth ? "smooth" : "instant",
    });
  };

  const runGPT = async (messages, temperature, promptId) => {
    const modelObj = models.find((item) => item.key === model);

    // Select Model
    if (modelObj.premium === true) {
      // Check if user is premium
      if (!user.isPremium) {
        setPremiumLockModalOpen(true);
        return;
      }
    }

    // Add User values to chat
    let finalStream = "";

    let endpoint = endpoints.AI_CHAT_URL;

    // Remove all the messages where role === 'system' except the first
    messages = messages.filter((msg, idx) => {
      if (msg.role === "system" && idx !== 0) {
        return false;
      }

      return true;
    });

    // Remove all the message where msg.content !== true
    messages = messages.filter((msg) => {
      return msg?.content;
    });

    await axiosPrivate.post(
      endpoint,
      { messages, temperature, promptId, model: modelObj.key },
      {
        withCredentials: false,
        onDownloadProgress: (progressEvent) => {
          finalStream = "";

          const dataChunk = progressEvent.target.response;

          let concatedPayload = dataChunk.split("data: ");

          // Filter out empty strings
          concatedPayload = concatedPayload.filter((str) => str.trim() !== "");

          for (const line of concatedPayload) {
            const jsonString = line;
            try {
              if (jsonString.trim() !== "[DONE]") {
                const dataObj = JSON.parse(jsonString);

                if (dataObj?.choices && dataObj.choices.length > 0) {
                  const delta = dataObj.choices[0].delta.content;

                  if (delta) {
                    finalStream += delta;

                    if (finalStream !== "") {
                      setStream(finalStream);
                    }
                  }
                }
              }
            } catch (error) {
              console.log("err: ", error);

              if (dataChunk) {
                const dataChunkObj = JSON.parse(dataChunk);
                const errCode = dataChunkObj.error.code;
                if (errCode) {
                  toast.error(t(errCode));
                }
              }

              if (line?.errors) {
                toast.error(line.errors.message);
              } else {
                console.log(error);
              }
            }
          }
        },
      }
    );

    return finalStream;
  };

  const updateChatHandler = async (chat) => {
    // Update locally
    setChat(chat);

    if (id) {
      const payload = {
        id,
        messages: chat.messages,
      };

      if (chat?.prompt?.code) {
        payload.promptCode = chat.prompt.code;
      }

      // Update Backend
      await axiosPrivate.patch(endpoints.CHAT_URL + "/" + id, payload, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
    }
  };

  const submitHandler = async (values) => {
    if (isProcessing) return;

    // Check premium
    checkPremium();

    setStreamIdx(-1);
    setStream("");
    setSearchQuery("");
    setIsProcessing(true);

    try {
      // Check if user has quota
      const quota = await fetchQuota();
      if (user?.isPremium !== true && quota.remainingQuota <= 0) {
        setPremiumLockModalOpen(true);
        return;
      }

      // Reset Form
      formik.resetForm({ msg: "" });

      let chatHistory = {
        ...chat,
        messages: [],
      };

      if (id) {
        let newChat = chat;

        // Add user to chat thread
        newChat.messages.push({
          role: "user",
          content: values.msg,
        });

        setChat(newChat);

        chatHistory = newChat;

        // Scroll to bottom
        scrollToBottom();
      } else {
        chatHistory.messages.push({ role: "user", content: values.msg });

        setChat({ ...chatHistory, title: values.msg });
      }

      const content = await runGPT(
        chatHistory.messages,
        temperature,
        chatHistory?.prompt?.code
      );

      if (!content) {
        throw new Error("Something went wrong");
      }

      chatHistory.messages.push({ role: "assistant", content });

      // Update chat
      let res;
      if (id) {
        // Update in Backend
        res = await axiosPrivate.patch(
          endpoints.CHAT_URL + "/" + id,
          {
            id,
            messages: chatHistory.messages,
            title: chat?.title,
          },
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
      } else {
        res = await axiosPrivate.post(
          endpoints.CHAT_URL,
          { messages: chatHistory.messages, title: values.msg },
          {
            withCredentials: true,
          }
        );
      }

      // Update chat
      setChat({
        title: res.data.title,
        messages: res.data.messages,
        promptCode: res.data.promptCode,
      });

      await getChatrooms();

      // Redirect
      if (!id) {
        navigate("/chat/" + res.data.id);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsProcessing(false);
      setStream("");
    }
  };

  const retryHandler = async (msgIdx) => {
    if (isProcessing) return;

    // Check if user has quota
    const quota = await fetchQuota();
    if (user?.isPremium !== true && quota.remainingQuota <= 0) {
      setPremiumLockModalOpen(true);
      return;
    }

    // Check premium
    checkPremium();

    setStream("");
    setSearchQuery("");
    setIsProcessing(true);

    try {
      // Get message history until id
      const messageHistory = chat.messages.filter((_, idx) => {
        return idx < msgIdx;
      });

      // Update streamIdx
      setStreamIdx(messageHistory.length);

      const content = await runGPT(
        messageHistory,
        temperature,
        chat?.prompt?.code
      );

      if (!content) {
        throw new Error("Something went wrong");
      }

      // TODO: Update chat locally
      chat.messages[msgIdx].content = content;

      await updateChatHandler(chat);
    } catch (err) {
      console.log(err);
    } finally {
      setStreamIdx(-1);
      setIsProcessing(false);
      setStream("");
    }
  };

  // When runGPT is called, scroll to bottom
  useEffect(() => {
    // Check if msgIdx is the last message
    if (
      chat &&
      (streamIdx === chat?.messages?.length - 1 || streamIdx === -1)
    ) {
      scrollToBottom(true);
    }
  }, [stream]);

  useEffect(() => {
    // Chat is updated
    scrollToBottom(true);
  }, [chat]);

  const deleteMsgHandler = async () => {
    if (deleteMsgIdx === null || deleteMsgIdx === undefined) return;

    // Remove message in side message with idx = msgIdx
    chat.messages.splice(deleteMsgIdx, 1);

    // Update chat
    await updateChatHandler(chat);

    setDeleteMsgIdx();
  };

  const editChatHandler = async (msgIdx, newMsg, autoSubmit = true) => {
    // Get message history until id
    const messageHistory = chat.messages.filter((_, idx) => {
      return idx < msgIdx;
    });

    let content = "";
    if (autoSubmit === true) {
      // Check if user has quota
      const quota = await fetchQuota();
      if (user?.isPremium !== true && quota.remainingQuota <= 0) {
        setPremiumLockModalOpen(true);
        return;
      }

      setIsProcessing(true);
      // Update message history
      messageHistory.push({
        role: "user",
        content: newMsg,
      });

      // Update streamIdx
      setStreamIdx(messageHistory.length);

      // Reset Stream
      setStream("");

      content = await runGPT(messageHistory, temperature, chat?.prompt?.code);
    }

    // Update user prompt
    chat.messages[msgIdx].content = newMsg;

    if (autoSubmit) {
      // Update response
      chat.messages[msgIdx + 1].content = content;
    }

    // update chat
    await updateChatHandler(chat);

    if (autoSubmit === true) {
      setIsProcessing(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      msg: "",
    },
    validationSchema: Yup.object({
      msg: Yup.string().required(t("This field is required")),
    }),
    validateOnChange: false,
    onSubmit: submitHandler,
  });

  const init = async () => {
    try {
      await getChatrooms();
      await getPrompts();
      await fetchQuota();
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.error?.msg === "CHATROOM_NOT_FOUND") {
        // Redirect to /chat
        navigate("/chat");
      }
    }
  };

  const selectChatSystemPrompt = async (prompt) => {
    const newChat = {
      ...chat,
      prompt,
    };

    if (prompt && newChat?.messages) {
      newChat.messages = newChat.messages.filter((msg) => {
        return msg.role !== "system";
      });
    }

    await updateChatHandler(newChat);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    // Focus on input
    if (id) {
      getChat();
    } else {
      setChat();
    }
  }, [id]);

  return (
    <div className="flex">
      {/* Content area */}
      <div
        className="dark:bg-neutral-900 relative flex flex-col flex-1"
        ref={contentArea}
      >
        <main>
          <PremiumLockModal
            open={premiumLockModalOpen}
            setOpen={setPremiumLockModalOpen}
          />

          <DeleteMessageModal
            open={deleteMessageModalOpen}
            setOpen={setDeleteMessageModalOpen}
            deleteMsgHandler={deleteMsgHandler}
          />

          <AddYoutubeVideoPrompt
            formik={formik}
            open={youtubeOpen}
            setOpen={setYoutubeOpen}
          />

          <CreateSystemPromptModal
            prompts={prompts}
            setPrompts={setPrompts}
            open={createSystemPromptModalOpen}
            setOpen={setCreateSystemPromptModalOpen}
          />

          <EditSystemPromptModal
            prompts={prompts}
            setPrompts={setPrompts}
            getPrompts={getPrompts}
            open={settingsModalOpen}
            setOpen={setSettingsModalOpen}
          />

          <SelectSystemPromptModal
            open={systemPromptLibraryOpen}
            setOpen={setSystemPromptLibraryOpen}
            prompts={prompts}
            chat={chat}
            selectChatSystemPrompt={selectChatSystemPrompt}
          />

          <div className="z-0 relative flex">
            {/* Messages sidebar */}
            <MessagesSidebar
              msgSidebarOpen={msgSidebarOpen}
              setMsgSidebarOpen={setMsgSidebarOpen}
              chatrooms={chatrooms}
              setChatrooms={setChatrooms}
              id={id}
              deleteChatRoomHandler={deleteChatRoomHandler}
              user={user}
            />

            <MessagesHeader
              user={user}
              setPremiumLockModalOpen={setPremiumLockModalOpen}
              msgSidebarOpen={msgSidebarOpen}
              setMsgSidebarOpen={setMsgSidebarOpen}
              setSettingsModalOpen={setSettingsModalOpen}
              title={chat?.title || t("AI Chat")}
              model={model}
              setModel={setModel}
            />

            {/* Messages body */}
            <div className="w-full -z-10 flex flex-col items-center transform transition-transform duration-300 ease-in-out pt-20">
              {chat && chat?.messages && (
                <MessagesBody
                  chat={chat}
                  selectChatSystemPrompt={selectChatSystemPrompt}
                  user={user}
                  isProcessing={isProcessing}
                  useInternet={useInternet}
                  isProcessingInternet={isProcessingInternet}
                  isProcessingSearchQuery={isProcessingSearchQuery}
                  stream={stream}
                  streamIdx={streamIdx}
                  messagesEndRef={messagesEndRef}
                  searchQuery={searchQuery}
                  scrollToBottom={scrollToBottom}
                  model={model}
                  retryHandler={retryHandler}
                  editChatHandler={editChatHandler}
                  setSystemPromptLibraryOpen={setSystemPromptLibraryOpen}
                  setDeleteMsgIdx={(idx) => {
                    setDeleteMsgIdx(idx);
                    setDeleteMessageModalOpen(true);
                  }}
                />
              )}
              {!chat?.messages && (
                <MessagesBodyEmpty
                  chat={chat}
                  setChat={selectChatSystemPrompt}
                  setOpen={setSystemPromptLibraryOpen}
                />
              )}
              <MessagesFooter
                chat={chat}
                isProcessing={isProcessing}
                setYoutubeOpen={setYoutubeOpen}
                formik={formik}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Chatroom;
