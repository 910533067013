import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";
import ProcessingBtn from "../../components/btn/ProcessingBtn";
import moment from "moment";
import { useTranslation } from "react-i18next";

export default function CreateCouponModal({
  isModalOpen,
  setIsModalOpen,
  refresh,
}) {
  function closeModal() {
    setIsModalOpen(false);
  }

  const { t } = useTranslation();
  const [warning, setWarning] = useState();
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const [isLoading, setLoading] = useState(false);

  const submitHandler = async (values) => {
    setLoading(true);
    try {
      if (!values.hasExpiry) {
        delete values.expireOn;
      }

      await axiosPrivate.post(endpoints.PROMOCODES_URL, values, {
        signal: controller.signal,
      });

      // Refresh
      await refresh();

      // Toast
      toast.success(`Coupon "${values.code}" created successfully`);

      // Close the modal
      setIsModalOpen(!isModalOpen);

      // Clear form
      formik.resetForm();
    } catch (err) {
      if (!err?.response) {
        toast.error("No Server res");
      } else {
        // API Error
        let error = err?.response?.data?.error.msg;
        toast.error(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      code: "",
      qty: 0,
      remark: "",
      hasLimit: true,
      premiumDays: 30,
      expireOn: moment().add(7, "days"),
      hasExpiry: false,
    },
    validationSchema: Yup.object({
      code: Yup.string().trim().required(t("This field is required")),
      qty: Yup.number(t("Must be number"))
        .integer(t("Must be integer"))
        .min(0, t("Must be greater than 0")),
      hasLimit: Yup.boolean().required(t("This field is required")),
      premiumDays: Yup.number()
        .nullable(true)
        .when("type", {
          is: "premium",
          then: Yup.number(t("Must be number"))
            .integer(t("Must be integer"))
            .min(1, t("Must be greater than 0"))
            .required(t("This field is required")),
        }),
      expireOn: Yup.date().optional(),
      hasExpiry: Yup.boolean().required(t("This field is required")),
    }),
    onSubmit: submitHandler,
  });

  return (
    <>
      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={closeModal}>
          {/* The backdrop, rendered as a fixed sibling to the panel container */}
          <div
            className="fixed inset-0 backdrop-blur-sm backdrop-brightness-75 transition-opacity"
            aria-hidden="true"
          />
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:text-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6"
                  >
                    {t("Create Coupon")}
                  </Dialog.Title>
                  {/* Warning */}
                  {warning !== null && <div className="py-2">{warning}</div>}

                  {/* Form */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="space-y-4">
                      <div>
                        <label
                          className="block text-sm font-medium mb-1"
                          htmlFor="code"
                        >
                          {t("Code")}
                          <span className="text-rose-500">*</span>
                        </label>
                        <input
                          name="code"
                          className="form-input w-full text-lg sm:text-base dark:text-white dark:bg-neutral-800 dark:border-neutral-600"
                          type="string"
                          value={formik.values.code}
                          onChange={(e) => {
                            // Turn to upper case
                            e.target.value = e.target.value.toUpperCase();
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                        />
                        <p className="text-red-600 text-sm">
                          {formik.errors.code && formik.touched.code
                            ? `Error: ${formik.errors.code}`
                            : null}
                        </p>
                      </div>

                      <div>
                        <label
                          className="block text-sm font-medium mb-1"
                          htmlFor="remark"
                        >
                          {t("Remark")}
                        </label>
                        <input
                          name="remark"
                          className="form-input w-full text-lg sm:text-base dark:text-white dark:bg-neutral-800 dark:border-neutral-600"
                          type="string"
                          value={formik.values.remark}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <p className="text-red-600 text-sm">
                          {formik.errors.remark && formik.touched.remark
                            ? `Error: ${formik.errors.remark}`
                            : null}
                        </p>
                      </div>

                      <div>
                        <label
                          className="block text-sm font-medium mb-1"
                          htmlFor="premiumDays"
                        >
                          {t("Premium Days")}
                        </label>
                        <input
                          name="premiumDays"
                          className="form-input w-full text-lg sm:text-base dark:text-white dark:bg-neutral-800 dark:border-neutral-600"
                          type="string"
                          value={formik.values.premiumDays}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <p className="text-red-600 text-sm">
                          {formik.errors.premiumDays &&
                          formik.touched.premiumDays
                            ? `Error: ${formik.errors.premiumDays}`
                            : null}
                        </p>
                      </div>
                      <div>
                        <div className="flex items-center space-x-4">
                          <label
                            className="block text-sm font-medium mb-1"
                            htmlFor="hasExpiry"
                          >
                            {t("Will Expired")}
                          </label>
                          <div className="form-switch">
                            <input
                              name="hasExpiry"
                              type="checkbox"
                              id="hasExpiry"
                              className="sr-only"
                              checked={formik.values.hasExpiry}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <label className="bg-slate-400" htmlFor="hasExpiry">
                              <span
                                className="bg-white shadow-sm"
                                aria-hidden="true"
                              ></span>
                              <span className="sr-only">
                                {t("Will Expired")}
                              </span>
                            </label>
                          </div>
                        </div>
                        <p className="text-red-600 text-sm">
                          {formik.errors.hasExpiry && formik.touched.hasExpiry
                            ? `Error: ${formik.errors.hasExpiry}`
                            : null}
                        </p>
                      </div>
                      <div>
                        <div className="flex items-center space-x-4">
                          <label
                            className="block text-sm font-medium mb-1"
                            htmlFor="code"
                          >
                            {t("Limited Qty")}
                          </label>
                          <div className="form-switch">
                            <input
                              name="hasLimit"
                              type="checkbox"
                              id="hasLimit"
                              className="sr-only"
                              checked={formik.values.hasLimit}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <label className="bg-slate-400" htmlFor="hasLimit">
                              <span
                                className="bg-white shadow-sm dark:text-white dark:bg-neutral-800 dark:border-neutral-600"
                                aria-hidden="true"
                              ></span>
                              <span className="sr-only">
                                {t("Limited Qty")}
                              </span>
                            </label>
                          </div>
                        </div>
                        <p className="text-red-600 text-sm">
                          {formik.errors.hasLimit && formik.touched.hasLimit
                            ? `Error: ${formik.errors.hasLimit}`
                            : null}
                        </p>
                      </div>
                      {formik.values.hasLimit && (
                        <div>
                          <label
                            className="block text-sm font-medium mb-1"
                            htmlFor="qty"
                          >
                            {t("Qty")}
                          </label>
                          <input
                            name="qty"
                            className="form-input w-full text-lg sm:text-base dark:text-white dark:bg-neutral-800 dark:border-neutral-600"
                            type="number"
                            value={formik.values.qty}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <p className="text-red-600 text-sm">
                            {formik.errors.qty && formik.touched.qty
                              ? `Error: ${formik.errors.qty}`
                              : null}
                          </p>
                        </div>
                      )}
                      {formik.values.hasExpiry && (
                        <div>
                          <label
                            className="block text-sm font-medium mb-1"
                            htmlFor="expireOn"
                          >
                            {t("Expiry Day")}
                          </label>
                          <input
                            name="expireOn"
                            className="form-input w-full text-lg sm:text-base dark:text-white dark:bg-neutral-800 dark:border-neutral-600"
                            type="date"
                            value={formik.values.expireOn}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <p className="text-red-600 text-sm">
                            {formik.errors.expireOn && formik.touched.expireOn
                              ? `Error: ${formik.errors.expireOn}`
                              : null}
                          </p>
                        </div>
                      )}
                    </div>

                    <div className="flex items-center justify-between mt-6 space-x-4">
                      <button
                        type="button"
                        onClick={() => setIsModalOpen(!isModalOpen)}
                        className="btn bg-slate-500 hover:bg-slate-600 dark:bg-slate-600 dark:hover:bg-slate-700 text-white w-full"
                      >
                        {t("Close")}
                      </button>
                      {!isLoading && (
                        <button
                          type="submit"
                          className="btn bg-sky-500 hover:bg-sky-600 dark:bg-sky-600 dark:hover:bg-sky-700 text-white rounded-lg w-full"
                        >
                          {t("Create Coupon")}
                        </button>
                      )}
                      {isLoading && <ProcessingBtn title="Create Coupon" />}
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
