import TextareaAutosize from "react-textarea-autosize";
import { useTranslation } from "react-i18next";
import { Disclosure } from "@headlessui/react";
import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useDebouncedValue } from "@mantine/hooks";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import endpoints from "../../../api/endpoints";

export default function FormPanel({
  systemPrompt,
  setSystemPrompt,
  prompts,
  product,
  valid,
  setProduct,
  runGPT,
  isAILoading,
  limit,
  user,
  setPremiumLockModalOpen,
  LoadingIcon,
}) {
  const { t } = useTranslation();

  const scrollToRef = useRef(null);

  const axiosPrivate = useAxiosPrivate();

  // useDebouncedValue
  const [debouncedValue, setDebouncedValue] = useDebouncedValue(
    product.businessInfo,
    500
  );

  const [subcat, setSubcat] = useState(systemPrompt?.subcat || "");

  const setGPTModel = (model) => {
    if (!user.isPremium) {
      setPremiumLockModalOpen(true);
      return;
    }
    setProduct({ ...product, model });
  };

  const getUniqueCats = () => {
    return [...new Set(prompts.map((item) => item.subcat))] || [];
  };

  const getSubcatItems = () => {
    return prompts.filter((item) => item.subcat === subcat);
  };

  const loadDefaultBusinessInfo = () => {
    let defaultBusinessInfo = `
商戶名稱: Articue
Instagram: @articue.io
簡介: 我們是一個專業的文案生成工具，幫助你快速生成各種文案
產品: 文案生成AI
目標客戶: 有需要快速產生文案的人，小編，小商戶
特色: 專為中文而設、快速、簡單、多種模板選擇、無需VPN`;

    defaultBusinessInfo = defaultBusinessInfo.trim();

    setProduct({ ...product, businessInfo: defaultBusinessInfo });
  };

  const scrollToSelectedTemplate = () => {
    scrollToRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  };

  const updateBusinessInfo = async () => {
    try {
      await axiosPrivate.patch(
        endpoints.PROFILE_URL,
        {
          businessInfo: product.businessInfo ?? "",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (err) {
      console.error(err);
      toast.error(t("Failed to update business info"));
    }
  };

  useEffect(() => {
    if (systemPrompt?.subcat) {
      setSubcat(systemPrompt.subcat);
    }
  }, [systemPrompt]);

  useEffect(() => {
    if (prompts.length > 0) {
      // check if systemPrompt is null
      if (!systemPrompt) {
        // get 一般產品 prompt
        const generalPrompt = prompts.find(
          (item) => item.code === "ig_product"
        );
        setSystemPrompt(generalPrompt);
      }
    }
  }, [prompts]);

  useEffect(() => {
    // scroll to ref
    if (scrollToRef.current) {
      // scroll to top of parent div
      scrollToSelectedTemplate();
    }
  }, [subcat, systemPrompt]);

  useEffect(() => {
    if (Object.keys(product).length === 0) return;
    updateBusinessInfo();
  }, [debouncedValue]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (!product) return;
        if (!valid) return;
        if (!systemPrompt?.id) {
          toast.error(t("Please select a writing template"));
          return;
        }
        runGPT();
      }}
    >
      <div className="pb-4 z-40">
        {/* Select Product Template */}
        <div className="mb-2 flex items-center justify-between">
          <div className="text-lg dark:text-white font-bold mb-2">
            {t("Writing Template")}
          </div>

          {systemPrompt?.title && (
            <div className="mb-2 inline-flex items-center px-2.5 py-0.5 rounded-md font-medium bg-sky-100 text-sky-800">
              {t("Selected")}: {systemPrompt.title}
            </div>
          )}
        </div>

        <div className="flex-wrap space-y-2 mb-2">
          {getUniqueCats().map((cat, idx) => {
            return (
              <button
                key={idx}
                type="button"
                onClick={() => {
                  setSubcat(cat);
                  scrollToSelectedTemplate();
                }}
                className={`text-sm font-medium transition-all mr-2 ${
                  cat === subcat ? "bg-purple-500 text-white" : "bg-white"
                } px-8 py-1 rounded-full shadow`}
              >
                {t(cat)}
              </button>
            );
          })}
        </div>

        <div className="flex flex-col items-center gap-2 mb-2 pb-2 max-h-52 overflow-scroll">
          {getSubcatItems().map((item, idx) => {
            return (
              <button
                key={idx}
                type="button"
                onClick={() => {
                  setSystemPrompt(item);
                }}
                className={`flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium ${
                  item.code === systemPrompt?.code
                    ? "bg-purple-500 text-white"
                    : "bg-white"
                } px-8 py-1 rounded-full shadow`}
                ref={item.code === systemPrompt?.code ? scrollToRef : null}
              >
                <span className="font-bold">{item.title}</span>{" "}
                <span className="hidden sm:block">({item.description})</span>
              </button>
            );
          })}
        </div>
      </div>

      <div className="pb-4 z-40">
        {/* Name */}
        <div className="mb-2 flex items-center justify-between">
          <div className="text-lg dark:text-white font-bold mb-2">
            {t("Title")}
          </div>

          {/* Name word counter */}
          <div
            className={`text-sm font-medium transition-all bg-white px-3 py-1 rounded-full shadow ${
              product?.name?.length > limit.name && "bg-red-100 text-red-600"
            }`}
          >
            {product?.name?.length || 0}/{limit.name}
          </div>
        </div>

        <div className="sm:flex w-full">
          <input
            type="text"
            className="w-full px-5 py-2 border border-neutral-300 shadow-sm placeholder-neutral-400 dark:bg-neutral-800 dark:border-neutral-600 dark:text-white rounded-lg"
            placeholder={`e.g. ${systemPrompt?.example || "輸入你的產品名稱"}`}
            value={product?.name || ""}
            onChange={(e) => {
              setProduct({ ...product, name: e.target.value });
            }}
          />
        </div>
      </div>
      <div className="pb-4 z-40">
        {/* Extra Prompt */}
        <div className="flex items-center justify-between">
          <div className="text-lg dark:text-white font-bold mb-2">
            {t("Extra Prompt")}
            <button
              type="button"
              onClick={() => {
                if (user.isPremium) return;
                setPremiumLockModalOpen(true);
              }}
              className="ml-2 text-sm inline-flex items-center px-2.5 py-0.5 rounded-md font-medium bg-purple-100 text-purple-800"
            >
              {t("Premium")}
            </button>
          </div>

          {/* Word counter */}
          <div
            className={`text-sm font-medium transition-all bg-white px-3 py-1 rounded-full shadow ${
              product?.extraPrompt?.length > limit.extraPrompt &&
              "bg-red-100 text-red-600"
            }`}
          >
            {product?.extraPrompt?.length || 0}/{limit.extraPrompt}
          </div>
        </div>

        <div className="sm:flex w-full">
          <textarea
            type="text"
            className={`w-full px-5 py-2 border border-neutral-300 shadow-sm placeholder-neutral-400 dark:bg-neutral-800 dark:border-neutral-600 dark:text-white rounded-lg resize-none  ${
              !user.isPremium ? "text-neutral-400 cursor-not-allowed" : ""
            }`}
            disabled={!user.isPremium}
            value={product?.extraPrompt || ""}
            rows={4}
            onChange={(e) => {
              setProduct({
                ...product,
                extraPrompt: e.target.value,
              });
            }}
            placeholder={`e.g. 寫150字，用新假期語氣，用一個日常生活故事包裝文案`}
          />
        </div>
      </div>
      <div className="pb-4 z-40">
        {/* My Business Info */}
        <div className="flex items-center justify-between">
          <div className="text-lg dark:text-white font-bold mb-2">
            {t("My Business Info")}
          </div>

          <div className="flex items-center space-x-1">
            {/* Load Default Button */}
            <button
              type="button"
              onClick={() => {
                loadDefaultBusinessInfo();
              }}
              className={`text-sm font-medium transition-all bg-white px-3 py-1 rounded-full shadow`}
            >
              {t("Use Default Value")}
            </button>

            {/* Word counter */}
            <div
              className={`text-sm font-medium transition-all bg-white px-3 py-1 rounded-full shadow ${
                product?.businessInfo?.length > limit.businessInfo &&
                "bg-red-100 text-red-600"
              }`}
            >
              {product?.businessInfo?.length || 0}/{limit.businessInfo}
            </div>
          </div>
        </div>

        <div className="sm:flex w-full">
          <textarea
            type="text"
            className={`w-full px-5 py-2 border border-neutral-300 shadow-sm placeholder-neutral-400 dark:bg-neutral-800 dark:border-neutral-600 dark:text-white rounded-lg resize-none`}
            value={product?.businessInfo || ""}
            rows={8}
            onChange={(e) => {
              setProduct({
                ...product,
                businessInfo: e.target.value,
              });
            }}
            placeholder="e.g. 商戶名稱、Instagram、簡介etc"
          />
        </div>
      </div>
      <div className="pb-4 z-40">
        {/* Select Model */}
        <div className="flex items-center justify-between">
          <div className="text-lg dark:text-white font-bold mb-2">
            {t("Select Model")}
            <button
              type="button"
              onClick={() => {
                if (user.isPremium) return;
                setPremiumLockModalOpen(true);
              }}
              className="ml-2 text-sm inline-flex items-center px-2.5 py-0.5 rounded-md font-medium bg-purple-100 text-purple-800"
            >
              {t("Premium")}
            </button>
          </div>

          <div className="space-x-2">
            <button
              type="button"
              onClick={() => {
                setGPTModel("genie");
              }}
              className={`text-sm font-medium transition-all ${
                product?.model === "genie"
                  ? "bg-purple-500 text-white"
                  : "bg-white"
              } px-8 py-1 rounded-full shadow`}
            >
              {t("Genie AI")}
            </button>
            <button
              type="button"
              onClick={() => {
                setGPTModel("");
              }}
              className={`text-sm font-medium transition-all ${
                product?.model !== "genie"
                  ? "bg-purple-500 text-white"
                  : "bg-white"
              } px-8 py-1 rounded-full shadow`}
            >
              {t("Default")}
            </button>
          </div>
        </div>
      </div>
      <Disclosure>
        <Disclosure.Button className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-bold text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
          <span>{t("Show Premium Settings")}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 ui-open:rotate-90 ui-open:transform transition-all"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </Disclosure.Button>
        <Disclosure.Panel className="pt-2">
          {/* Additional Information */}
          <div className="pb-4 z-40">
            <div className="flex items-center justify-between">
              <div className="text-lg dark:text-white font-bold mb-2">
                {t("Additional Information")}

                <button
                  type="button"
                  onClick={() => {
                    if (user.isPremium) return;
                    setPremiumLockModalOpen(true);
                  }}
                  className="ml-2 text-sm inline-flex items-center px-2.5 py-0.5 rounded-md font-medium bg-purple-100 text-purple-800"
                >
                  {t("Premium")}
                </button>
              </div>

              {/* Word counter */}
              <div
                className={`text-sm font-medium transition-all bg-white px-3 py-1 rounded-full shadow ${
                  product?.description?.length > limit.description &&
                  "bg-red-100 text-red-600"
                }`}
              >
                {product?.description?.length || 0}/{limit.description}
              </div>
            </div>
            <div className="mb-2">
              <span className="text-sm font-bold">
                Tips:{" "}
                {t("1-2 sentences to express the flow of your copywriting")}
              </span>
            </div>
            <div className="sm:flex w-full">
              <TextareaAutosize
                type="text"
                className={`w-full px-5 py-2 border border-neutral-300 shadow-sm placeholder-neutral-400 dark:bg-neutral-800 dark:border-neutral-600 dark:text-white rounded-lg resize-none ${
                  !user.isPremium ? "text-neutral-400 cursor-not-allowed" : ""
                }`}
                value={product?.description || ""}
                minRows={4}
                maxRows={8}
                disabled={!user.isPremium}
                onChange={(e) => {
                  setProduct({
                    ...product,
                    description: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          {/* Brand Tone */}
          <div className="pb-4 z-40">
            <div className="mb-2 flex items-center justify-between">
              <div className="text-lg dark:text-white font-bold mb-2">
                {t("Brand Tone")}

                <button
                  type="button"
                  onClick={() => {
                    if (user.isPremium) return;
                    setPremiumLockModalOpen(true);
                  }}
                  className="ml-2 text-sm inline-flex items-center px-2.5 py-0.5 rounded-md font-medium bg-purple-100 text-purple-800"
                >
                  {t("Premium")}
                </button>
              </div>

              {/* Word counter */}
              <div
                className={`text-sm font-medium transition-all bg-white px-3 py-1 rounded-full shadow ${
                  product?.brandTone?.length > limit.brandTone &&
                  "bg-red-100 text-red-600"
                }`}
              >
                {product?.brandTone?.length || 0}/{limit.brandTone}
              </div>
            </div>

            <div className="sm:flex w-full">
              <TextareaAutosize
                type="text"
                className={`w-full px-5 py-2 border border-neutral-300 shadow-sm placeholder-neutral-400 dark:bg-neutral-800 dark:border-neutral-600 dark:text-white rounded-lg resize-none ${
                  !user.isPremium ? "text-neutral-400 cursor-not-allowed" : ""
                }`}
                value={product?.brandTone || ""}
                minRows={4}
                maxRows={8}
                disabled={!user.isPremium}
                onChange={(e) => {
                  setProduct({
                    ...product,
                    brandTone: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        </Disclosure.Panel>
      </Disclosure>

      <div className="mt-3 rounded-md shadow">
        {valid ? (
          <button
            type="submit"
            disabled={isAILoading === true}
            className={`w-full flex items-center justify-center py-3 px-5 border border-transparent text-base rounded-lg text-white font-bold ${
              isAILoading === true ? "bg-black/20 cursor-not-allow" : "bg-black"
            }`}
          >
            {t("Generate")}
            {isAILoading && (
              <LoadingIcon className="ml-2 w-4 h-4 animate-spin fill-white group-hover:fill-white" />
            )}
          </button>
        ) : (
          <span
            className={`w-full flex items-center justify-center py-3 px-5 border border-transparent text-base rounded-lg text-white font-bold ${
              true ? "bg-black/20 cursor-not-allow" : "bg-black"
            }`}
          >
            {t("Generate")}
            {isAILoading && (
              <LoadingIcon className="ml-2 w-4 h-4 animate-spin fill-white group-hover:fill-white" />
            )}
          </span>
        )}
      </div>
    </form>
  );
}
