import React from "react";
import { ReactComponent as LoadingIcon } from "../images/Loader.svg";

const Loader = () => {
  return (
    <div className="h-screen w-full flex justify-center items-center bg-transparent">
      <LoadingIcon className="w-10 h-10 animate-spin fill-sky-500 group-hover:fill-white" />
    </div>
  );
};

export default Loader;
