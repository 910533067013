import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";

function WritingCard({
  writing,
  isLoading,
  selectDocs,
  checkedIds,
  setCheckedIds,
}) {
  const { t } = useTranslation();

  const renderMain = () => {
    return (
      <>
        <div className="w-full relative flex flex-col justify-between h-full p-5">
          <div className="grow mt-2">
            <div className="inline-flex text-neutral-800 hover:text-neutral-900 mb-1">
              <h2 className="text-xl leading-snug line-clamp-1 font-semibold text-black dark:text-white">
                {writing?.product?.name
                  ? writing?.product?.name
                  : writing?.title || t("Untitled")}
              </h2>
            </div>
            <p className="text-sm text-left line-clamp-5 dark:text-neutral-200">
              <span className="font-medium mr-2">
                {moment(writing.updatedAt).format("DD-MM-YYYY")}
              </span>
              <span>{writing.content || t("No content")}</span>
            </p>
          </div>
        </div>
      </>
    );
  };

  if (isLoading) {
    return (
      <div className="animate-pulse h-20 sm:h-48 group col-span-full sm:col-span-6 xl:col-span-4 rounded-lg bg-white dark:bg-neutral-800 ring-1 ring-neutral-900/5 shadow-lg space-y-3 hover:ring-4 hover:ring-sky-500 ease-in-out duration-150"></div>
    );
  } else if (selectDocs) {
    const isSelected = checkedIds.includes(writing.id);

    return (
      <button
        onClick={() => {
          if (isSelected) {
            setCheckedIds(checkedIds.filter((id) => id !== writing.id));
          } else {
            setCheckedIds([...checkedIds, writing.id]);
          }
        }}
        className={`text-start flex items-center justify-center h-48 group col-span-full sm:col-span-6 xl:col-span-4 rounded-lg bg-white dark:bg-neutral-800 shadow-lg space-y-3 ease-in-out duration-150 ${
          isSelected ? "ring-4 ring-sky-500" : "hover:ring-4 ring-neutral-400"
        }`}
      >
        {renderMain()}
        <div
          className={`absolute h-20 w-20 rounded-full group-hover:scale-105 ${
            isSelected ? "bg-sky-500" : "bg-white"
          } border-4 border-sky-500 flex items-center justify-center shadow transition-all`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={3}
            stroke="currentColor"
            className={`w-10 h-10 ${
              isSelected ? "text-white" : "text-sky-500"
            }`}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 12.75l6 6 9-13.5"
            />
          </svg>
        </div>
      </button>
    );
  } else {
    return (
      <Link
        to={`/docs/${writing.id}`}
        className="h-48 group col-span-full sm:col-span-6 xl:col-span-4 rounded-lg bg-white dark:bg-neutral-800 ring-1 ring-neutral-900/5 shadow-lg space-y-3 hover:ring-4 hover:ring-sky-500 ease-in-out duration-150"
      >
        {renderMain()}
      </Link>
    );
  }
}

export default WritingCard;
