import { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { useState } from "react";

import axios from "axios";
import { getYoutubeVidFromUrl } from "../../utils/Utils";
import { useDebouncedValue } from "@mantine/hooks";
import { ReactComponent as LoadingIcon } from "../../images/Loader.svg";

export default function AddYoutubeVideoPrompt({ open, setOpen, formik }) {
  // i18n
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [caption, setCaption] = useState("");
  const [urlDebounce] = useDebouncedValue(url, 1000, { leading: true });
  const [error, setError] = useState("");

  const getYoutubeCaption = async (vid, lang) => {
    setLoading(true);
    setError("");
    try {
      const res = await axios.get(
        `https://yt-caption.edge.articue.io/?vid=${vid}${
          lang ? `&lang=${lang}` : ""
        }`
      );

      setCaption(res.data);
    } catch (err) {
      console.log("error");
      if (err.response.data.msg);
      setError(err.response.data.msg);
      setCaption("");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (urlDebounce === url && url) {
      const vid = getYoutubeVidFromUrl(url);

      // Get ?lang=xx from urlDebounce
      const urlParams = new URLSearchParams(urlDebounce);
      const lang = urlParams.get("lang");

      if (vid) {
        getYoutubeCaption(vid, lang);
      } else {
        setError(t("Invalid Youtube URL"));
      }
    }
  }, [url, urlDebounce]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-center justify-center min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 backdrop-blur-sm backdrop-brightness-75 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block bg-white dark:bg-neutral-900 rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-3xl w-full p-6">
              <div>
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center space-x-2">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-black dark:text-white"
                    >
                      {t("Import Youtube Video")}
                    </Dialog.Title>
                  </div>
                  <button
                    onClick={() => setOpen(false)}
                    className="hover:scale-105 transition-all"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="mt-4">
                <input
                  className="mt-2 shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-neutral-300 rounded-md resize-none"
                  type="text"
                  name="url"
                  value={url}
                  placeholder="https://www.youtube.com/watch?v=..."
                  onChange={(e) => {
                    setUrl(e.target.value);
                  }}
                  autoComplete="off"
                />
              </div>
              {/* Loading  */}
              {loading && (
                <div className="mt-4 h-48 w-full flex justify-center items-center overflow-auto">
                  <LoadingIcon className="w-10 h-10 animate-spin fill-sky-500 group-hover:fill-white" />
                </div>
              )}

              {!loading && caption && (
                <>
                  <div className="mt-4 h-48 overflow-auto py-4">{caption}</div>
                  <div className="mt-4 flex justify-between">
                    <button
                      type="button"
                      onClick={() => {
                        formik.setFieldValue("msg", caption);
                        setOpen(false);
                      }}
                      className="w-full flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sky-500 hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                    >
                      {t("Import")}
                    </button>
                  </div>
                </>
              )}

              {error && (
                <div className="mt-4 rounded-md bg-red-50 p-4">
                  <div className="flex">
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-800">
                        {error}
                      </h3>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
