import Typewriter from "typewriter-effect";
import { useTranslation } from "react-i18next";

export default function Component() {
  const { t } = useTranslation();

  return (
    <div className="text-left w-full relative flex flex-col gap-y-4 p-4 rounded-lg h-52">
      {t("Yelo Partyroom")}
      <Typewriter
        options={{
          strings: [
            t(
              "Ever dreamt of a place where you can eat, drink, and party to your heart's content without worrying about disturbing anyone? Yelo Partyroom is the perfect solution for you!"
            ),
          ],
          autoStart: true,
          loop: true,
          delay: 30,
          deleteSpeed: 0,
          pauseFor: 10000,
          wrapperClassName: "bg-teal-400/30",
        }}
      />
    </div>
  );
}
