import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";

import useAuth from "../../hooks/useAuth";

import AccountPanel from "../../partials/settings/AccountPanel";
import SecurityPanel from "../../partials/settings/SecurityPanel";
import PlansPanel from "../../partials/settings/PlansPanel";
import ThemePanel from "../../partials/settings/ThemePanel";
import LanguagePanel from "../../partials/settings/LanguagePanel";

function Settings() {
  const { t } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // Get User Profile
  const { auth } = useAuth();
  const user = auth.user;

  return (
    <div className="flex overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        user={user}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />

      {/* Content area */}
      <div className="md:h-screen relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
          title={t("Account Settings")}
          customBtn={
            // Go to previous
            <button
              onClick={() => {
                // if the previous page is the same domain, go back, otherwise, go to '/'
                if (document.referrer.includes(window.location.origin)) {
                  window.history.back();
                } else {
                  window.location.href = "/";
                }
              }}
              className="text-neutral-500 hover:text-neutral-600 dark:text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                />
              </svg>
            </button>
          }
        />

        <main className="pb-20">
          <div className="px-4 sm:px-6 lg:px-8 pb-8 sm:py-8 w-full max-w-9xl mx-auto">
            {/* Content */}
            <div className="bg-white dark:bg-neutral-900 dark:text-white mb-8">
              <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
                <AccountPanel user={user} />
                <SecurityPanel user={user} />
                <PlansPanel user={user} />
                <ThemePanel />
                <LanguagePanel />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Settings;
