import { ReactComponent as OpenAILogo } from "../../images/vectors/openai-logo.svg";
import { ReactComponent as MetaLogo } from "../../images/vectors/meta-logo.svg";

const models = [
  // {
  //   name: "OpenAI GPT-4 (32K)",
  //   key: "gpt4-32k",
  //   description:
  //     "Latest and greatest from OpenAI. The most powerful AI model ever created. 32K Token version support longer input length than GPT-4 base model (8K token).",
  //   premium: true,
  //   icon: OpenAILogo,
  // },
  {
    name: "OpenAI GPT-4o",
    key: "gpt4",
    description:
      "Latest and greatest from OpenAI. The most powerful AI model ever created.",
    premium: true,
    icon: OpenAILogo,
  },
  // {
  //   name: "OpenAI ChatGPT (16K)",
  //   key: "chatgpt-16k",
  //   description:
  //     "Fast and general-purpose chatbot. Can handle most of the daily tasks. 16K Token version support longer input length than ChatGPT base model (4K token)",
  //   premium: true,
  //   icon: OpenAILogo,
  // },
  {
    name: "OpenAI ChatGPT",
    key: "chatgpt",
    description:
      "Fast and general-purpose chatbot. Can handle most of the daily tasks.",
    premium: false,
    icon: OpenAILogo,
  },
];

export default models;
