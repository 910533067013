import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../images/logo/Logo.svg";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "@mantine/hooks";

const AuthHeader = (props) => {
  const { t, i18n } = useTranslation();
  // Dark Mode
  const [theme, setTheme] = useLocalStorage({
    key: "theme",
    defaultValue: "",
  });

  const setThemeMode = (mode) => {
    setTheme(mode);

    // On page load or when changing themes, best to add inline in `head` to avoid FOUC
    if (
      localStorage.theme.includes("dark") ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  };
  return (
    <div className="flex-1">
      <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
        {/* Logo */}
        <Link className="block" to="/">
          <Logo className="h-8 w-8" />
        </Link>

        <div className="flex items-center gap-x-2">
          <button
            onClick={() => {
              setThemeMode(theme !== "dark" ? "dark" : "light");
            }}
            className="inline-flex justify-center rounded-md px-4 py-2 text-sm font-medium text-neutral-800 hover:bg-neutral-50 dark:hover:bg-neutral-800 dark:text-white dark:hover:text-neutral-100"
          >
            {/* Sun */}
            {theme !== "dark" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                />
              </svg>
            )}

            {/* Moon */}
            {theme === "dark" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z"
                />
              </svg>
            )}
          </button>

          {/* i18n language switch */}
          <button
            className="inline-flex justify-center rounded-md px-4 py-2 text-sm font-medium text-neutral-800 hover:bg-neutral-50 dark:hover:bg-neutral-800 dark:text-white dark:hover:text-neutral-100"
            onClick={() =>
              i18n.changeLanguage(i18n.language === "en" ? "zh" : "en")
            }
          >
            {i18n.language === "en" ? t("繁體中文") : t("English")}
          </button>
        </div>
      </div>
      {/* Dynamic Title */}
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{props.title || t("Home")} | Articue</title>
        </Helmet>
      </HelmetProvider>
    </div>
  );
};

export default AuthHeader;
