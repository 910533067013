import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function PromptMenu({ setYoutubeOpen }) {
  const { t } = useTranslation();

  return (
    <Link
      to="/chat"
      className="inline-flex justify-center gap-x-1 items-center px-3 py-2 text-sm font-medium rounded-lg sm:bg-indigo-100 sm:hover:bg-indigo-200 sm:hover:text-indigo-700 sm:text-indigo-600 transition-all mr-2"
    >
      <span className="hidden sm:block whitespace-nowrap">{t("New Chat")}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={3}
        stroke="currentColor"
        className="h-5 w-5 dark:text-white sm:dark:text-indigo-600"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 4.5v15m7.5-7.5h-15"
        />
      </svg>
    </Link>
  );

  // Deprecated
  // return (
  //   <div className="text-right">
  //     <Menu as="div" className="relative inline-block text-left">
  //       <div>
  //         <Menu.Button className="inline-flex justify-center gap-x-4 items-center px-3 py-2 text-sm leading-4 font-medium rounded-md dark:text-white mr-2">
  //           <svg
  //             xmlns="http://www.w3.org/2000/svg"
  //             fill="none"
  //             viewBox="0 0 24 24"
  //             strokeWidth={1.5}
  //             stroke="currentColor"
  //             className="h-5 w-5"
  //           >
  //             <path
  //               strokeLinecap="round"
  //               strokeLinejoin="round"
  //               d="M12 4.5v15m7.5-7.5h-15"
  //             />
  //           </svg>
  //         </Menu.Button>
  //       </div>
  //       <Transition
  //         as={Fragment}
  //         enter="transition ease-out duration-100"
  //         enterFrom="transform opacity-0 scale-95"
  //         enterTo="transform opacity-100 scale-100"
  //         leave="transition ease-in duration-75"
  //         leaveFrom="transform opacity-100 scale-100"
  //         leaveTo="transform opacity-0 scale-95"
  //       >
  //         <Menu.Items className="absolute bottom-10 mt-2 w-96 origin-bottom-left divide-y divide-neutral-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
  //           <div className="px-1 py-1 ">
  //             <Menu.Item>
  //               <button
  //                 onClick={() => {
  //                   setYoutubeOpen(true);
  //                 }}
  //                 className={`hover:bg-sky-400 hover:text-white text-neutral-900 group flex w-full items-center rounded-md px-2 py-2 text-sm`}
  //               >
  //                 <svg
  //                   xmlns="http://www.w3.org/2000/svg"
  //                   fill="none"
  //                   viewBox="0 0 24 24"
  //                   strokeWidth={1.5}
  //                   stroke="currentColor"
  //                   className="mr-2 h-5 w-5"
  //                 >
  //                   <path
  //                     strokeLinecap="round"
  //                     d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z"
  //                   />
  //                 </svg>

  //                 {t("Import Youtube Video")}
  //               </button>
  //             </Menu.Item>
  //           </div>
  //         </Menu.Items>
  //       </Transition>
  //     </Menu>
  //   </div>
  // );
}
