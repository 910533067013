import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import UserMenu from "../../components/DropdownProfile";

import useAuth from "../../hooks/useAuth";
import { ReactComponent as Logo } from "../../images/logo/Logo.svg";
import useRefreshToken from "../../hooks/useRefreshToken";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";
// the hook
import { useTranslation } from "react-i18next";

export default function PaySuccess() {
  // i18n
  const { t } = useTranslation();

  const refresh = useRefreshToken();

  const [searchParams] = useSearchParams();
  const [isVerifying, setIsVerifying] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState();

  const { auth } = useAuth();
  const user = auth?.user;

  const axiosPrivate = useAxiosPrivate();

  const sessionId = searchParams.get("sessionId");

  const verifyPayment = async () => {
    if (!sessionId) return;
    setIsVerifying(true);
    try {
      const res = await axiosPrivate.post(
        `${endpoints.PLAN_URL}/verify-payment`,
        {
          sessionId,
        }
      );

      setPaymentInfo(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsVerifying(false);
      await refresh();
    }
  };

  useEffect(() => {
    verifyPayment();
  }, [searchParams]);

  return (
    <>
      {/* Header */}
      <header className="bg-white/80 dark:bg-neutral-900/80 backdrop-blur-sm">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16 -mb-px">
            {/* Logo */}
            <Link className="block" to="/">
              {/* Logo */}
              <Logo className="h-8 w-8" />
            </Link>

            <div className="flex items-center gap-x-4 dark:text-white">
              {/* User Profile */}
              <UserMenu align="right" user={user} />

              <Link
                className="block rounded-full text-neutral-500 hover:text-neutral-600 dark:text-white"
                to="/"
              >
                <span className="sr-only">{t("Back")}</span>
                <svg width="32" height="32" viewBox="0 0 32 32">
                  <path
                    className="fill-current"
                    d="M15.95 14.536l4.242-4.243a1 1 0 111.415 1.414l-4.243 4.243 4.243 4.242a1 1 0 11-1.415 1.415l-4.242-4.243-4.243 4.243a1 1 0 01-1.414-1.415l4.243-4.242-4.243-4.243a1 1 0 011.414-1.414l4.243 4.243z"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </header>

      <main>
        <div className="relative px-4 sm:p-8 max-w-3xl mx-auto">
          <div className="bg-white sm:px-8 p-6 rounded-lg sm:shadow-lg">
            <div className="relative flex flex-col py-4 mb-6 gap-y-10">
              <span className="inline-block">
                <Link to="/pay/" className="float-left">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={3}
                    stroke="currentColor"
                    className="w-6 h-6 text-neutral-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                    />
                  </svg>
                </Link>
                <h1 className="text-2xl text-center leading-snug text-neutral-800 font-bold">
                  {isVerifying ? (
                    <>{t("Verifying Payment")}...</>
                  ) : paymentInfo?.status === "complete" ? (
                    t("Payment Successful")
                  ) : (
                    t("Payment Failed")
                  )}
                </h1>
              </span>

              {!isVerifying &&
                (paymentInfo?.status === "complete" ? (
                  <>{t("You have successfully subscribed")}:</>
                ) : (
                  t(
                    "We have encountered an error while processing your payment"
                  )
                ))}

              {/* Show product */}
              {!isVerifying && paymentInfo && (
                <div className="w-full flex justify-between items-center sm:px-2">
                  <span as="span" className="black block text-sm font-medium">
                    <div className="text-xl font-bold">
                      {paymentInfo?.product.name}
                    </div>
                  </span>

                  {/* Price */}
                  <div className="flex flex-col items-start text-md font-semibold gap-x-1">
                    <div>
                      <span className="text-xl whitespace-nowrap">
                        {paymentInfo?.product.currency}
                        {paymentInfo?.product.price}
                      </span>
                      <span className="text-neutral-500">
                        {paymentInfo?.product.unit}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              {/* Back to Home */}
              <Link
                to="/"
                className="btn w-full bg-sky-500 hover:bg-sky-600 text-white"
              >
                {t("Back To Home")}
              </Link>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
