import Typewriter from "typewriter-effect";
import { useTranslation } from "react-i18next";

export default function Component() {
  const { t } = useTranslation();

  return (
    <div className="w-full relative flex flex-col gap-y-4 p-4 rounded-lg h-52">
      <div className="w-full rounded-lg bg-neutral-200 px-4 py-2">
        <p>{t("Yelo Partyroom")}</p>
      </div>
      <div className="w-full rounded-lg bg-sky-600 text-white px-4 py-2">
        <p>
          <Typewriter
            options={{
              strings: [
                t(
                  "Ever dreamt of a place where you can eat, drink, and party to your heart's content without worrying about disturbing anyone? Yelo Partyroom is the perfect solution for you!"
                ),
              ],
              autoStart: true,
              loop: true,
              delay: 30,
              deleteSpeed: 0,
              pauseFor: 10000,
            }}
          />
        </p>
      </div>
    </div>
  );
}
