import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import moment from "moment";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";

import LoadingBtn from "../../components/LoadingBtn";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import Loader from "../../partials/Loader";

const imageTypes = ["JPG", "JPEG", "PNG"];

function EditUser(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const { auth } = useAuth();
  const account = auth?.user;
  const [user, setUser] = useState();

  const navigate = useNavigate();

  // i18n
  const { t } = useTranslation();

  // Params
  const { id } = useParams();

  const getUser = async () => {
    try {
      const res = await axiosPrivate.get(endpoints.USERS_URL + `/${id}`, {
        signal: controller.signal,
      });

      setUser(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteUser = async () => {
    try {
      await axiosPrivate.delete(endpoints.USERS_URL + `/${id}`, {
        signal: controller.signal,
      });

      // Redirect to allusers
      navigate("/allusers");
    } catch (error) {
      console.error(error);
    }
  };

  const sendResetPasswordEmail = async () => {
    try {
      setIsSending(true);
      await axiosPrivate.post(
        endpoints.FORGOTPW_URL,
        { email: user.email },
        {
          signal: controller.signal,
        }
      );

      toast.success(t("Successfully Sent Reset Email!"));
    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
    }
  };

  const main = async () => {
    try {
      await getUser();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    main();
  }, []);

  const fileUpload = async (blob, type) => {
    let formData = new FormData();
    formData.append(type, blob);

    if (type === "image") {
      const res = await axiosPrivate.post(endpoints.UPLOAD_IMAGE, formData);
      return res.data.url;
    } else if (type === "audio") {
      const res = await axiosPrivate.post(endpoints.UPLOAD_ADUIO, formData);
      return res.data.url;
    } else {
      throw new Error("UNKNOWN_TYPE");
    }
  };

  const handleAvatarUpload = async (file) => {
    // Activate Loader
    formik.setFieldValue("avatar", "loader");

    // Upload to server
    const url = await fileUpload(file, "image");

    // Update URL to form
    formik.setFieldValue("avatar", url);
  };

  const submitHandler = async (values, { setErrors, resetForm }) => {
    setIsSaving(true);
    try {
      await axiosPrivate.patch(
        endpoints.USERS_URL + `/${formik.values.id}`,
        formik.values,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      console.log(values);

      toast.success("Edit Successfully!");

      setIsSaving(false);
    } catch (err) {
      console.log(err);

      if (!err?.response) {
        toast.error("Error occurred!");
      } else {
        let error = err?.response.data.errors.msg;

        toast.error(error);
      }
    } finally {
      setIsSaving(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      id: user?.id || "",
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      avatar: user?.avatar || "",
      isPremium: user?.isPremium || false,
      phone: user?.phone || "",
      email: user?.email || "",
      premiumExpires: user?.premiumExpires || moment().add(7, "days"),
      role: user?.role || "user",
      emailVerified: user?.emailVerified || false,
      isVerifiedAccount: user?.isVerifiedAccount || false,
      isEnabled: user?.isEnabled || false,
    },
    validationSchema: Yup.object({
      id: Yup.string().required("This field is required"),
      firstName: Yup.string().required("This field is required"),
      phone: Yup.string().optional(),
      email: Yup.string().required("This field is required"),
      premiumExpires: Yup.date().required("This field is required"),
      role: Yup.string()
        .oneOf(["user", "admin"])
        .required("This field is required"),
      isVerifiedAccount: Yup.boolean().required("This field is required"),
    }),
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  const setPremiumExp = (type) => {
    let date = null;

    if (type === "now") {
      date = moment();
    } else if (type === "reset") {
      date = user.premiumExpires;
    } else {
      date = moment(formik.values.premiumExpires)
        .add(1, type)
        .endOf("day")
        .format();
    }

    formik.setFieldValue("premiumExpires", date);
    formik.setFieldValue("isPremium", moment(date).isAfter());
  };

  if (isLoading) return <Loader />;
  return (
    <div className="flex overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        user={account}
      />

      {/* Content area */}
      <div className="md:h-screen relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={account}
        />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full">
            <form onSubmit={formik.handleSubmit}>
              <div className="space-y-8 divide-y divide-neutral-200 sm:space-y-5">
                {/*  Global Setting */}
                <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-black dark:text-white">
                      {t("Edit User")}
                    </h3>
                  </div>
                  <div className="space-y-6 sm:space-y-5">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t sm:border-neutral-200 py-5">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-black dark:text-white sm:mt-px sm:pt-2"
                      >
                        {t("First Name")}{" "}
                        <span className="text-rose-500">*</span>
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          id="firstName"
                          name="firstName"
                          value={formik.values.firstName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="px-3 py-2 max-w-lg shadow-sm block w-full focus:ring-sky-500 focus:border-sky-500 sm:text-sm border border-neutral-400 dark:border-neutral-600 rounded-md dark:bg-neutral-800 dark:text-white"
                        />
                        <p className="text-red-600 text-sm">
                          {formik.errors.firstName && formik.touched.firstName
                            ? `Error: ${formik.errors.firstName}`
                            : null}
                        </p>
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t sm:border-neutral-200 py-5">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-black dark:text-white sm:mt-px sm:pt-2"
                      >
                        {t("Last Name")}{" "}
                        <span className="text-rose-500">*</span>
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          id="lastName"
                          name="lastName"
                          value={formik.values.lastName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="px-3 py-2 max-w-lg shadow-sm block w-full focus:ring-sky-500 focus:border-sky-500 sm:text-sm border border-neutral-400 dark:border-neutral-600 rounded-md dark:bg-neutral-800 dark:text-white"
                        />
                        <p className="text-red-600 text-sm">
                          {formik.errors.lastName && formik.touched.lastName
                            ? `Error: ${formik.errors.lastName}`
                            : null}
                        </p>
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t sm:border-neutral-200 py-5">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-black dark:text-white sm:mt-px sm:pt-2"
                      >
                        {t("Email")} <span className="text-rose-500">*</span>
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          id="email"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="px-3 py-2 max-w-lg shadow-sm block w-full focus:ring-sky-500 focus:border-sky-500 sm:text-sm border border-neutral-400 dark:border-neutral-600 rounded-md dark:bg-neutral-800 dark:text-white"
                        />
                        <p className="text-red-600 text-sm">
                          {formik.errors.email && formik.touched.email
                            ? `Error: ${formik.errors.email}`
                            : null}
                        </p>
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t sm:border-neutral-200 py-5">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-black dark:text-white sm:mt-px sm:pt-2"
                      >
                        {t("Phone")}
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          id="phone"
                          name="phone"
                          rows="1"
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="px-3 py-2 max-w-lg shadow-sm block w-full focus:ring-sky-500 focus:border-sky-500 sm:text-sm border border-neutral-400 dark:border-neutral-600 rounded-md dark:bg-neutral-800 dark:text-white"
                        />
                        <p className="text-red-600 text-sm">
                          {formik.errors.phone && formik.touched.phone
                            ? `Error: ${formik.errors.phone}`
                            : null}
                        </p>
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t sm:border-neutral-200 py-5">
                      <div className="flex justify-between items-center sm:block sm:space-y-4">
                        <label
                          htmlFor="avatar"
                          className="block text-sm font-medium text-black dark:text-white sm:mt-px sm:pt-2"
                        >
                          {t("Avatar")}
                        </label>
                      </div>

                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        {formik.values.avatar &&
                          (formik.values.avatar === "loader" ? (
                            <div className="relative h-24 w-full">
                              <Loader />
                            </div>
                          ) : (
                            <div className="relative flex justify-center">
                              <img
                                src={formik.values.avatar}
                                alt="Avatar"
                                className="w-48 h-48 rounded-full object-cover mb-2"
                              />
                              <button
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  // Delete Avatar
                                  formik.setFieldValue("avatar", "");
                                }}
                                className="group flex justify-center items-center absolute h-5 w-5 top-0 right-0 -mt-1 -mr-1 z-20"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={2}
                                  stroke="currentColor"
                                  className="flex absolute h-4 w-4 z-10 text-white"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                  />
                                </svg>
                                <span className="relative inline-flex rounded-full h-5 w-5 bg-rose-500 group-hover:bg-rose-600"></span>
                              </button>
                            </div>
                          ))}
                        <FileUploader
                          multiple={false}
                          handleChange={(file) => handleAvatarUpload(file)}
                          name="avatar"
                          classes="h-48 max-w-lg flex justify-center px-6 py-5 pb-6 border-2 border-neutral-400 dark:border-neutral-600 border-dashed rounded-md"
                          types={imageTypes}
                        />
                        <p className="text-red-600 text-sm">
                          {formik.errors.languages && formik.touched.languages
                            ? `Error: ${formik.errors.avatar}`
                            : null}
                        </p>
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-neutral-200 sm:py-5">
                      <label
                        htmlFor="sex"
                        className="block text-sm font-medium text-black dark:text-white sm:mt-px sm:pt-2"
                      >
                        {t("Sex")}
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="sm:col-span-2">
                          <div className="mt-4 space-y-4">
                            <select
                              id="sex"
                              name="sex"
                              value={formik.values.sex}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className="max-w-lg block focus:ring-sky-500 focus:border-sky-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-neutral-400 dark:border-neutral-600 rounded-md dark:bg-neutral-800 dark:text-white"
                            >
                              <option value="male">{t("Male")}</option>
                              <option value="female">{t("Female")}</option>
                              <option value="unknown">{t("N/A")}</option>
                            </select>
                            <p className="text-red-600 text-sm">
                              {formik.errors.sex && formik.touched.sex
                                ? `Error: ${formik.errors.sex}`
                                : null}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Admin Settings */}
                {account.role === "admin" && (
                  <div className="divide-y divide-neutral-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-black dark:text-white">
                        {t("Admin Settings")}
                      </h3>
                    </div>
                    <div className="space-y-6 sm:space-y-5 divide-y divide-neutral-200">
                      <div className="pt-6 sm:py-5">
                        <div role="group" aria-labelledby="label-notifications">
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                            <div>
                              <div
                                className="text-base font-medium text-black sm:text-sm dark:text-white"
                                id="label-notifications"
                              >
                                {t("Premium")}{" "}
                                <span className="text-rose-500">*</span>
                              </div>
                              <p className="text-sm text-black dark:text-neutral-200">
                                {t(
                                  "To set the Free plan, you just need to set Premium expires date to past. In this case, you can click Now"
                                )}
                              </p>
                            </div>
                            <div className="sm:col-span-2">
                              <div className="max-w-lg">
                                <div className="mt-4 space-y-4">
                                  <div
                                    className={`inline-flex items-center text-sm font-extrabold text-neutral-100 ${
                                      moment(
                                        formik.values.premiumExpires
                                      ).isBefore()
                                        ? "bg-rose-500 dark:bg-rose-600"
                                        : "bg-sky-500 dark:bg-sky-600"
                                    } rounded-full text-center px-4 py-1`}
                                  >
                                    {t("Premium")}{" "}
                                    {moment(
                                      formik.values.premiumExpires
                                    ).isBefore()
                                      ? "expired"
                                      : "activated"}
                                    :{" "}
                                    {moment(
                                      formik.values.premiumExpires
                                    ).format("DD-MM-YYYY")}{" "}
                                    (
                                    {moment(
                                      formik.values.premiumExpires
                                    ).fromNow()}
                                    )
                                  </div>
                                  <div className="flex flex-wrap -space-x-px">
                                    <button
                                      type="button"
                                      onClick={() => setPremiumExp("now")}
                                      className="btn bg-white border-neutral-200 dark:border-neutral-600 hover:bg-neutral-50 dark:bg-neutral-800 dark:text-white text-indigo-500 rounded-none first:rounded-l last:rounded-r"
                                    >
                                      {t("Now")}
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => setPremiumExp("days")}
                                      className="btn bg-white border-neutral-200 dark:border-neutral-600 hover:bg-neutral-50 dark:bg-neutral-800 dark:text-white text-black rounded-none first:rounded-l last:rounded-r"
                                    >
                                      +1 {t("Day")}
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => setPremiumExp("weeks")}
                                      className="btn bg-white border-neutral-200 dark:border-neutral-600 hover:bg-neutral-50 dark:bg-neutral-800 dark:text-white text-black rounded-none first:rounded-l last:rounded-r"
                                    >
                                      +1 {t("Week")}
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => setPremiumExp("months")}
                                      className="btn bg-white border-neutral-200 dark:border-neutral-600 hover:bg-neutral-50 dark:bg-neutral-800 dark:text-white text-black rounded-none first:rounded-l last:rounded-r"
                                    >
                                      +1 {t("Month")}
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => setPremiumExp("years")}
                                      className="btn bg-white border-neutral-200 dark:border-neutral-600 hover:bg-neutral-50 dark:bg-neutral-800 dark:text-white text-black rounded-none first:rounded-l last:rounded-r"
                                    >
                                      +1 {t("Year")}
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => setPremiumExp("reset")}
                                      className="btn bg-white border-neutral-200 dark:border-neutral-600 hover:bg-neutral-50 dark:bg-neutral-800 dark:text-white text-rose-600 rounded-none first:rounded-l last:rounded-r"
                                    >
                                      {t("Reset")}
                                    </button>
                                  </div>
                                  <p className="text-red-600 text-sm">
                                    {formik.errors.isPremium &&
                                    formik.touched.isPremium
                                      ? `Error: ${formik.errors.isPremium}`
                                      : null}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t sm:border-neutral-200 py-5">
                        <label
                          htmlFor="reset-password"
                          className="block text-sm font-medium text-black dark:text-white sm:mt-px sm:pt-2"
                        >
                          {t("Reset Password")}
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          {isSending ? (
                            <span className="inline-flex items-center px-4 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-400">
                              {t("Send Reset Password to Email")}...
                            </span>
                          ) : (
                            <button
                              type="button"
                              onClick={() => {
                                sendResetPasswordEmail();
                              }}
                              className="inline-flex items-center px-4 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                            >
                              {t("Send Reset Password to Email")}
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="pt-6 sm:py-5">
                        <div role="group" aria-labelledby="label-notifications">
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                            <div>
                              <div
                                className="text-base font-medium text-black sm:text-sm dark:text-white"
                                id="label-notifications"
                              >
                                {t("Role")}
                                <span className="text-rose-500">*</span>
                              </div>
                            </div>
                            <div className="sm:col-span-2">
                              <div className="max-w-lg">
                                <div className="mt-4 space-y-4">
                                  <select
                                    id="role"
                                    name="role"
                                    value={formik.values.role}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className="max-w-lg block focus:ring-sky-500 focus:border-sky-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-neutral-400 dark:border-neutral-600 rounded-md dark:bg-neutral-800 dark:text-white"
                                  >
                                    <option value="user">{t("User")}</option>
                                    <option value="admin">{t("Admin")}</option>
                                  </select>
                                  <p className="text-red-600 text-sm">
                                    {formik.errors.role && formik.touched.role
                                      ? `Error: ${formik.errors.role}`
                                      : null}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pt-6 sm:py-5">
                        <div role="group" aria-labelledby="label-notifications">
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                            <div>
                              <div
                                className="text-base font-medium text-black sm:text-sm dark:text-white"
                                id="label-notifications"
                              >
                                {t("Verified User")}
                                <span className="text-rose-500">*</span>
                              </div>
                              <p className="text-sm text-black dark:text-neutral-200">
                                {t("Verified user will have a dedicated badge")}
                              </p>
                            </div>
                            <div className="sm:col-span-2">
                              <div className="max-w-lg">
                                <div className="mt-4 space-y-4">
                                  <select
                                    id="isVerifiedAccount"
                                    name="isVerifiedAccount"
                                    value={formik.values.isVerifiedAccount}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className="max-w-lg block focus:ring-sky-500 focus:border-sky-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-neutral-400 dark:border-neutral-600 rounded-md dark:bg-neutral-800 dark:text-white"
                                  >
                                    <option value="true">{t("Enable")}</option>
                                    <option value="false">
                                      {t("Disable")}
                                    </option>
                                  </select>
                                  <p className="text-red-600 text-sm">
                                    {formik.errors.isVerifiedAccount &&
                                    formik.touched.isVerifiedAccount
                                      ? `Error: ${formik.errors.isVerifiedAccount}`
                                      : null}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="pt-6 sm:py-5">
                        <div role="group" aria-labelledby="label-notifications">
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                            <div>
                              <div
                                className="text-base font-medium text-black sm:text-sm dark:text-white"
                                id="label-notifications"
                              >
                                {t("Enable Status")}{" "}
                                <span className="text-rose-500">*</span>
                              </div>
                              <p className="text-sm text-black dark:text-neutral-200">
                                {t(
                                  "Whether this user is visible to normal user (User are not able to see this user even if they are the owner)"
                                )}
                              </p>
                            </div>
                            <div className="sm:col-span-2">
                              <div className="max-w-lg">
                                <div className="mt-4 space-y-4">
                                  <select
                                    id="isEnabled"
                                    name="isEnabled"
                                    value={formik.values.isEnabled}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className="max-w-lg block focus:ring-sky-500 focus:border-sky-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-neutral-400 dark:border-neutral-600 rounded-md dark:bg-neutral-800 dark:text-white"
                                  >
                                    <option value="true">{t("Enabled")}</option>
                                    <option value="false">
                                      {t("Disable")}
                                    </option>
                                  </select>
                                  <p className="text-red-600 text-sm">
                                    {formik.errors.isEnabled &&
                                    formik.touched.isEnabled
                                      ? `Error: ${formik.errors.isEnabled}`
                                      : null}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="py-5">
                <div className="flex justify-between items-center space-x-4">
                  {/* Delete User */}
                  <button
                    type="button"
                    className="btn bg-rose-500 hover:bg-rose-600 text-white"
                    onClick={() => {
                      deleteUser();
                    }}
                  >
                    {t("Delete user")}
                  </button>

                  <div className="flex justify-end items-center space-x-4">
                    <Link
                      to={props.fallback || "/stories"}
                      className="bg-white py-2 px-4 border border-neutral-400 dark:border-neutral-600 rounded-md shadow-sm text-sm font-medium text-black hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                    >
                      {t("Back")}
                    </Link>
                    <div>
                      {isSaving ? (
                        <LoadingBtn text="Saving" />
                      ) : Object.keys(formik.errors).length > 0 ? (
                        <button
                          type="button"
                          disabled
                          className="btn bg-sky-400 text-white cursor-not-allowed"
                        >
                          {t("Save changes")}
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn bg-sky-500 hover:bg-sky-600 text-white"
                        >
                          {t("Save changes")}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {/* Content */}
          </div>
        </main>
      </div>
    </div>
  );
}

export default EditUser;
