import { useState } from "react";
import { useTranslation } from "react-i18next";
const UserMessageBlock = ({ isEdit, setIsEdit, idx, message, props }) => {
  const isOverflow =
    message.content.length > 200 || message.content.split("\n").length > 10;

  const [showMore, setShowMore] = useState(isOverflow || false);
  const { t } = useTranslation();

  return (
    <div className="relative">
      <div
        className={`${
          showMore && isEdit !== idx
            ? "line-clamp-6"
            : isEdit === idx
            ? "bg-neutral-100 dark:bg-neutral-600 rounded-lg p-4"
            : ""
        } whitespace-pre-wrap text-neutral-800 dark:text-white relative focus:outline-none focus:border-none`}
        contentEditable={isEdit === idx}
        suppressContentEditableWarning={true}
        onKeyDown={(e) => {
          // if isEdit === idx, when user click esc, then cancel edit
          if (e.key === "Escape" && isEdit === idx) {
            setIsEdit(-1);
            e.target.innerText = message.content;
          }

          // If command + enter, then submit
          if (e.key === "Enter" && e.metaKey) {
            // update chat
            props.editChatHandler(idx, e.target.innerText);
            setIsEdit(-1);
          }
        }}
      >
        {message.content}
      </div>
      {isOverflow && isEdit !== idx && (
        <div className="sticky bottom-20 mt-5 flex justify-center items-center space-x-4">
          <button
            type="button"
            onClick={() => {
              // update chat
              setShowMore(!showMore);
            }}
            className="inline-flex items-center px-8 py-1.5 font-medium rounded-lg text-sm bg-black text-white shadow"
          >
            {showMore ? t("Show more") : t("Show less")}
          </button>
        </div>
      )}
      {isEdit === idx && (
        <div className="sticky bottom-20 mt-5 flex justify-center items-center space-x-4">
          <button
            type="button"
            onClick={(e) => {
              // update chat
              props.editChatHandler(
                idx,
                e.target.parentElement.previousSibling.innerText
              );
              setIsEdit(-1);
            }}
            className="inline-flex items-center px-8 py-1.5 font-medium rounded-lg bg-sky-600 text-white shadow"
          >
            {t("Save & Submit")}
          </button>
          <button
            type="button"
            onClick={(e) => {
              setIsEdit(-1);
              e.target.parentElement.previousSibling.innerText =
                message.content;
            }}
            className="inline-flex items-center px-8 py-1.5 font-medium rounded-lg bg-black text-white shadow"
          >
            {t("Cancel")}
          </button>
        </div>
      )}
    </div>
  );
};

export default UserMessageBlock;
