import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import Header from "../../partials/Header";
import { Link } from "react-router-dom";

import axios from "../../api/axios";
import endpoints from "../../api/endpoints";
import PageNotFound from "../error/PageNotFound";
import ReactQuill from "react-quill";
import QuillMarkdown from "quilljs-markdown";

const Quill = ReactQuill.Quill;
Quill.register("modules/QuillMarkdown", QuillMarkdown, true);

export default function WritingViewer() {
  const { t } = useTranslation();

  // ID from url
  const { id } = useParams();
  const [value, setValue] = useState();
  const [isLoading, setLoading] = useState(true);
  const [isNotFound, setNotFound] = useState(false);
  const [title, setTitle] = useState("");

  // Get User Profile
  const { auth } = useAuth();
  const user = auth?.user;

  const controller = new AbortController();

  // Quill Editor
  const modules = {
    toolbar: true,
    QuillMarkdown: true,
  };
  const placeholder = `${t(
    "Start typing your content and let your thoughts flow"
  )}...`;
  const quillRef = useRef(null);

  // Fetch Writing
  const fetchWriting = async () => {
    try {
      if (id) {
        const res = await axios.get(endpoints.WRITINGS_URL + "/public/" + id, {
          signal: controller.signal,
        });

        return res.data;
      } else {
        // Cannot Found
        setNotFound(true);
      }
    } catch (error) {
      console.error(error);
      setNotFound(true);
    }
  };

  const initQuill = async () => {
    try {
      // Get Writing
      const res = await fetchWriting();

      setLoading(false);

      if (quillRef.current) {
        // Set Initial Text
        if (res.title) {
          setTitle(res.title);
        }

        if (res.delta) {
          try {
            // Parse Content
            const delta = JSON.parse(res.delta);

            // set Content
            quillRef.current.getEditor().setContents(delta);
          } catch {
            quillRef.current.getEditor().setText(res.content);
          }
        } else {
          // Fallback to content
          quillRef.current.getEditor().setText(res.content);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // main
  useEffect(() => {
    initQuill();
  }, [isLoading]);

  if (!isLoading && isNotFound) {
    return <PageNotFound />;
  } else {
    return (
      <div className="flex overflow-hidden">
        {/* Content area */}
        <div
          onClick={(e) => {
            // if user is not click any input or textarea field
            if (e.target.tagName.toLowerCase() !== "input") {
              // focus to editor
              quillRef.current.getEditor().focus();
            }
          }}
          className="md:h-screen relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden"
        >
          <Header
            user={user}
            title={title}
            otherComponents={
              <>
                <Link
                  to={`/docs/${id}`}
                  className="whitespace-nowrap text-sm inline-flex space-x-1 items-center font-medium bg-neutral-100 text-neutral-600 rounded-full text-center p-2.5 sm:px-2.5 sm:py-1"
                >
                  {t("Edit")}
                </Link>
              </>
            }
          />

          <main>
            <div className="w-full flex flex-col sm:space-x-16 justify-center items-center dark:bg-neutral-900 sm:px-6 lg:px-8">
              <div className="relative h-full w-full md:max-w-2xl">
                {/* Editor */}
                <ReactQuill
                  ref={quillRef}
                  value={value}
                  modules={modules}
                  readOnly={true}
                  theme="bubble"
                  placeholder={placeholder}
                  onChange={setValue}
                  className="relative text-neutral-900 dark:text-white placeholder:text-white text-start text-align-setting mb-32"
                />
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}
