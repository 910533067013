import { Link } from "react-router-dom";
import AuthHeader from "../../partials/auths/AuthHeader";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import SignUpComponent from "../../partials/auths/SignUpComponent";

function Signup() {
  // i18n
  const { t } = useTranslation();
  const navigate = useNavigate();

  const redirect = () => {
    // Return to previous page
    navigate("/", { replace: true });
  };

  return (
    <main className="bg-white dark:bg-neutral-900">
      <div className="relative md:flex">
        {/* Content */}
        <div className="w-full">
          <div className="h-full flex flex-col after:flex-1">
            {/* Header */}
            <AuthHeader title={t("Sign Up")} />

            <div className="max-w-sm mx-auto px-2 sm:px-4 py-8 w-96">
              <SignUpComponent callback={redirect} />
              {/* Footer */}
              <div className="pt-5 mt-6 border-t border-neutral-200">
                <div className="flex justify-center text-sm gap-x-2 text-neutral-800 dark:text-white">
                  {t("Have an account?")}{" "}
                  <Link
                    className="font-medium text-sky-500 hover:text-sky-600"
                    to="/signin"
                  >
                    {t("Sign In")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Signup;
