import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Typewriter from "typewriter-effect";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";

function MessagesBodyEmpty({ chat, setChat, setOpen }) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const createWriting = async () => {
    try {
      const res = await axiosPrivate.post(endpoints.WRITINGS_URL, {
        signal: controller.signal,
      });

      navigate(`/docs/${res.data.id}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="min-h-[calc(100vh-154px)] mx-auto text-center py-2 md:px-6 lg:px-8">
      <div className="mt-4">
        {chat?.prompt?.code ? (
          <>
            <div className="mb-4 w-screen max-w-2xl p-8 rounded-lg shadow bg-sky-50 dark:bg-sky-800 text-left">
              <div className="text-xl font-bold text-sky-500">
                {chat.prompt.title}
              </div>
              <div>{chat.prompt.description}</div>
            </div>
            <div className="space-x-4">
              <button
                type="button"
                onClick={() => {
                  setOpen(true);
                }}
                className="inline-flex items-center justify-center px-5 py-1.5 border border-transparent text-base font-medium rounded-md text-white bg-black"
              >
                {t("Use Other Template")}
              </button>
              <button
                type="button"
                onClick={() => {
                  setChat();
                }}
                className="inline-flex items-center justify-center px-5 py-1.5 border border-transparent text-base font-medium rounded-md text-white bg-black"
              >
                {t("Clear")}
              </button>
            </div>
          </>
        ) : (
          <>
            <div>
              <div className="max-w-3xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <h2 className="text-3xl font-extrabold tracking-tight dark:text-white sm:text-4xl">
                  <span className="block">
                    <Typewriter
                      options={{
                        strings: [
                          t("Want to sell a course?"),
                          t("Want to sell a workshop?"),
                          t("Want to sell a product?"),
                          t("Want to sell some snacks?"),
                        ],
                        autoStart: true,
                        loop: true,
                        delay: 50,
                        deleteSpeed: 0,
                        pauseFor: 3000,
                        wrapperClassName: "bg-teal-400/30",
                      }}
                    />
                    {t(
                      "Using the system prompt can increase the output quality by 80%"
                    )}
                  </span>
                </h2>
                <p className="mt-3 text-lg text-neutral-600 dark:text-neutral-200">
                  💡Tips:{" "}
                  {t(
                    "Using our custom-made system prompts, you can start writing your Instagram post in less than 10 seconds. Let's explore our Product Templates!"
                  )}
                </p>
                <div className="mt-14 flex justify-center">
                  {/* <div className="relative flex items-end justify-center rounded-md shadow">
                    <button
                      type="button"
                      onClick={() => {
                        setOpen(true);
                      }}
                      className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700"
                    >
                      {t("Select Product Template")}
                    </button>
                  </div> */}
                  <div className="ml-3 inline-flex">
                    <button
                      type="button"
                      onClick={() => {
                        createWriting();
                      }}
                      className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-sky-700 bg-sky-100 hover:bg-sky-200"
                    >
                      {t("Create IG Post for your Product")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default MessagesBodyEmpty;
