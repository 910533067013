import ModalBasic from "./ModalBasic";
import { useTranslation } from "react-i18next";

const DeleteWritingPreview = (props) => {
  const { t } = useTranslation();
  return (
    <>
      {/* Modal w/ Scroll Bar */}
      <div className="m-1.5">
        <ModalBasic
          id="scrollbar-modal"
          modalOpen={props?.modalOpen}
          setModalOpen={props?.setModalOpen}
          title={
            props?.writing?.title === undefined && props?.writing?.title === ""
              ? t("Untitled")
              : props.writing.title
          }
        >
          {/* Modal content */}
          <div className="px-5 py-4">
            <div className="text-sm">
              <div className="prose overflow-hidden prose overflow-hidden-slate dark:prose overflow-hidden-invert text-start text-align-setting">
                {props?.writing?.content === undefined &&
                props?.writing?.content === ""
                  ? t("No content")
                  : props.writing.content}
              </div>
            </div>
          </div>
          {/* Modal footer */}
          <div className="sticky bottom-0 px-5 py-4 bg-white dark:bg-neutral-900 border-t border-neutral-200">
            <div className="flex flex-wrap items-center justify-between space-x-2">
              <div className="m-1.5 space-x-2">
                {/* Restore Icon */}
                {/* Start */}
                <button
                  onClick={props.putbackWriting}
                  className="btn bg-emerald-500 hover:scale-105 transition ease-in-out duration-150 space-x-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    className="w-4 h-4 fill-current text-white shrink-0"
                  >
                    <path d="M18.885 3.515c-4.617-4.618-12.056-4.676-16.756-.195l-2.129-2.258v7.938h7.484l-2.066-2.191c2.82-2.706 7.297-2.676 10.073.1 4.341 4.341 1.737 12.291-5.491 12.291v4.8c3.708 0 6.614-1.244 8.885-3.515 4.686-4.686 4.686-12.284 0-16.97z" />
                  </svg>
                  <span className="text-white">{t("Restore")}</span>
                </button>
                {/* End */}
              </div>
              <div className="m-1.5 space-x-2 flex items-center">
                <button
                  className="btn px-6 dark:text-white border-neutral-200 hover:border-neutral-300 hover:scale-105 transition ease-in-out duration-150 space-x-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    props?.setModalOpen(false);
                  }}
                >
                  {t("Close")}
                </button>
                {/* Delete Icon */}
                {/* Start */}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    props?.setModalOpen(false);
                    props.deleteWriting(e);
                  }}
                  className="btn bg-rose-500 hover:scale-105 transition ease-in-out duration-150 space-x-2"
                >
                  <svg
                    className="w-4 h-4 fill-current text-white shrink-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5 7h2v6H5V7zm4 0h2v6H9V7zm3-6v2h4v2h-1v10c0 .6-.4 1-1 1H2c-.6 0-1-.4-1-1V5H0V3h4V1c0-.6.4-1 1-1h6c.6 0 1 .4 1 1zM6 2v1h4V2H6zm7 3H3v9h10V5z" />
                  </svg>
                  <span className="text-white">{t("Delete")}</span>
                </button>
                {/* End */}
              </div>
            </div>
          </div>
        </ModalBasic>
        {/* End */}
      </div>
    </>
  );
};

export default DeleteWritingPreview;
