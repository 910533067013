import { useFormik } from "formik";
import * as Yup from "yup";

import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";
import { toast } from "react-toastify";

export default function CreateEshopModal({
  open,
  setOpen,
  eshops,
  setEshops,
  setSelectedEshop,
}) {
  // i18n
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  // Get Prompts
  const createEshop = async (values) => {
    try {
      setLoading(true);
      const res = await axiosPrivate.post(endpoints.ESHOPS_URL, values);
      toast.success(t("E-Shop created successfully"));

      setSelectedEshop(res.data);
      setEshops([...eshops, res.data]);
      setOpen(false);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      url: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required"),
      url: Yup.string().url().required("This field is required"),
    }),
    enableReinitialize: true,
    onSubmit: createEshop,
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-center justify-center min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 backdrop-blur-sm backdrop-brightness-75 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <form
              onSubmit={formik.handleSubmit}
              className="inline-block bg-white dark:bg-neutral-900 rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-3xl w-full p-6"
            >
              <div>
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center space-x-2">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-bold text-black dark:text-white"
                    >
                      {t("Create E-Shop")}
                    </Dialog.Title>
                  </div>
                  <button
                    type="button"
                    onClick={() => setOpen(false)}
                    className="hover:scale-105 transition-all"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={3}
                      stroke="currentColor"
                      className="w-6 h-6 dark:text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="mt-4">
                <div className="flex items-center">
                  <label
                    htmlFor="title"
                    className="block text-sm font-medium dark:text-white"
                  >
                    {t("Name")}
                  </label>
                  <span className="text-rose-500">*</span>
                </div>
                <input
                  className="mt-2 shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-neutral-300 rounded-md resize-none dark:border-neutral-600 dark:bg-neutral-800 dark:text-white"
                  type="text"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  autoComplete="off"
                />
                <p className="text-red-600 text-sm">
                  {formik.errors.name && formik.touched.name
                    ? `${t("Error")}: ${formik.errors.name}`
                    : null}
                </p>
              </div>
              <div className="mt-2">
                <div className="flex items-center">
                  <label
                    htmlFor="title"
                    className="block text-sm font-medium dark:text-white"
                  >
                    {t("URL")}
                  </label>
                  <span className="text-rose-500">*</span>
                </div>
                <input
                  className="mt-2 shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-neutral-300 rounded-md resize-none dark:border-neutral-600 dark:bg-neutral-800 dark:text-white"
                  type="text"
                  name="url"
                  value={formik.values.url}
                  onChange={formik.handleChange}
                  autoComplete="off"
                  placeholder="e.g. https://yourshopifystore.com"
                />
                <p className="text-red-600 text-sm">
                  {formik.errors.url && formik.touched.url
                    ? `${t("Error")}: ${formik.errors.url}`
                    : null}
                </p>
                <span className="mt-2 text-sm text-bold text-neutral-600 dark:text-neutral-400">
                  {t(
                    "We currently only support Shopify and Shopage, other platforms will be supported soon"
                  )}
                </span>
              </div>
              {/* Submit button */}
              <div className="mt-4 flex justify-between">
                {!loading ? (
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sky-500 hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                  >
                    {t("Create")}
                  </button>
                ) : (
                  <span
                    className="w-full flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-neutral-300 cursor-not-allowed"
                    disabled
                  >
                    {t("Create")}
                  </span>
                )}
              </div>
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
