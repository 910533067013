import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";
import { useClipboard } from "@mantine/hooks";

export default function ViewPanel({
  writingObj,
  user,
  addSuffixToText,
  openViewHistory,
  setOpenViewHistory,
}) {
  const { t } = useTranslation();

  const [index, setIndex] = useState(0);
  const [content, setContent] = useState("");

  const clipboard = useClipboard({ timeout: 1000 });

  const copyText = () => {
    const copyableText = addSuffixToText(content, user);

    // Copy to clipboard
    clipboard.copy(copyableText);
  };

  useEffect(() => {
    if (writingObj?.genHistory?.length > 0) {
      // check if index is inbound
      try {
        setContent(writingObj.genHistory[index]);
      } catch {
        setContent(writingObj.genHistory[0]);
      }
    } else {
      setContent("");
    }
  }, [index]);

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="text-lg dark:text-white font-bold mb-2">
          {t("Generate History")}
        </div>

        <div
          className={`mb-2 flex items-center justify-between space-x-2 transition-all`}
        >
          <div className="text-sm font-medium transition-all bg-white px-3 py-1 rounded-full shadow flex items-center">
            {index + 1}/{writingObj?.genHistory?.length || 0}{" "}
            <span className="hidden sm:block">{t("Version")}</span>
          </div>
          <div className="rounded-full shadow bg-white">
            <button
              onClick={() => {
                // infinity loop
                setIndex(Math.max(index - 1, 0));
              }}
              className="text-sm group font-medium transition-all px-1 sm:px-3 py-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 group-hover:-translate-x-0.5 transition-all"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5 8.25 12l7.5-7.5"
                />
              </svg>
            </button>
            <button
              onClick={() => {
                setIndex(
                  Math.min(index + 1, writingObj?.genHistory?.length - 1)
                );
              }}
              className="text-sm group font-medium transition-all px-1 sm:px-3 py-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 group-hover:translate-x-0.5 transition-all"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m8.25 4.5 7.5 7.5-7.5 7.5"
                />
              </svg>
            </button>
          </div>

          <button
            type="button"
            onClick={() => {
              copyText();
            }}
            className={`text-sm font-medium transition-all bg-white px-3 py-1 rounded-full shadow ${
              clipboard.copied
                ? "text-emerald-500 scale-105"
                : "text-neutral-800"
            }`}
          >
            {clipboard.copied ? (
              `${t("Copied")}!`
            ) : (
              <>
                <span className="sm:hidden">{t("Copy")}</span>
                <span className="hidden sm:block">
                  {t("Copy this writing")}
                </span>
              </>
            )}
          </button>

          <button
            type="button"
            onClick={() => {
              setOpenViewHistory(!openViewHistory);
            }}
            className={`text-sm font-medium transition-all px-3 py-1 rounded-full shadow text-white bg-black`}
          >
            {t("Close")}
          </button>
        </div>
      </div>

      <div className="relative">
        <TextareaAutosize
          className="mb-8 border-none resize-none focus:ring-0 outline-none w-full  h-96 bg-neutral-50 dark:bg-neutral-800 rounded-xl relative text-neutral-900 dark:text-white dark:placeholder:text-white p-4"
          value={content}
          onCopy={(e) => {
            e.preventDefault();

            const start = e.target.selectionStart;
            const end = e.target.selectionEnd;

            console.log(e.target);

            const selectedText = content.substring(start, end).trim();

            const copyableText = addSuffixToText(selectedText, user);
            e.clipboardData.setData("text/plain", copyableText);
          }}
          type="text"
          name="prompt"
          minRows={20}
          autoComplete="off"
          autoFocus
        />
      </div>
    </>
  );
}
