import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import DirectMessages from "./DirectMessages";
import { useTranslation } from "react-i18next";

export default function MessagesSidebar({
  user,
  msgSidebarOpen,
  setMsgSidebarOpen,
  chatrooms,
  setChatrooms,
  id,
  deleteChatRoomHandler,
}) {
  const { t } = useTranslation();

  return (
    <div className="z-10 fixed h-screen flex overflow-hidden bg-neutral-100">
      <Transition.Root show={msgSidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40"
          onClose={setMsgSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black/50 backdrop-blur-sm" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="relative flex-1 flex flex-col max-w-2xl sm:mx-auto sm:rounded-xl sm:m-10 w-full bg-neutral-900">
              <div className="relative overflow-y-auto">
                <DirectMessages
                  msgSidebarOpen={msgSidebarOpen}
                  setMsgSidebarOpen={setMsgSidebarOpen}
                  chatrooms={chatrooms}
                  setChatrooms={setChatrooms}
                  id={id}
                  deleteChatRoomHandler={deleteChatRoomHandler}
                />
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
