import React, { useState, useEffect } from "react";
import User from "./UsersTableItem";
import { useTranslation } from "react-i18next";

function UsersTable({ selectedItems, handleSelectedItems, isFetching, users }) {
  const { t } = useTranslation();
  return (
    <div className="bg-white dark:bg-neutral-800 dark:border-neutral-600 dark:text-white shadow-lg rounded-sm border border-neutral-200 relative">
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-neutral-500 bg-neutral-50 border-neutral-200 dark:text-white dark:bg-neutral-800 dark:border-neutral-600 border-b">
              <tr>
                {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="flex items-center">
                    <label className="inline-flex">
                      <span className="sr-only">Select all</span>
                      <input
                        className="form-checkbox"
                        type="checkbox"
                        onChange={handleSelectAll}
                      />
                    </label>
                  </div>
                </th> */}
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">{t("Name")}</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">{t("Email")}</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">
                    {t("Joined At")}
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">
                    {t("Last Login")}
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">{t("Role")}</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">{t("Premium")}</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <span className="sr-only">Menu</span>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="table-auto text-sm divide-y divide-neutral-200">
              {users.map((user, idx) => {
                return (
                  <User
                    key={`user-${idx}`}
                    user={user}
                    handleClick={() => handleSelectedItems(user.id)}
                    isChecked={selectedItems.includes(user.id)}
                  />
                );
              })}
              {!isFetching && users.length === 0 && (
                <tr>
                  <td colSpan="6">
                    <div className="col-span-12 flex items-center justify-center py-5 text-xl">
                      {/* Show Empty */}
                      {t("No Found")}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default UsersTable;
