import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Transition from "../utils/Transition";
import { useLocalStorage } from "@mantine/hooks";
import { useTranslation } from "react-i18next";

function DropdownProfile(props) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { t, i18n } = useTranslation();
  const location = useLocation();

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // Dark Mode
  const [theme, setTheme] = useLocalStorage({
    key: "theme",
    defaultValue: "",
  });

  const setThemeMode = (mode) => {
    setTheme(mode);

    // On page load or when changing themes, best to add inline in `head` to avoid FOUC
    if (
      localStorage.theme.includes("dark") ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  };

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  const renderPlan = (isPremium) => {
    if (isPremium) {
      return (
        <div className="text-xs font-bold bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500">
          {t("Premium")}
        </div>
      );
    } else {
      return (
        <div className="text-xs font-bold bg-clip-text text-transparent bg-gradient-to-r from-teal-500 to-sky-500">
          {t("Free")}
        </div>
      );
    }
  };

  const renderUserIcon = (user) => {
    let initial = "U";
    if (user?.firstName) {
      initial = user?.firstName[0] || "U";
    }
    initial = initial.toUpperCase();

    if (user?.profilePic) {
      return (
        <img
          className="w-12 h-12 rounded-full object-cover"
          src={props.user.profilePic}
          alt="User"
        />
      );
    } else {
      return (
        <>
          <div className="flex justify-center items-center w-12 h-12 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
            <span className="text-2xl font-bold text-white">{initial}</span>
          </div>
        </>
      );
    }
  };

  if (!props.user) {
    return (
      <Link
        to="/signin"
        state={{ from: location }}
        className="inline-flex gap-x-2 py-2 px-4 items-center w-full rounded-md text-sm font-medium text-black hover:bg-neutral-200 dark:text-white dark:hover:bg-neutral-800"
      >
        {t("Sign In")}
      </Link>
    );
  } else {
    return (
      <div className="relative inline-flex items-center gap-x-2">
        <button
          ref={trigger}
          className="w-12 inline-flex justify-center items-center group"
          aria-haspopup="true"
          onClick={() => setDropdownOpen(!dropdownOpen)}
          aria-expanded={dropdownOpen}
        >
          {renderUserIcon(props?.user)}
        </button>

        <Transition
          className={`z-20 origin-top-right absolute top-full bg-white dark:bg-neutral-900 dark:border-neutral-600 dark:text-white rounded-lg shadow-lg ${
            props.align === "right" ? "right-0" : "left-0"
          }`}
          show={dropdownOpen}
          enter="transition ease-out duration-200 transform"
          enterStart="opacity-0 -translate-y-2"
          enterEnd="opacity-100 translate-y-0"
          leave="transition ease-out duration-200"
          leaveStart="opacity-100"
          leaveEnd="opacity-0"
        >
          <div
            ref={dropdown}
            onFocus={() => setDropdownOpen(true)}
            onBlur={() => setDropdownOpen(false)}
            className="w-72"
          >
            <div className="py-3 px-3 border-b border-neutral-200">
              <Link to="/settings" className="flex items-center gap-x-4">
                {renderUserIcon(props?.user)}
                <div className="flex flex-col w-fit">
                  <div className="font-bold text-lg text-neutral-800 dark:text-white truncate">
                    {props?.user?.firstName}
                  </div>
                  <div className="font-bold text-sm text-neutral-800 dark:text-white truncate">
                    {props?.user?.email}
                  </div>
                  {renderPlan(props?.user?.isPremium)}
                </div>
              </Link>
            </div>
            <ul>
              <li>
                <Link
                  className="font-medium flex items-center py-2 px-4 hover:bg-black/10"
                  to="/settings"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  {t("Settings")}
                </Link>
              </li>
              {/* <li>
                <button
                  className="w-full font-medium flex items-center py-2 px-4 hover:bg-black/10"
                  to="/settings"
                  onClick={() =>
                    setThemeMode(theme === "dark" ? "light" : "dark")
                  }
                >
                  {theme === "dark" ? t("Light Mode") : t("Dark Mode")}
                </button>
              </li>
              <li>
                <button
                  className="w-full font-medium flex items-center py-2 px-4 hover:bg-black/10"
                  onClick={() =>
                    i18n.changeLanguage(i18n.language === "en" ? "zh" : "en")
                  }
                >
                  {i18n.language === "en" ? t("繁體中文") : t("English")}
                </button>
              </li> */}
              <li>
                <Link
                  className="font-medium flex items-center py-2 px-4 hover:bg-black/10 rounded-b-lg"
                  to="/signout"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  {t("Sign Out")}
                </Link>
              </li>
            </ul>
          </div>
        </Transition>
      </div>
    );
  }
}

export default DropdownProfile;
