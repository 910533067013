import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import PasswordStrengthBar from "react-password-strength-bar";

import useAuth from "../../hooks/useAuth"; // Auth
import ProcessingBtn from "../../components/btn/ProcessingBtn";
import axios from "../../api/axios";
import endpoints from "../../api/endpoints";
import Loader from "../../partials/Loader";

import Warning from "../../components/alert/Warning";
import Message from "../message/Message";

import AuthHeader from "../../partials/auths/AuthHeader";
import ResetPasswordSuccessModal from "../../partials/modals/ResetPasswordSuccessModal";

// the hook
import { useTranslation } from "react-i18next";

function Reset() {
  // i18n
  const { t } = useTranslation();

  // Warning
  const [warning, setWarning] = useState();
  const [open, setOpen] = useState(false);

  // Auth
  const { setAuth } = useAuth();

  // Reset Token
  let { resetToken } = useParams();
  const [isTokenValid, setTokenValid] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const resetHandler = async (values, { setErrors, resetForm }) => {
    setLoading(true);
    try {
      const res = await axios.post(
        endpoints.RESETPW_URL,
        JSON.stringify(formik.values),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      // Set Auth Payload
      setAuth(res?.data);

      setOpen(true);
    } catch (err) {
      console.log(err);

      if (!err?.response) {
        console.error("No Server res");
      } else {
        let error = err?.response.data.errors.msg;
        setWarning(<Warning title="Invalid Token" />);
      }
    } finally {
      setLoading(false);
    }
  };

  const [isLoading, setLoading] = useState(true);

  const formik = useFormik({
    initialValues: {
      resetToken: resetToken,
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      resetToken: Yup.string().required("Reset token is required"),
      password: Yup.string()
        .trim()
        .min(8, t("Password must be at least 8 characters"))
        .required(t("This field is required")),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        t("Passwords must match")
      ),
    }),
    onSubmit: resetHandler,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const checkToken = async () => {
      try {
        // Check if the reset token is valid
        console.log(resetToken);

        // Check if the reset token is valid
        await axios.get(endpoints.VERIFY_RESET_TOKEN_URL, {
          params: { id: resetToken },
        });

        setTokenValid(true);
        setLoading(false);
      } catch (e) {
        navigate(endpoints.ERROR_404_URL, { replace: true });
      }
    };
    checkToken();
  }, [isLoading]);

  if (isLoading) return Loader;
  if (!isTokenValid) {
    return <Message message="Invalid verification token!" />;
  } else {
    return (
      <main className="bg-white dark:bg-neutral-900">
        <ResetPasswordSuccessModal open={open} setOpen={setOpen} />

        <div className="relative md:flex">
          {/* Content */}
          <div className="w-full">
            <div className="h-full flex flex-col after:flex-1">
              {/* Header */}
              <AuthHeader title={t("Reset Password")} />

              <div className="max-w-sm mx-auto px-2 sm:px-4 py-8 w-96">
                <h1 className="text-3xl text-neutral-800 dark:text-white font-bold mb-6">
                  {t("Reset Password")}
                </h1>

                {/* Warning */}
                {warning}

                {/* Form */}
                <form onSubmit={formik.handleSubmit}>
                  <div className="space-y-4">
                    <div>
                      <label
                        className="block text-sm font-medium mb-1 text-neutral-800 dark:text-white"
                        htmlFor="password"
                      >
                        {t("New Password")}
                      </label>
                      <input
                        name="password"
                        className="form-input w-full text-lg sm:text-base dark:text-white dark:bg-neutral-800 dark:border-neutral-600"
                        type="password"
                        value={formik.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.values.password && (
                        <PasswordStrengthBar
                          minLength={8}
                          scoreWordClassName="text-sm font-medium"
                          password={formik.values.password}
                        />
                      )}
                      <p className="text-red-600 text-sm">
                        {formik.errors.password && formik.touched.password
                          ? `${t("Error")}: ${formik.errors.password}`
                          : null}
                      </p>
                    </div>
                    <div>
                      <label
                        className="block text-sm font-medium mb-1 text-neutral-800 dark:text-white"
                        htmlFor="confirmPassword"
                      >
                        {t("Confirm Password")}
                      </label>
                      <input
                        name="confirmPassword"
                        className="form-input w-full text-lg sm:text-base dark:text-white dark:bg-neutral-800 dark:border-neutral-600"
                        type="password"
                        autoComplete="on"
                        value={formik.confirmPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <p className="text-red-600 text-sm">
                        {formik.errors.confirmPassword &&
                        formik.touched.confirmPassword
                          ? `${t("Error")}: ${formik.errors.confirmPassword}`
                          : null}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-6">
                    {!isLoading && (
                      <button
                        type="submit"
                        className="btn bg-sky-500 hover:bg-sky-600 text-white w-full"
                      >
                        {t("Reset")}
                      </button>
                    )}
                    {isLoading && <ProcessingBtn title="Reset" />}
                  </div>
                </form>
                {/* Footer */}
                <div className="pt-5 mt-6 border-t border-neutral-200">
                  <div className="flex justify-center text-sm mb-6 text-neutral-800 dark:text-white gap-x-2">
                    <div>{t("Already have an account?")}</div>
                    <Link
                      className="font-medium text-sky-500 hover:text-sky-600"
                      to="/signin"
                    >
                      {t("Sign In")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Reset;
