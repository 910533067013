import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import BookmarkModal from "../modals/BookmarkModal";
import UserMessageBlock from "./UserMessageBlock";
import SystemMessageBlock from "./SystemMessageBlock";
import AssistantMessageBlock from "./AssistantMessageBlock";
import MessageEditMenu from "./MessageEditMenu";

import { addSuffixToText } from "../../utils/Utils";
import useAuth from "../../hooks/useAuth";

function MessagesBody(props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState({});
  const [isEdit, setIsEdit] = useState(-1);

  const { auth } = useAuth();
  const user = auth?.user;

  const edit = (idx) => {
    setIsEdit(idx);
  };

  const copy = (text, useToast = true) => {
    const copyableText = addSuffixToText(text, user);
    navigator.clipboard.writeText(copyableText);

    if (!useToast) return;
    toast.success(t("Copied to clipboard"));
  };

  const renderMsg = (message, idx) => {
    if (message.content === undefined) return;
    if (message.content === null) return;

    let roleName = "Articue AI";

    if (message.role === "user") {
      roleName = "You";
    }

    if (message.role === "system") {
      roleName = "System Prompt";
    }

    return (
      <div
        key={`msg-${idx}`}
        onCopy={(e) => {
          if (message.role === "user") return;
          e.preventDefault();

          // Get selected text
          const selectedText = window.getSelection().toString();

          copy(selectedText, false);
        }}
        className={`flex w-full justify-center items-start mb-4 last:mb-0`}
      >
        <div
          className={`w-full p-8 rounded-lg shadow ${
            message.role === "assistant" ? "bg-neutral-50 dark:bg-neutral-800" : ""
          } ${message.role === "system" ? "bg-sky-50 dark:bg-sky-800" : ""}`}
        >
          <div className="relative flex justify-between mb-2">
            <div className="text-sky-500">{t(roleName)}:</div>
            <MessageEditMenu
              props={props}
              idx={idx}
              edit={edit}
              copy={copy}
              message={message}
            />
          </div>
          {message?.useInternet && (
            <div>
              <div className="flex items-center space-x-2 mb-2">
                <div className="text-sm text-neutral-500 dark:text-neutral-400">
                  {t("Search Query")}:{" "}
                  <span
                    className={
                      message.isProcessingSearchQuery
                        ? "text-amber-500"
                        : "text-emerald-500"
                    }
                  >
                    {message.isProcessingSearchQuery
                      ? t("Processing")
                      : message.searchQuery}
                  </span>
                </div>
              </div>
              {message.isProcessingSearchQuery === false && (
                <div className="flex items-center space-x-2 mb-2">
                  <div className="text-sm text-neutral-500 dark:text-neutral-400">
                    {t("Internet Status")}:{" "}
                    <span
                      className={
                        message.isProcessingInternet
                          ? "text-amber-500"
                          : "text-emerald-500"
                      }
                    >
                      {message.isProcessingInternet
                        ? t("Fetching")
                        : t("Fetched")}
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}
          {message.role === "assistant" && (
            <AssistantMessageBlock
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              copy={copy}
              idx={idx}
              message={message}
              props={props}
            />
          )}
          {message.role === "user" && (
            <UserMessageBlock
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              idx={idx}
              message={message}
              props={props}
            />
          )}
          {message.role === "system" && (
            <SystemMessageBlock
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              idx={idx}
              message={message}
              props={props}
            />
          )}
        </div>
      </div>
    );
  };

  const renderChat = () => {
    let isRendered = false;

    let messages = props.chat.messages;

    let messageQueue = messages.map((message, idx) => {
      if (idx === props.streamIdx && props.isProcessing) {
        isRendered = true;
        return renderMsg(
          {
            role: "assistant",
            content: props.stream
              ? props.stream + "▋"
              : `${t("Processing")}...`,
            useInternet: props.useInternet,
            isProcessing: props.isProcessing,
            isProcessingInternet: props.isProcessingInternet,
            isProcessingSearchQuery: props.isProcessingSearchQuery,
            searchQuery: props.searchQuery,
          },
          idx
        );
      }

      if (
        idx !== messages.length - 1 ||
        !props.isProcessing ||
        props.streamIdx !== -1
      ) {
        return renderMsg(message, idx);
      } else {
        if (message.role === "user") {
          return renderMsg(message, idx);
        }
      }
    });

    if (props.isProcessing && !isRendered) {
      // Append the last message
      messageQueue.push(
        renderMsg(
          {
            role: "assistant",
            content: props.stream
              ? props.stream + "▋"
              : `${t("Processing")}...`,
            useInternet: props.useInternet,
            isProcessing: props.isProcessing,
            isProcessingInternet: props.isProcessingInternet,
            isProcessingSearchQuery: props.isProcessingSearchQuery,
            searchQuery: props.searchQuery,
          },
          messages.length,
          "Articue AI"
        )
      );
    }

    return messageQueue;
  };

  useEffect(() => {
    props.scrollToBottom();
  }, []);

  return (
    <div className="min-h-[calc(100vh-154px)] w-full">
      {/* Bookmark Model */}
      <BookmarkModal open={open} setOpen={setOpen} message={message} />

      <div className="max-w-2xl mx-auto">
        {/* Your msg */}
        {renderChat()}
      </div>
      <div id="messagesEndRef" ref={props.messagesEndRef} />
    </div>
  );
}

export default MessagesBody;
