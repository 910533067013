/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";

export default function BookmarkModal({ open, setOpen, message }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [bookmarks, setBookmarks] = useState([]);
  const [editSet, setEditSet] = useState(new Set());

  const axiosPrivate = useAxiosPrivate();

  const getBookmarks = async () => {
    try {
      const res = await axiosPrivate.get(endpoints.BOOKMARK_URL);
      setBookmarks(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const createBookmark = async () => {
    try {
      const res = await axiosPrivate.post(endpoints.BOOKMARK_URL, {
        title: t("New bookmark"),
      });

      // Prepend the new bookmark to the list
      setBookmarks([
        { id: res.data.id, title: res.data.title, count: res.data.count },
        ...bookmarks,
      ]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addToBookmark = async (id) => {
    try {
      const res = await axiosPrivate.post(endpoints.BOOKMARK_URL + "/" + id, {
        messages: [message],
      });

      // Update bookmark
      setBookmarks(
        bookmarks.map((bookmark) =>
          bookmark.id === id
            ? { id: res.data.id, title: res.data.title, count: res.data.count }
            : bookmark
        )
      );

      // Close Modal
      setOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateBookmark = async (id, title) => {
    try {
      const res = await axiosPrivate.patch(endpoints.BOOKMARK_URL + "/" + id, {
        title,
      });

      // Update bookmark
      setBookmarks(
        bookmarks.map((bookmark) =>
          bookmark.id === id
            ? { id: res.data.id, title: res.data.title, count: res.data.count }
            : bookmark
        )
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBookmarks();
  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-40 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex h-screen items-center justify-center text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 backdrop-blur-sm backdrop-brightness-75 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="mx-4 sm:mx-0 inline-block align-bottom bg-white dark:bg-neutral-900 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg w-full p-6">
              <div className="sm:flex sm:items-start">
                <div className="text-center sm:text-left w-full space-y-4">
                  <div className="flex items-center justify-between">
                    <Dialog.Title
                      as="h4"
                      className="h4 font-medium text-neutral-900 dark:text-white "
                    >
                      {t("Add to bookmark")}
                    </Dialog.Title>
                    {/* Create Bookmark button */}
                    <button
                      onClick={() => setOpen(false)}
                      className="px-4 py-1 rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 dark:text-white flex items-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  <ul
                    role="list"
                    className="py-2 rounded-lg space-y-2 max-h-96 overflow-auto"
                  >
                    {bookmarks.length === 0 && (
                      <p className="text-neutral-600 dark:text-neutral-200 text-center">
                        {t("No bookmark")}
                      </p>
                    )}
                    {bookmarks.map((bookmark, idx) => (
                      <div
                        key={idx}
                        className="group p-4 relative rounded-lg border border-neutral-300  w-full flex justify-between items-center"
                      >
                        <div className="flex w-full items-center font-medium text-neutral-900 dark:text-white text-left">
                          <span className="mr-2">({bookmark.count})</span>
                          <input
                            className={`w-[calc(100%-48px)] bg-transparent z-10 group-hover:cursor-text border-2 border-transparent border-dotted rounded-md ${
                              !editSet.has(bookmark.id) &&
                              "hover:border-purple-500"
                            }`}
                            onBlur={(e) => {
                              // Remove from edit set
                              editSet.delete(bookmark.id);
                              setEditSet(new Set(editSet));

                              // Update bookmark
                              updateBookmark(bookmark.id, e.target.value);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                e.target.blur();
                              }
                            }}
                            defaultValue={bookmark.title}
                          />
                        </div>

                        <div className="flex items-center space-x-2">
                          {/* Add */}
                          <button
                            className="whitespace-pre hover:scale-105 rounded-full bg-emerald-500 text-sm px-3 py-1 font-medium text-white"
                            onClick={() => {
                              addToBookmark(bookmark.id);
                            }}
                          >
                            {t("Add")}
                          </button>
                        </div>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="mt-4 flex items-center space-x-2">
                <button
                  type="button"
                  onClick={() => {
                    createBookmark();
                  }}
                  className="w-full inline-flex justify-center rounded-md px-8 py-2 bg-sky-500 hover:bg-sky-600 dark:bg-sky-600 dark:hover:bg-sky-700 text-base font-medium text-white shadow-sm"
                >
                  {t("New bookmark")}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
