import React from "react";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";
import { useClipboard } from "@mantine/hooks";

export default function EditorPanel({
  writing,
  writingObj,
  setWriting,
  wordsCounter,
  user,
  isAILoading,
  openViewHistory,
  setOpenViewHistory,
  addSuffixToText,
}) {
  const { t } = useTranslation();

  const clipboard = useClipboard({ timeout: 1000 });

  const copyText = () => {
    const copyableText = addSuffixToText(writing, user);

    // Copy to clipboard
    clipboard.copy(copyableText);
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="text-lg dark:text-white font-bold mb-2">
          {t("My Copywriting")}
        </div>

        <div
          className={`mb-2 flex items-center justify-between space-x-2 transition-all`}
        >
          {writingObj?.genHistory &&
            writingObj.genHistory.length > 0 &&
            (openViewHistory ? (
              <button
                type="button"
                onClick={() => {
                  setOpenViewHistory(!openViewHistory);
                }}
                className="text-sm font-medium transition-all bg-black text-white px-3 py-1 rounded-full shadow"
              >
                {t("Close")}
              </button>
            ) : (
              <button
                type="button"
                onClick={() => {
                  setOpenViewHistory(!openViewHistory);
                }}
                className="text-sm font-medium transition-all bg-white px-3 py-1 rounded-full shadow"
              >
                {t("View Generate History")} ({writingObj.genHistory.length})
              </button>
            ))}

          <div className="text-sm font-medium transition-all bg-white px-3 py-1 rounded-full shadow">
            {wordsCounter} {t("words")}
          </div>

          <button
            type="button"
            onClick={() => {
              copyText();
            }}
            className={`text-sm font-medium transition-all bg-white px-3 py-1 rounded-full shadow ${
              clipboard.copied
                ? "text-emerald-500 scale-105"
                : "text-neutral-800"
            }`}
          >
            {clipboard.copied ? (
              `${t("Copied")}!`
            ) : (
              <>
                <span className="sm:hidden">{t("Copy")}</span>
                <span className="hidden sm:block">
                  {t("Copy this writing")}
                </span>
              </>
            )}
          </button>
        </div>
      </div>

      <div className="relative">
        <TextareaAutosize
          className="mb-8 border-none resize-none focus:ring-0 outline-none w-full  h-96 bg-neutral-50 dark:bg-neutral-800 rounded-xl relative text-neutral-900 dark:text-white dark:placeholder:text-white p-4"
          value={writing}
          onCopy={(e) => {
            e.preventDefault();

            const start = e.target.selectionStart;
            const end = e.target.selectionEnd;

            const selectedText = writing.substring(start, end).trim();

            const copyableText = addSuffixToText(selectedText, user);
            e.clipboardData.setData("text/plain", copyableText);
          }}
          placeholder={
            isAILoading
              ? `${t("Processing")}...`
              : t(
                  "Start by selecting the type of product you want to write about"
                )
          }
          onChange={(e) => {
            setWriting(e.target.value);
          }}
          type="text"
          name="prompt"
          minRows={20}
          autoComplete="off"
          autoFocus
        />
      </div>
    </>
  );
}
