import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";
import { isMobile } from "react-device-detect";
import PromptMenu from "../prompt/PromptMenu";

function MessagesFooter({ chat, formik, isProcessing, setYoutubeOpen }) {
  const { t } = useTranslation();

  const inputRef = useRef(null);

  useEffect(() => {
    if (!isMobile) {
      inputRef.current?.focus();
    }
  }, []);

  return (
    <div
      className={`sticky bottom-0 w-full max-w-3xl flex items-end py-4 justify-between bg-white dark:bg-neutral-900 border-neutral-200 dark:border-neutral-800 px-4 sm:px-6 md:px-5 ${
        isMobile ? "" : ""
      }`}
    >
      {/* Message input */}
      <form onSubmit={formik.handleSubmit} className="grow flex">
        <div className="relative flex justify-end items-center grow">
          <PromptMenu setYoutubeOpen={setYoutubeOpen} />

          <label htmlFor="msg" className="sr-only">
            {t("Type a message")}
          </label>
          <TextareaAutosize
            ref={inputRef}
            className="resize-none text-lg sm:text-base rounded-xl form-input w-full bg-neutral-50 dark:bg-neutral-800 border-transparent dark:text-white focus:border-neutral-300 dark:focus:border-neutral-600 pr-10"
            type="text"
            name="msg"
            maxRows={10}
            minRows={1}
            value={formik.values.msg}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={
              chat?.prompt?.example ? `e.g ${chat?.prompt?.example}` : "Aa"
            }
            onKeyDown={(e) => {
              if (!isMobile) {
                // Desktop
                if (e.key === "Enter" && !e.shiftKey) {
                  if (formik.values.msg) {
                    e.preventDefault();
                    formik.submitForm();
                  }
                }
              }
            }}
            autoComplete="off"
          />
          {isProcessing ? (
            <span className="absolute pr-4 text-neutral-800/25 dark:text-white whitespace-nowrap gap-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                />
              </svg>
            </span>
          ) : (
            <button
              type="submit"
              className="absolute pr-4 text-neutral-800 dark:text-white whitespace-nowrap gap-x-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                />
              </svg>
            </button>
          )}
        </div>
      </form>
    </div>
  );
}

export default MessagesFooter;
