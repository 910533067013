import React from "react";
import { useTranslation } from "react-i18next";

function PaginationClassic(props) {
  const { t } = useTranslation();

  const hasPrevious = () => {
    if (Math.max(props.offset, 0) === 0) {
      return false;
    } else {
      return true;
    }
  };

  const hasNext = () => {
    if (props.result !== props.limit) {
      return false;
    } else {
      return true;
    }
  };

  const next = () => {
    if (hasNext()) {
      props.setOffset(props.offset + props.limit);
    }
  };

  const previous = () => {
    if (hasPrevious()) {
      props.setOffset(Math.max(props.offset - props.limit, 0));
    }
  };

  return (
    <div className="flex flex-col w-full sm:flex-row sm:items-center justify-between">
      <nav
        className="mb-4 sm:mb-0 sm:order-1"
        role="navigation"
        aria-label="Navigation"
      >
        <ul className="flex justify-between">
          <li className="ml-3 first:ml-0">
            {props.isFetching && (
              <span className="btn bg-white border-neutral-200 text-neutral-300 cursor-not-allowed gap-x-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={3}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                  />
                </svg>
                {t("Previous")}...
              </span>
            )}
            {!props.isFetching && (
              <button
                className={
                  hasPrevious()
                    ? "btn bg-white border-neutral-200 hover:border-neutral-300 text-emerald-500 gap-x-1"
                    : "btn bg-white border-neutral-200 text-neutral-300 cursor-not-allowed gap-x-1"
                }
                disabled={!hasPrevious()}
                onClick={() => previous()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={3}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                  />
                </svg>
                {t("Previous")}
              </button>
            )}
          </li>
          <li className="ml-3 first:ml-0">
            {props.isFetching && (
              <span className="btn bg-white border-neutral-200 text-neutral-300 cursor-not-allowed gap-x-1">
                {t("Next")}...
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={3}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                  />
                </svg>
              </span>
            )}
            {!props.isFetching && (
              <button
                className={
                  hasNext()
                    ? "btn bg-white border-neutral-200 hover:border-neutral-300 text-emerald-500 gap-x-1"
                    : "btn bg-white border-neutral-200 text-neutral-300 cursor-not-allowed gap-x-1"
                }
                disabled={!hasNext()}
                onClick={() => next()}
              >
                {t("Next")}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={3}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                  />
                </svg>
              </button>
            )}
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default PaginationClassic;
