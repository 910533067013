import React, { useEffect, useState } from "react";

import { ReactComponent as LoadingIcon } from "../../images/Loader.svg";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import axios from "../../api/axios";
import endpoints from "../../api/endpoints";
// the hook
import { useTranslation } from "react-i18next";
import ContactUsModal from "../modals/ContactUsModal";

export default function PricingTables() {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [premiumPlan, setPremiumPlan] = useState([]);
  const [subscription, setSubscription] = useState();
  const [open, setOpen] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  // Get User subscription status
  const getSubscription = async () => {
    setLoading(true);
    const response = await axiosPrivate.get(
      endpoints.PROFILE_URL + "/subscription"
    );
    setSubscription(response.data);
    setLoading(false);
  };

  useEffect(() => {
    getSubscription();
  }, []);

  const getProducts = async () => {
    try {
      const planRes = await axios.get(endpoints.PRODUCTS_URL, {
        signal: controller.signal,
      });

      let premiumArr = planRes.data.filter(
        (item) => item.productId === "Premium"
      );
      premiumArr = premiumArr.sort((a, b) => a.price - b.price);

      setPremiumPlan(premiumArr);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  const pay = async (plan) => {
    if (isFetching) return;
    setIsFetching(true);
    try {
      // Post to backend
      const res = await axiosPrivate.post(
        `${endpoints.PLAN_URL}/${plan?.id}/create-checkout-session`,
        {
          signal: controller.signal,
        }
      );

      // Redirect to checkout page
      window.location.href = res.data.url;
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const goToCustomerPortal = async () => {
    if (isFetching) return;
    setIsFetching(true);
    try {
      // Post to backend
      const res = await axiosPrivate.post(
        `${endpoints.PLAN_URL}/create-customer-portal-session`,
        {
          signal: controller.signal,
        }
      );

      // Redirect to checkout page
      window.location.href = res.data.url;
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const renderPayButton = () => {
    if (subscription && subscription?.hasSubscription === true) {
      return (
        <button
          className={`${
            isFetching
              ? "cursor-not-allowed bg-neutral-500/50"
              : "bg-neutral-500 hover:bg-neutral-600"
          } btn-sm text-white w-full font-bold`}
          disabled={isFetching}
          onClick={(e) => {
            e.preventDefault();
            goToCustomerPortal();
          }}
        >
          管理訂閱
        </button>
      );
    }

    // Case 2: user has no subscription
    return (
      <div className="flex justify-between items-center gap-2">
        {premiumPlan.map((item) => {
          return (
            <button
              className={`${
                isFetching
                  ? "cursor-not-allowed bg-amber-500/50"
                  : "bg-amber-500 hover:bg-amber-600"
              } btn-sm text-white w-full font-bold`}
              disabled={isFetching}
              onClick={(e) => {
                e.preventDefault();
                pay(item);
              }}
            >
              {isFetching && (
                <LoadingIcon className="w-4 h-4 animate-spin fill-white mr-2" />
              )}
              立即訂閱: {item.title} ${item.billPrice}
              {item.unit}
            </button>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <ContactUsModal open={open} setOpen={setOpen} />
      <section id="pricing">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="space-y-4">
            {/* Section header */}
            <div className="max-w-3xl dark:text-white mx-auto text-center">
              <div className="h3" data-aos="zoom-y-out">
                訂閱Articue Premium計劃，享受更多功能
              </div>
            </div>

            {/* Pricing tables */}
            <div>
              <ul className="max-w-sm md:max-w-3xl mx-auto grid gap-8 xl:gap-6 items-start">
                <li
                  className="order-first sm:order-none relative flex flex-col h-full py-5 px-6 rounded shadow-xl aos-init aos-animate bg-white ring-4 ring-amber-500 "
                  data-aos="zoom-y-out"
                  data-aos-delay={450}
                >
                  <div className="mb-4">
                    <div
                      className={`${
                        true ? "" : "invisible"
                      } text-xs inline-flex font-bold bg-amber-100 text-amber-600 rounded-md text-center px-2`}
                    >
                      最受歡迎
                    </div>
                    <div className="text-3xl font-bold mb-1">
                      Articue高級計劃
                    </div>
                  </div>
                  <div className="text font-bold">額外功能:</div>
                  <ul className="-mb-2 grow">
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>自訂品牌語氣</span>
                    </li>
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>進階模型微調 (e.g. 產品詳情，額外指令)</span>
                    </li>
                    <li className="flex items-center mb-2">
                      <svg
                        className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>無限使用額度 (免費版本只有每日2次使用額度*)</span>
                    </li>
                    <span className="text-sm">*每日香港時間23:59重置</span>
                  </ul>
                  <div className="border-t border-neutral-200 pt-5 mt-6">
                    {loading ? (
                      <div className="flex items-center justify-center">
                        <LoadingIcon className="w-4 h-4 animate-spin fill-black" />
                      </div>
                    ) : (
                      renderPayButton()
                    )}
                  </div>
                </li>
              </ul>
            </div>
            {/* Contact Us */}
            <div className="text-xs text-neutral-500 text-center">
              {t("Contact us")}
              <div className="space-x-2">
                <span>
                  Email:{" "}
                  <a
                    href="mailto:info@articue.io"
                    className="text-sky-500 underline"
                  >
                    info@articue.io
                  </a>
                </span>
                <span>
                  IG:{" "}
                  <a
                    href="https://instagram.com/articue.io"
                    className="text-sky-500 underline"
                  >
                    @articue.io
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
