/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import models from "../../partials/ai/modelSelection";
import { useLocalStorage } from "@mantine/hooks";

const renderModelName = (model) => {
  const modelObj = models.find((item) => item.key === model);
  return (
    <span
      className={
        modelObj.premium === true ? "text-indigo-600 dark:text-indigo-400" : ""
      }
    >
      {modelObj.name}
    </span>
  );
};

export default function Component({ model, setModel }) {
  const { t } = useTranslation();

  const [temperature, setTemperature] = useLocalStorage({
    key: "temperature",
    defaultValue: 0.7,
  });

  return (
    <Popover className="md:relative">
      {({ close }) => (
        <>
          <Popover.Button className="inline-flex justify-center gap-x-4 items-center px-3 py-2 text-sm leading-4 font-medium rounded-md border dark:text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
              />
            </svg>
            {renderModelName(model)}
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-60 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0 lg:max-w-3xl">
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2 overflow-auto">
                  {models.map((item) => (
                    <button
                      type="button"
                      key={item.name}
                      onClick={(e) => {
                        e.preventDefault();
                        setModel(item.key);
                        close();
                      }}
                      className={`text-left -m-3 p-3 flex items-start rounded-lg hover:bg-neutral-50 transition ease-in-out duration-150 ${
                        item.key === model && "bg-sky-100"
                      }`}
                    >
                      <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-black text-white sm:h-12 sm:w-12">
                        <item.icon
                          className="h-8 w-8 fill-white"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-4">
                        <p className="text-base font-medium text-neutral-900 flex items-center">
                          {item.name}
                          {item.premium && (
                            <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
                              {t("Premium")}
                            </span>
                          )}
                        </p>
                        <p className="mt-1 text-sm text-neutral-500">
                          {t(item.description)}
                        </p>
                      </div>
                    </button>
                  ))}
                </div>
                <div className="p-5 bg-white">
                  <div className="flex justify-between">
                    <label
                      htmlFor="temperature"
                      className="block text-sm font-medium text-neutral-700"
                    >
                      {t("Temperature")}
                    </label>
                    <span className="text-sm text-neutral-500" id="temperature">
                      {temperature}
                    </span>
                  </div>
                  <input
                    name="temperature"
                    type="range"
                    min="0"
                    max="1"
                    step="0.1"
                    value={temperature}
                    onChange={(e) => {
                      setTemperature(e.target.value);
                    }}
                    className="mt-2 w-full h-2 mb-6 bg-neutral-200 rounded-lg appearance-none cursor-pointer dark:bg-neutral-700"
                  />
                  <div className="flex justify-between">
                    <div className="block text-sm font-light text-neutral-600">
                      {t("More conservative")}
                    </div>
                    <div className="block text-sm font-light text-neutral-600">
                      {t("More creative")}
                    </div>
                  </div>
                </div>
                <Link
                  to="/pay"
                  className="block p-5 bg-neutral-50 hover:bg-neutral-100 sm:p-8"
                >
                  <div className="-m-3 px-3 flow-root rounded-md transition ease-in-out duration-150">
                    <span className="flex items-center">
                      <span className="text-base font-medium text-neutral-900">
                        {t("Unlock Premium")}
                      </span>
                      <span className="ml-3 inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium leading-5 bg-amber-100 text-amber-800">
                        {t("Recommended")}
                      </span>
                    </span>
                    <span className="mt-1 block text-sm text-neutral-500">
                      {t(
                        "Empower your chat response by 80% with our premium model, starting at only $100 HKD per month."
                      )}
                    </span>
                  </div>
                </Link>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
