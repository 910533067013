/* eslint-disable no-undef */
import { Link, useNavigate, useLocation } from "react-router-dom";
import AuthHeader from "../../partials/auths/AuthHeader";

// the hook
import { useTranslation } from "react-i18next";
import SignInComponent from "../../partials/auths/SignInComponent";
import GoogleSSOComponent from "../../partials/auths/GoogleSSOComponent";

function Signin() {
  // i18n
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const redirect = (data) => {
    // Return to previous page
    navigate(from, { replace: true });
  };

  return (
    <main className="bg-white dark:bg-neutral-900">
      <div className="relative md:flex">
        {/* Content */}
        <div className="w-full">
          <div className="h-full flex flex-col after:flex-1">
            {/* Header */}
            <AuthHeader title={t("Sign In")} />

            <div className="max-w-sm mx-auto px-2 sm:px-4 py-8 w-96">
              <SignInComponent callback={redirect} />

              {/* Footer */}
              <div className="pt-5 mt-6 border-t border-neutral-200 space-y-6">
                <div className="flex items-center justify-between">
                  <GoogleSSOComponent callback={redirect} />
                </div>

                <div className="flex justify-center text-neutral-800 dark:text-white text-sm gap-x-2">
                  {t("Don’t you have an account?")}
                  <Link
                    className="font-medium text-sky-500 hover:text-sky-600"
                    to="/signup"
                  >
                    {t("Sign Up")}
                  </Link>
                </div>
                <div className="flex justify-center text-neutral-800 dark:text-white text-sm gap-x-2">
                  <Link
                    className="font-medium text-sky-500 hover:text-sky-600"
                    to="/redeem"
                  >
                    {t("Redeem your promo code")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Signin;
