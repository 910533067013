import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PasswordStrengthBar from "react-password-strength-bar";
import LoadingBtn from "../../components/LoadingBtn";
// the hook
import { useTranslation } from "react-i18next";

import endpoints from "../../api/endpoints";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";

function SecurityPanel(props) {
  // i18n
  const { t } = useTranslation();

  const [isSaving, setIsSaving] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  // Reset Password Handler
  const resetPasswordHandler = async (values, { setErrors, resetForm }) => {
    setIsSaving(true);

    try {
      const res = await axiosPrivate.post(endpoints.CHANGEPW_URL, values);
      console.log(res);

      if (res.data.msg === "PASSWORD_CHANGED") {
        toast.success(t("Password changed successfully"));
      }

      // Reset Form
      resetForm();
    } catch (error) {
      if (error.response.data.errors.msg === "WRONG_PASSWORD") {
        toast.error(t("Wrong old password"));
      } else {
        toast.error(error.response.data.errors.msg);
      }
    } finally {
      setIsSaving(false);
    }
  };

  const resetPasswordForm = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().trim().required(t("Old password is required")),
      newPassword: Yup.string()
        .trim()
        .min(8, t("Password must be at least 8 characters"))
        .required(t("Password is required")),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("newPassword"), null],
        t("Passwords must match")
      ),
    }),
    onSubmit: resetPasswordHandler,
  });

  return (
    <div className="border-gray-200 shadow p-4 rounded-lg">
      <form onSubmit={resetPasswordForm.handleSubmit}>
        {/* Panel body */}
        <div className="space-y-6">
          <h2 className="text-2xl text-neutral-800 dark:text-white font-bold mb-5">
            {t("Change Password")}
          </h2>

          {/* Password */}
          <section>
            <div className="mt-5">
              <div className="space-y-4">
                <div className="">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="oldPassword"
                  >
                    {t("Old Password")}
                  </label>
                  <input
                    id="oldPassword"
                    name="oldPassword"
                    className="form-input w-full dark:bg-neutral-800 dark:border-neutral-600 dark:text-white"
                    type="password"
                    onChange={resetPasswordForm.handleChange}
                    value={resetPasswordForm.values.oldPassword}
                  />
                  <p className="text-red-600 text-sm">
                    {resetPasswordForm.errors.oldPassword &&
                    resetPasswordForm.touched.oldPassword
                      ? `${t("Error")}: ${resetPasswordForm.errors.oldPassword}`
                      : null}
                  </p>
                </div>
                <div className="">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="newPassword"
                  >
                    {t("New Password")}
                  </label>
                  <input
                    id="newPassword"
                    name="newPassword"
                    className="form-input w-full dark:bg-neutral-800 dark:border-neutral-600 dark:text-white"
                    type="password"
                    onChange={resetPasswordForm.handleChange}
                    value={resetPasswordForm.values.newPassword}
                  />
                  <p className="text-red-600 text-sm">
                    {resetPasswordForm.errors.newPassword &&
                    resetPasswordForm.touched.newPassword
                      ? `${t("Error")}: ${resetPasswordForm.errors.newPassword}`
                      : null}
                  </p>
                  <PasswordStrengthBar
                    minLength={8}
                    scoreWordClassName="text-sm font-medium"
                    password={resetPasswordForm.values.newPassword}
                  />
                </div>
                <div className="">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="confirmPassword"
                  >
                    {t("Confirm New Password")}
                  </label>
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    className="form-input w-full dark:bg-neutral-800 dark:border-neutral-600 dark:text-white"
                    type="password"
                    onChange={resetPasswordForm.handleChange}
                    value={resetPasswordForm.values.confirmPassword}
                  />
                  <p className="text-red-600 text-sm">
                    {resetPasswordForm.errors.confirmPassword &&
                    resetPasswordForm.touched.confirmPassword
                      ? `${t("Error")}: ${
                          resetPasswordForm.errors.confirmPassword
                        }`
                      : null}
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* Panel footer */}
        <footer>
          <div className="flex flex-col py-5 border-neutral-200">
            <div className="flex">
              {isSaving ? (
                <LoadingBtn text={t("Saving")} />
              ) : (
                <button
                  type="submit"
                  className="px-10 btn bg-sky-500 hover:bg-sky-600 text-white"
                >
                  {t("Save")}
                </button>
              )}
            </div>
          </div>
        </footer>
      </form>
    </div>
  );
}

export default SecurityPanel;
