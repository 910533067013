import React, { useState, useEffect } from "react";
import CouponTableItem from "./CouponTableItem";
import { useTranslation } from "react-i18next";

export default function CouponTable({
  selectedItems,
  coupons,
  selectCouponHandler,
}) {
  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    setList(coupons);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coupons]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setIsCheck(list?.map((li) => li.id));
    if (selectAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setSelectAll(false);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  useEffect(() => {
    selectedItems(isCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheck]);

  return (
    <div className="bg-white dark:bg-neutral-800 shadow-lg rounded-sm dark:border dark:border-neutral-600 relative">
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full divide-y divide-slate-200">
            {/* Table header */}
            <thead className="text-xs uppercase text-black dark:text-white">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="flex items-center">
                    <label className="inline-flex">
                      <span className="sr-only">{t("Select all")}</span>
                      <input
                        className="form-checkbox"
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </label>
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">{t("Code")}</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">{t("Remark")}</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">
                    {t("Redemption count")} ({t("Total")})
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">
                    {t("Premium Days")}
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">{t("Expiry")}</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">
                    {t("Created at")}
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">{t("Details")}</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            {list.length > 0 ? (
              list.map((coupon) => {
                return (
                  <CouponTableItem
                    key={coupon.id}
                    coupon={coupon}
                    selectCouponHandler={selectCouponHandler}
                    handleClick={handleClick}
                    isChecked={isCheck.includes(coupon.id)}
                  />
                );
              })
            ) : (
              <tbody>
                <tr>
                  <td colSpan="7">
                    <div className="text-center py-4 dark:text-white">
                      {t("No data")}
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
}
