import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

function UsersTableItem(props) {
  const { t } = useTranslation();
  return (
    <tr>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left font-bold flex items-center">
          {props.user?.firstName} {props.user?.lastName}
          {props.user?.isVerifiedAccount && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="ml-1 w-6 h-6 text-sky-600"
            >
              <path
                fillRule="evenodd"
                d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">{props.user.email}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">
          {moment(props.user.createdAt).fromNow()}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">
          {moment(props.user.lastLogin).fromNow()}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        {props.user.role === "user" && (
          <div className="text-xs inline-flex font-extrabold bg-emerald-100 dark:bg-emerald-200 text-emerald-600 rounded-full text-center px-2.5 py-1">
            {t("User")}
          </div>
        )}
        {props.user.role === "admin" && (
          <div className="text-xs inline-flex font-extrabold bg-rose-100 dark:bg-rose-200 text-rose-600 rounded-full text-center px-2.5 py-1">
            {t("Admin")}
          </div>
        )}
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        {!props.user.isPremium ? (
          <div className="text-xs inline-flex font-extrabold bg-emerald-100 dark:bg-emerald-200 text-emerald-600 rounded-full text-center px-2.5 py-1">
            {t("Free")}
          </div>
        ) : (
          <div className="text-xs inline-flex font-extrabold bg-indigo-100 text-indigo-600 rounded-full text-center px-2.5 py-1">
            {t("Premium")}
          </div>
        )}
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        <Link
          to={`/allusers/${props.user.id}/edit`}
          className="bg-neutral-50 hover:bg-neutral-100 text-black block px-4 py-1 text-sm rounded-md"
        >
          {t("Edit")}
        </Link>
      </td>
    </tr>
  );
}

export default UsersTableItem;
