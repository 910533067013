import React, { useState } from "react";
import moment from "moment";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function GiftcodesTableItem({
  coupon,
  handleClick,
  isChecked,
  selectCouponHandler,
}) {
  const { t } = useTranslation();

  const [descriptionOpen, setDescriptionOpen] = useState(false);

  const [statistics, setStatistics] = useState([]);
  const [history, setHistory] = useState([]);
  const [histLoading, setHistLoading] = useState(true);
  const [statLoading, setStatLoading] = useState(true);

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const getRedemptionStatistics = async () => {
    try {
      setStatLoading(true);
      const res = await axiosPrivate.get(
        endpoints.PROMOCODES_URL + `/${coupon.id}/stat`,
        {
          signal: controller.signal,
        }
      );

      setStatistics(res.data);
      setStatLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const openDetailHandler = async () => {
    setDescriptionOpen(!descriptionOpen);
    await Promise.all([getRedemptionStatistics()]);
  };

  const getUrl = (code) => {
    return `https://app.articue.io/redeem?code=${code}`;
  };

  const copyLink = async (code) => {
    const url = getUrl(code);

    // Copy to clipboard
    await navigator.clipboard.writeText(url);

    // Toast
    toast.success(t("Copied to clipboard"));
  };

  return (
    <tbody className="text-sm dark:text-white">
      {/* Row */}
      <tr key={coupon.id}>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
          <div className="flex items-center">
            <label className="inline-flex">
              <span className="sr-only">{t("Select")}</span>
              <input
                id={coupon.id}
                className="form-checkbox"
                type="checkbox"
                onClick={handleClick}
                onChange={handleClick}
                checked={isChecked}
              />
            </label>
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="flex items-center">
            <button
              type="button"
              onClick={() => {
                selectCouponHandler(coupon);
              }}
              className="mr-2 inline-flex items-center border border-transparent text-xs font-medium rounded text-sky-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                />
              </svg>
            </button>
            <button
              type="button"
              onClick={() => {
                copyLink(coupon.code);
              }}
              className="mr-2 inline-flex items-center border border-transparent text-xs font-medium rounded text-sky-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.7}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                />
              </svg>
            </button>
            <div className="font-bold text-sky-500">{coupon.code}</div>
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          {!coupon.remark ? "No remark" : coupon.remark}
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="font-medium">
            {coupon.hasLimit && `${coupon.redeemed}/${coupon.qty}`}
            {!coupon.hasLimit && `${coupon.redeemed}/${t("unlimited")}`}
          </div>
        </td>
        <td>
          <div className="font-bold text-amber-500">
            {t("Premium Days")}: {coupon.premiumDays}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="font-medium">
            {!coupon.expireOn
              ? "No expiry"
              : moment(coupon.expireOn).format("DD-MM-YYYY")}
          </div>
          {coupon.expireOn &&
            (moment(coupon.expireOn).isAfter() ? (
              <div className="text-sm text-sky-500">
                ({moment(coupon.expireOn).fromNow("days")})
              </div>
            ) : (
              <div className="text-sm text-rose-500">Expired</div>
            ))}
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div>{moment(coupon.createdAt).format("DD-MM-YYYY")}</div>
          <div>Last Update:{moment(coupon.updatedAt).format("DD-MM-YYYY")}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
          <div className="flex items-center">
            <button
              className={`text-slate-400 hover:text-slate-500 transform ${
                descriptionOpen && "rotate-180"
              }`}
              aria-expanded={descriptionOpen}
              onClick={() => openDetailHandler()}
              aria-controls={`description-${coupon.id}`}
            >
              <span className="sr-only">{t("Menu")}</span>
              <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">
                <path d="M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z" />
              </svg>
            </button>
          </div>
        </td>
      </tr>
      <tr role="region" className={`${!descriptionOpen && "hidden"}`}>
        <td colSpan="10" className="px-2 first:pl-5 last:pr-5 py-3">
          <div className="flex flex-col space-y-2 bg-neutral-50 dark:bg-neutral-900 p-3 -mt-3">
            <div>
              <div className="font-medium text-sky-500 underline">
                {t("Statistics")}
              </div>
              <div className="w-full flex justify-center items-center ">
                {/* Statistic */}
                {statLoading && (
                  <svg
                    className="animate-spin w-4 h-4 fill-current shrink-0"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
                  </svg>
                )}
                {/* Empty State */}
                {!statLoading && statistics.length === 0 && (
                  <div>{t("No Data")}</div>
                )}
                {/* Data */}
                {!statLoading && statistics.length > 0 && (
                  <ul className="divide-y divide-neutral-200 w-full text-neutral-900 dark:text-neutral-200">
                    {statistics.map((row) => {
                      return (
                        <li key={row.createdAt} className="py-4 flex">
                          <div className="ml-3">
                            <p className="text-sm font-medium">
                              {row.user.email}
                            </p>
                            <p className="text-sm text-neutral-500">
                              {t("Redeemed")}{" "}
                              {moment(row.createdAt).startOf("day").fromNow()} (
                              {moment(row.createdAt)
                                .startOf("day")
                                .format("DD-MM-YYYY")}
                              )
                            </p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  );
}
