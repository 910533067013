import resolveConfig from "tailwindcss/resolveConfig";

export const tailwindConfig = () => {
  // Tailwind config
  return resolveConfig("./src/css/tailwind.config.js");
};

export const hexToRGB = (h) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }
  return `${+r},${+g},${+b}`;
};

export const formatValue = (value) =>
  Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "HKD",
    maximumSignificantDigits: 3,
    notation: "compact",
  }).format(value);

export const formatThousands = (value) =>
  Intl.NumberFormat("en-US", {
    maximumSignificantDigits: 3,
    notation: "compact",
  }).format(value);

export const getId = (url) => {
  try {
    const regex =
      /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
    return regex.exec(url)[3];
  } catch {
    return "";
  }
};

// Check youtube url and extract youtube id
export const getYoutubeVidFromUrl = (url) => {
  // Extract youtube id from url
  var urlSplit = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  var youtubeId =
    urlSplit[2] !== undefined
      ? urlSplit[2].split(/[^0-9a-z_\-]/i)[0]
      : urlSplit[0];

  if (!youtubeId || youtubeId === url) {
    youtubeId = getId(url);
  }

  if (youtubeId) {
    return youtubeId;
  }

  return "";
};

export const renderDisplayMarkdown = (message) => {
  const finalMsg = message
    .split("\n")
    .map((line, index, array) => {
      if (index !== array.length - 1) {
        let previousLine = array[index - 1] || "";
        if (previousLine.length !== 2) {
          return line + "  ";
        }
      }
      return line;
    })
    .join("\n");

  return finalMsg;
};

export const suffix =
  "\n\n*文案由Articue生成，一個專為香港中文/廣東話而設的AI小編。立即到 https://articue.io 註冊試用！\n*想去除這個段廣告？立即購買Articue Premium計劃！";

export const addSuffixToText = (text, user) => {
  if (user && user?.isPremium === true) {
    return text;
  }
  return text + suffix;
};
