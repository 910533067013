/* eslint-disable no-undef */
import { useEffect, useState } from "react";
import axios from "../../api/axios";
import endpoints from "../../api/endpoints";
import useAuth from "../../hooks/useAuth";
import { toast } from "react-toastify";

const GoogleSSOComponent = ({ callback }) => {
  const { setAuth } = useAuth();

  const [isInited, setIsInited] = useState(false);

  const initGoogle = () => {
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: async (response) => {
        if (response.credential) {
          // Send credential to server
          const res = await axios.post(
            endpoints.GOOGLE_SSO_AUTH,
            {
              credential: response.credential,
            },
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          );

          setAuth(res.data);

          // Return to previous page
          if (callback) {
            callback(res.data);
          }
        }
      },
    });

    google.accounts.id.renderButton(document.getElementById("googleSSOBtn"), {
      theme: "filled_blue",
      size: "large",
    });

    setIsInited(true);
  };

  useEffect(() => {
    try {
      if (!isInited) {
        initGoogle();
      }
    } catch (e) {
      toast.error(e.message);
      console.log(e);
    }
  });

  return (
    <div
      className="w-full flex items-center justify-center"
      id="googleSSOBtn"
    ></div>
  );
};

export default GoogleSSOComponent;
