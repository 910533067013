import { useEffect } from "react";
import UserMenu from "../components/DropdownProfile";
import { Helmet, HelmetProvider } from "react-helmet-async";
import useRefreshToken from "../hooks/useRefreshToken";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Header(props) {
  const refresh = useRefreshToken();

  const { t } = useTranslation();

  useEffect(() => {
    // Refresh
    refresh();
  }, []);

  return (
    <header
      className={`duration-0 sticky top-0 left-0 right-0 bg-white/80 transition-all dark:bg-neutral-900/80 backdrop-blur-sm z-30`}
    >
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 -mb-px">
          {/* Header: Left side */}
          <div className="flex gap-x-2">
            {/* Hamburger button */}
            {!props.customBtn && props.sidebarOpen !== undefined && (
              <button
                className="text-neutral-500 hover:text-neutral-600 lg:hidden"
                aria-controls="sidebar"
                aria-expanded={props.sidebarOpen}
                onClick={() => props.setSidebarOpen(!props.sidebarOpen)}
              >
                <span className="sr-only">Open sidebar</span>
                <svg
                  className="w-6 h-6 fill-current"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="4" y="5" width="16" height="2" />
                  <rect x="4" y="11" width="16" height="2" />
                  <rect x="4" y="17" width="16" height="2" />
                </svg>
              </button>
            )}
            {props.customBtn && props.customBtn}
          </div>

          {/* Header: Right side */}
          <div className="flex items-center gap-x-4">
            {props?.user?.isPremium === false && (
              <>
                <Link
                  to="/pay"
                  className="hidden text-sm sm:inline-flex space-x-2 items-center font-medium bg-amber-100 hover:scale-105 transition-all text-amber-600 rounded-full text-center px-2.5 py-1"
                >
                  <span className="whitespace-nowrap">
                    {t("Upgrade to Premium")}
                  </span>
                </Link>
                <Link
                  to="/pay"
                  className="sm:hidden text-sm inline-flex space-x-2 items-center font-medium bg-amber-100 hover:scale-105 transition-all text-amber-600 rounded-full text-center px-2.5 py-1"
                >
                  <span className="whitespace-nowrap">{t("Upgrade")}</span>
                </Link>
              </>
            )}

            {props.otherComponents && props.otherComponents}
            <UserMenu align="right" user={props.user} />
          </div>
        </div>
      </div>
      {/* Dynamic Title */}
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          {props.title && <title>{props.title} | Articue</title>}
        </Helmet>
      </HelmetProvider>
    </header>
  );
}

export default Header;
