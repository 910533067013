/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";

export default function ResetPasswordEmailSuccessModal({ open, setOpen }) {
  const { t } = useTranslation();

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-40 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex h-screen items-center justify-center text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 backdrop-blur-sm backdrop-brightness-75 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="mx-4 sm:mx-0 inline-block align-bottom bg-white dark:bg-neutral-900 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg w-full p-6">
              <div className="sm:flex sm:items-start">
                <div className="text-left">
                  <Dialog.Title
                    as="h4"
                    className="h4 font-medium text-neutral-900 dark:text-white "
                  >
                    {t("Success")}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-neutral-500 dark:text-neutral-300">
                      {t(
                        "The reset password email is sent to your email address"
                      )}
                      !
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 flex items-center space-x-2">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md px-8 py-2 bg-black text-white dark:bg-white text-base font-medium dark:text-neutral-700 shadow-sm dark:hover:bg-neutral-50"
                  onClick={() => setOpen(false)}
                >
                  {t("OK")}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
