import { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import ProcessingBtn from "../../components/btn/ProcessingBtn";
import endpoints from "../../api/endpoints";
import axios from "../../api/axios";

import Warning from "../../components/alert/Warning";
import Danger from "../../components/alert/Danger";
import ResetPasswordEmailSuccessModal from "../../partials/modals/ResetPasswordEmailSuccessModal";
import AuthHeader from "../../partials/auths/AuthHeader";

// the hook
import { useTranslation } from "react-i18next";

function ResetPassword() {
  // i18n
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  // Warning
  const [warning, setWarning] = useState();

  const submitHandler = async (values, { setErrors, resetForm }) => {
    setLoading(true);

    try {
      await axios.post(endpoints.FORGOTPW_URL, formik.values);

      setOpen(true);
    } catch (err) {
      if (!err?.response) {
        console.error("No Server res");
      } else {
        const error = err.response?.data?.errors?.msg;

        if (error === "WRONG_PASSWORD") {
          setWarning(<Warning title={t("Wrong Password")} />);
        } else if (error === "USER_DOES_NOT_EXIST") {
          setWarning(<Warning title={t("User does not exist")} />);
        } else if (error === "BLOCKED_USER") {
          setWarning(<Danger title={t("User is blocked")} />);
        } else if (err.code === "ERR_NETWORK") {
          setWarning(<Danger title={t("No Network")} />);
        } else {
          setWarning(<Danger title={t("Invalid Login")} />);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("This is not a valid email"))
        .required(t("This field is required")),
    }),
    onSubmit: submitHandler,
  });

  return (
    <main className="bg-white dark:bg-neutral-900">
      {/* Modal Component */}
      <ResetPasswordEmailSuccessModal open={open} setOpen={setOpen} />
      <div className="relative md:flex">
        {/* Content */}
        <div className="w-full">
          <div className="h-full flex flex-col after:flex-1">
            {/* Header */}
            <AuthHeader title={t("Reset your Password")} />

            <div className="max-w-sm mx-auto px-2 sm:px-4 py-8 w-96">
              <h1 className="text-3xl text-neutral-800 dark:text-white font-bold mb-6">
                {t("Reset your Password")} ✨
              </h1>
              {/* Warning */}
              {warning}

              {/* Form */}
              <form onSubmit={formik.handleSubmit}>
                <div className="space-y-4">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1 text-neutral-800 dark:text-white"
                      htmlFor="email"
                    >
                      {t("Email")} <span className="text-rose-500">*</span>
                    </label>
                    <input
                      name="email"
                      className="form-input w-full dark:text-white dark:bg-neutral-800 dark:border-neutral-600"
                      type="string"
                      value={formik.email}
                      onChange={formik.handleChange}
                    />
                    <p className="text-red-600 text-sm">
                      {formik.errors.email
                        ? `${t("Error")}: ${formik.errors.email}`
                        : null}
                    </p>
                  </div>
                </div>
                <div className="flex justify-end mt-6">
                  {!isLoading && (
                    <button
                      type="submit"
                      className="btn bg-sky-500 hover:bg-sky-600 text-white whitespace-nowrap w-full"
                    >
                      {t("Submit")}
                    </button>
                  )}
                  {isLoading && <ProcessingBtn title={t("Submit")} />}
                </div>
                <div className="flex justify-center mt-6">
                  <Link
                    to="/"
                    className="text-sky-500 hover:text-sky-600 underline"
                  >
                    {t("Back To Home")}
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ResetPassword;
