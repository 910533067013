import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DeleteAllChatsModal from "../modals/DeleteAllChatsModal";
import EditChatModel from "../modals/EditChatModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";

import DMEditMenu from "./DMEditMenu";

function DirectMessages({
  setMsgSidebarOpen,
  chatrooms,
  setChatrooms,
  id,
  deleteChatRoomHandler,
}) {
  const { t } = useTranslation();

  const [openDeleteAllModal, setDeleteAllModalOpen] = useState(false);
  const [openEditModal, setEditModalOpen] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [target, setTarget] = useState();

  const updateChatroomTitle = async (id, title) => {
    try {
      if (id) {
        const res = await axiosPrivate.patch(endpoints.CHAT_URL + "/" + id, {
          title,
        });

        // Update chatroom
        setChatrooms((prev) => {
          const newChatrooms = prev.map((chatroom) => {
            if (chatroom.id === id) {
              return {
                ...chatroom,
                title: res.data.title,
              };
            } else {
              return chatroom;
            }
          });
          return newChatrooms;
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const renderChat = (props, idx) => {
    return (
      <div key={idx} className="relative flex justify-between items-center px-4">
        <Link
          to={`/chat/${props.id}`}
          onClick={() => {
            setMsgSidebarOpen(false);
          }}
          className={`relative flex items-center w-full p-2 rounded transition group hover:bg-neutral-50 overflow truncate ${
            props?.id === id ? "bg-neutral-100 group-hover:bg-neutral-300" : ""
          }`}
          // onClick={() => setMsgSidebarOpen(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className={`absolute w-6 h-6 text-sm font-medium ${
              props?.id === id ? "text-neutral-800" : "text-white"
            } group-hover:text-neutral-800`}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z"
            />
          </svg>

          <div className="flex items-center justify-between w-full">
            <div
              className={`truncate pl-8 text-sm font-medium bg-transparent w-full focus:cursor-text ${
                props?.id === id ? "text-neutral-800" : "text-white"
              } group-hover:text-neutral-800`}
            >
              {props.title}
            </div>
          </div>
        </Link>
        <DMEditMenu
          deleteChatRoomHandler={deleteChatRoomHandler}
          props={props}
          setTarget={setTarget}
          setEditModalOpen={setEditModalOpen}
        />
      </div>
    );
  };

  return (
    <div>
      <EditChatModel
        open={openEditModal}
        setOpen={setEditModalOpen}
        updateHandler={updateChatroomTitle}
        target={target}
      />
      <DeleteAllChatsModal
        open={openDeleteAllModal}
        setOpen={setDeleteAllModalOpen}
        setChatrooms={setChatrooms}
      />
      <div className="z-10 px-2 py-4 space-y-2 bg-neutral-900 rounded-t-lg sticky top-0">
        <div className="px-2 flex items-center">
          <button
            onClick={() => {
              setMsgSidebarOpen(false);
            }}
            className="flex items-center space-x-2 w-full text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
            <div className="text-lg font-bold">{t("New Chat")}</div>
          </button>
          <div className="flex items-center space-x-1 justify-end">
            <button
              to="/chat"
              onClick={() => {
                setMsgSidebarOpen(false);
              }}
              className="h-10 w-fit inline-flex justify-center gap-x-4 items-center px-3 py-2 text-sm leading-4 font-medium rounded-md shadow-sm text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={3}
                stroke="currentColor"
                className="w-6 h-6 text-white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="px-2 text-sm font-semibold text-neutral-400 uppercase mb-3">
          <div className="flex justify-between items-center">
            <div>{t("Chats")}</div>
            <div>
              <button
                onClick={() => {
                  setDeleteAllModalOpen(true);
                }}
                className="inline-flex items-center px-1.5 py-1 border border-transparent leading-4 font-medium rounded-md hover:text-white hover:bg-rose-600 transition-all"
              >
                {t("Delete all chats")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="space-y-2 h-full overflow-auto no-scrollbar mb-20">
        {chatrooms.map((chat, idx) => renderChat(chat, idx))}
        {chatrooms.length === 0 && (
          <div>
            <div className="text-center text-sm font-medium text-neutral-400">
              {t("Empty")}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DirectMessages;
