import Register from "./pages/auth/Signup";
import Login from "./pages/auth/Signin";
import Signout from "./pages/auth/Signout";
import ResetPassword from "./pages/auth/ResetPassword";
import PageNotFound from "./pages/error/PageNotFound";
import Verify from "./pages/auth/Verify";
import Reset from "./pages/auth/Reset";

import Docs from "./pages/docs/Docs";
import Eshops from "./pages/eshops/Eshops";
import Trash from "./pages/docs/Trash";
import Editor from "./pages/docs/Editor";
import Chatroom from "./pages/chat/Chatroom";

// Settings
import Settings from "./pages/settings/Settings";

// WritingViewer
import WritingViewer from "./pages/writings/WritingViewer";

// Pay
import PlanPay from "./pages/pay/PlanPay";
import PaySuccess from "./pages/pay/PaySuccess";

// Loading
import Loader from "./partials/Loader";

// Users
import AllUsers from "./pages/users/AllUsers";
import EditUser from "./pages/users/EditUser";

// Coupon Code
import AllCoupons from "./pages/coupons/AllCoupons";
// Redeem
import RedeemHome from "./pages/redeem/RedeemHome";

// For Reference Only
import Layout from "./components/Layout";
import Unauthorized from "./components/Unauthorized";
import RequireAuth from "./components/RequireAuth";
import { Routes, Route, Navigate } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="signin" element={<Login />} />
        <Route path="signup" element={<Register />} />
        <Route path="signout" element={<Signout />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="reset/:resetToken" element={<Reset />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="verify/:verify_token" element={<Verify />} />
        <Route path="loading" element={<Loader />} />

        <Route path="redeem" element={<RedeemHome />} />

        {/* Pay */}
        <Route path="pay" element={<PlanPay />} />
        <Route path="pay/success" element={<PaySuccess />} />

        <Route path="/writings/:id" element={<WritingViewer />} />

        {/* we want to protect these routes */}
        {/* <Route element={<PersistLogin />}> */}
        <Route>
          <Route element={<RequireAuth allowedRoles="user" />}>
            <Route path="/" element={<Navigate to="/docs" />} />

            <Route path="/docs" element={<Docs />} />
            <Route path="/eshops" element={<Eshops />} />
            <Route path="/trash" element={<Trash />} />
            <Route path="/docs/:id" element={<Editor />} />

            {/* Chat */}
            <Route path="/chat/" element={<Chatroom />} />
            <Route path="/chat/:id" element={<Chatroom />} />

            {/* Settings */}
            <Route path="/settings/" element={<Settings />} />
          </Route>

          <Route element={<RequireAuth allowedRoles="admin" />}>
            {/* Users */}
            <Route path="/allusers" element={<AllUsers />} />
            <Route
              path="/allusers/:id/edit"
              element={<EditUser fallback="/allusers" />}
            />

            {/* Coupons */}
            <Route path="/allcoupons" element={<AllCoupons />} />
          </Route>
        </Route>

        {/* catch all */}
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
