import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useState } from "react";
import { Link } from "react-router-dom";
import ModelSelectionFlyout from "../flyout/ModelSelectionFlyout";

function MessagesHeader({
  msgSidebarOpen,
  setMsgSidebarOpen,
  title,
  model,
  setModel,
  user,
  setPremiumLockModalOpen,
}) {
  const { t } = useTranslation();

  return (
    <div className="w-full fixed top-0 bg-white/80 shadow dark:bg-neutral-900/80 backdrop-blur-sm">
      {/* Dynamic Title */}
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          {title && <title>{title} | Articue</title>}
        </Helmet>
      </HelmetProvider>
      <div className="flex items-center justify-between px-4 sm:px-6 md:px-5 h-16">
        {/* Menu */}
        <div className="flex items-center justify-start">
          <Link to="/" className="text-neutral-400 hover:text-neutral-500 mr-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              ></path>
            </svg>
          </Link>
        </div>

        {/* Model Title */}
        <div className="flex items-center space-x-2">
          <ModelSelectionFlyout model={model} setModel={setModel} />
        </div>

        {/* Buttons on the right side */}
        <div className="flex items-center space-x-2 justify-end">
          <button
            className="text-neutral-400 hover:text-neutral-500 mr-4"
            onClick={() => setMsgSidebarOpen(!msgSidebarOpen)}
            aria-controls="messages-sidebar"
            aria-expanded={msgSidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

export default MessagesHeader;
