/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import ChatThreadUI from "../ui/ChatThreadUI";
import ChatWritingUI from "../ui/ChatWritingUI";

export default function Modal({ open, setOpen, createWriting }) {
  // i18n
  const { t } = useTranslation();

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-center justify-center min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 backdrop-blur-sm backdrop-brightness-75 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block bg-white dark:bg-neutral-900 rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-4xl w-full p-6">
              <div className="flex items-center flex-col sm:flex-row space-y-8 sm:space-y-0 sm:space-x-8">
                <button
                  onClick={() => {
                    createWriting();
                    setOpen(false);
                  }}
                  className="w-full block group outline-none"
                >
                  <div className="py-3 transition-all font-medium text-xl flex items-center justify-center text-black dark:text-white">
                    {t("Create a new document")}
                    <span className="ml-2 inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-amber-100 text-amber-800">
                      {t("Latest")}
                    </span>
                  </div>
                  <div className="bg-white w-full flex justify-center overflow-y-scroll group border border-neutral-200 transition-all group-hover:ring-4 group-hover:ring-sky-500 rounded-lg">
                    <ChatWritingUI />
                  </div>
                </button>
                <Link to="/chat" className="w-full block group outline-none">
                  <div className="py-3 transition-all font-medium text-xl flex items-center justify-center text-black dark:text-white">
                    {t("Use AI Chat")}
                  </div>
                  <div className="bg-white w-full flex justify-center overflow-y-scroll group border border-neutral-200 transition-all group-hover:ring-4 group-hover:ring-sky-500 rounded-lg">
                    <ChatThreadUI />
                  </div>
                </Link>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
