import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UserMenu from "../../components/DropdownProfile";

import useAuth from "../../hooks/useAuth";
import { ReactComponent as Logo } from "../../images/logo/Logo.svg";

import PricingTables from "../../partials/pricing/PricingTable";

function PlanPay() {
  // i18n

  const { auth } = useAuth();
  const user = auth?.user;

  return (
    <>
      {/* Header */}
      <header className="bg-white/80 dark:bg-neutral-900/80 backdrop-blur-sm">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16 -mb-px">
            {/* Logo */}
            {user ? (
              <button
                onClick={() => {
                  // if the previous page is the same domain, go back, otherwise, go to '/'
                  if (document.referrer.includes(window.location.origin)) {
                    window.history.back();
                  } else {
                    window.location.href = "/";
                  }
                }}
                className="text-neutral-500 hover:text-neutral-600 dark:text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                  />
                </svg>
              </button>
            ) : (
              <Link className="block" to="/">
                {/* Logo */}
                <Logo className="h-8 w-8" />
              </Link>
            )}

            <UserMenu align="right" user={user} />
          </div>
        </div>
      </header>

      <main>
        <PricingTables />
      </main>
    </>
  );
}

export default PlanPay;
