/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";

// Video
import Tutorial from "../../assets/tutorials/editors/tutorial.mp4";
import Tutorial01 from "../../assets/tutorials/editors/tutorial_01.mp4";
import Tutorial02 from "../../assets/tutorials/editors/tutorial_02.mp4";
import Tutorial03 from "../../assets/tutorials/editors/tutorial_03.mp4";
import Tutorial04 from "../../assets/tutorials/editors/tutorial_04.mp4";
import Tutorial05 from "../../assets/tutorials/editors/tutorial_05.mp4";
import Tutorial06 from "../../assets/tutorials/editors/tutorial_06.mp4";

export default function EditorTutorialModal({ open, setOpen }) {
  const [step, setStep] = useState(0);

  const steps = [
    {
      title: "快速上手！立即成為AI行銷專家🚀",
      description:
        "Articue是一個先進的AI行銷內容生成工具！你只需要提供產品名稱，AI小編就會馬上幫你分析產品目標顧客，5秒內為妳寫一篇產品介紹文案！馬上看看怎樣使用吧！",
      video: Tutorial,
    },
    {
      title: "第一步: 輸入產品名稱",
      description:
        "在頁面的第一個文字方格輸入你的產品名稱，例如是'Samsung - The Freestyle 微型投影機 SP-LSP3BLAXZK $4,190'。你可以在後面表示一些產品資料，例如價錢，產地等等。",
      video: Tutorial01,
    },
    {
      title: "第二步: 選擇產品類型",
      description:
        "我們有十多種產品類型，選擇適合的產品類型確保你的產品文案輸出有最佳的效果。例如我們的Samsung的投影機，我們可以選擇'家庭電器'",
      video: Tutorial02,
    },
    {
      title: "第三步: 點擊'生成'按鈕",
      description:
        "一切就緒後，點擊'生成'按鈕，我們的AI小編會馬上為你生成一篇產品介紹文案！如果系統出現'錯誤'，請再次點擊'生成'按鈕。當然，你也可以點擊'生成'按鈕多次，直到你滿意為止。",
      video: Tutorial03,
    },
    {
      title: "第四步: 直接修改文案",
      description:
        "如果你需要修改文案，你可以直接在文案方格內修改。你可以修改任何你想修改的地方。我們的系統會自動儲存你的修改，不用擔心你的修改會不見。",
      video: Tutorial04,
    },
    {
      title: "第五步: 重新生成文案",
      description:
        "如果你不滿意你的文案，你可以點擊'生成'按鈕重新生成文案。我們的AI小編會重新生成文案，直到你滿意為止。",
      video: Tutorial05,
    },
    {
      title: "最後: 複製文案",
      description:
        "當你滿意你的文案後，你可以點擊'複製此文案'按鈕複製你整篇文案。你可以在任何地方貼上你的文案，例如Facebook、Instagram、網站等等。",
      video: Tutorial06,
    },
  ];

  const closeHandler = (type) => {
    setOpen(false);
    toast.info("你可以隨時在右上角的'使用教學'按鈕重新開啟教學", {
      toastId: "editor_tutorial",
    });
  };

  useEffect(() => {
    if (open) {
      setStep(0);
    }
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-40 inset-0 overflow-y-auto"
        onClose={() => {
          closeHandler("clicked_outside");
        }}
      >
        <div className="flex h-screen items-center justify-center text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 backdrop-brightness-75 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-lg w-full p-6">
              <div>
                {steps[step].video ? (
                  <video
                    controls={false}
                    autoPlay
                    loop
                    muted
                    playsInline
                    src={steps[step].video}
                    type="video/mp4"
                    className="mx-auto aspect-video object-cover flex items-center justify-center w-full rounded-md bg-neutral-100"
                  />
                ) : (
                  <div className="mx-auto aspect-video object-cover flex items-center justify-center w-full rounded-md bg-neutral-100"></div>
                )}
                <div className="mt-3 sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl leading-6 font-bold text-neutral-900"
                  >
                    {steps[step].title || ""}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-base text-neutral-500 h-32 overflow-y-auto">
                      {steps[step].description || ""}
                    </p>
                  </div>
                  <div className="flex justify-center space-x-2">
                    {Array.from({ length: steps.length }, (_, index) => (
                      <button
                        key={index}
                        className={`h-2 w-2 focus:outline-none rounded-full transition-all mx-1 ${
                          step === index ? "bg-black" : "bg-neutral-300"
                        }`}
                        onClick={() => setStep(index)}
                      ></button>
                    ))}
                  </div>
                </div>
              </div>
              <div className="mt-5 grid grid-cols-2 gap-3 grid-flow-row-dense">
                {step !== steps.length - 1 ? (
                  <>
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-neutral-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-neutral-700 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                      onClick={() => {
                        closeHandler("skip");
                      }}
                    >
                      跳過教學
                    </button>
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:col-start-2 sm:text-sm"
                      onClick={() => {
                        // go to next step
                        if (step === steps.length - 1) return;
                        setStep(step + 1);
                      }}
                    >
                      下一步
                    </button>
                  </>
                ) : (
                  <button
                    type="button"
                    className="w-full col-span-2 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:text-sm"
                    onClick={() => closeHandler("finish")}
                  >
                    完成教學
                  </button>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
