import React, { useEffect } from "react";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import axios from "../../api/axios";
import endpoints from "../../api/endpoints";
import ProcessingBtn from "../../components/btn/ProcessingBtn";

export default function GetPromoCodeComponent({ setCouponHandler }) {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);

  // Get code from url
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  const getPromoCodeInfo = async (code) => {
    try {
      const res = await axios.get(endpoints.REDEEM_URL + "/" + code, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });

      setCouponHandler(res.data);
    } catch (err) {
      if (!err?.response) {
        console.error("No Server res");
      } else {
        // API Error
        let error = err?.response?.data?.error;
        if (error === "NOT_FOUND") {
          toast.info(t("Code not found"));
        } else {
          toast.error(error);
        }
      }
    }
  };

  const submitHandler = async (values, { setErrors, resetForm }) => {
    setLoading(true);
    try {
      const res = await axios.get(endpoints.REDEEM_URL + "/" + values.code, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });

      setCouponHandler(res.data);
    } catch (err) {
      if (!err?.response) {
        console.error("No Server res");
      } else {
        // API Error
        let error = err?.response?.data?.error;
        if (error === "NOT_FOUND") {
          toast.info(t("Code not found"));
        } else {
          toast.error(error);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (code) {
      getPromoCodeInfo(code);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: Yup.object({
      code: Yup.string().trim().required(t("This field is required")),
    }),
    onSubmit: submitHandler,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="space-y-4">
        <div>
          <label
            className="block text-sm font-medium mb-1 text-neutral-800 dark:text-white"
            htmlFor="code"
          >
            {t("Promo code")}
          </label>
          <input
            name="code"
            className="uppercase form-input w-full text-lg sm:text-base dark:text-white dark:bg-neutral-800 dark:border-neutral-600"
            type="string"
            value={formik.code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            autoFocus
          />
        </div>
      </div>
      <div className="flex items-center justify-between mt-6">
        {!isLoading && (
          <button
            type="submit"
            className="btn bg-sky-500 hover:bg-sky-600 text-white w-full"
          >
            {t("Submit")}
          </button>
        )}
        {isLoading && <ProcessingBtn title={t("Submit")} />}
      </div>
    </form>
  );
}
