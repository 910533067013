import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
  const { auth } = useAuth();
  const location = useLocation();

  const isAllowed = (role) => {
    const rank = ["user", "admin"];
    return rank.indexOf(role) >= rank.indexOf(allowedRoles);
  };

  return (
    // Check role
    // auth?.roles?.find(role => allowedRoles?.includes(role))
    isAllowed(auth?.user?.role) ? (
      <Outlet />
    ) : auth?.token ? ( //changed from user to accessToken to persist login after refresh
      <Navigate to="/unauthorized" state={{ from: location }} replace />
    ) : (
      <Navigate to="/signin" state={{ from: location }} replace />
    )
  );
};

export default RequireAuth;
