import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import useLogout from "../../hooks/useLogout";
import AuthHeader from "../../partials/auths/AuthHeader";

import { useTranslation } from "react-i18next";

function Signout() {
  // i18n
  const { t } = useTranslation();

  const logout = useLogout();

  const signOut = async () => {
    await logout();

    navigate("/");
  };

  const navigate = useNavigate();

  useEffect(() => {
    signOut();
  }, []);

  return (
    <div className="flex flex-col overflow-hidden">
      {/* Header */}
      <AuthHeader title={t("Sign Out")} />

      {/* Content area */}
      <div className="relative flex flex-col overflow-y-auto overflow-x-hidden bg-transparent">
        <main>
          <div className="px-4 sm:px-6 lg:px-8 pb-8 sm:py-8 w-full max-w-9xl mx-auto">
            <div className="max-w-2xl m-auto mt-16">
              <div className="text-center px-4">
                <div className="h3 mb-6 dark:text-white">
                  {t("You are logged out!")}
                </div>
                <Link
                  to="/"
                  className="btn bg-sky-500 hover:bg-sky-600 text-white"
                >
                  {t("Back To Home")}
                </Link>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Signout;
