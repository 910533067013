import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";

export default function PlansPanel(props) {
  const { t } = useTranslation();
  const [subscription, setSubscription] = useState();
  const [loaded, setLoaded] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  // Get User subscription status
  const getSubscription = async () => {
    setLoaded(false);
    const response = await axiosPrivate.get(
      endpoints.PROFILE_URL + "/subscription"
    );
    setSubscription(response.data);
    setLoaded(true);
  };

  const renderStauts = (status) => {
    switch (status) {
      case "active":
        return t("Active");
      case "incomplete":
        return t("Incomplete");
      case "incomplete_expired":
        return t("Incomplete Expired");
      case "past_due":
        return t("Past Due");
      case "canceled":
        return t("Canceled");
      case "unpaid":
        return t("Unpaid");
      case "trialing":
        return t("Trialing");
    }
  };

  useEffect(() => {
    getSubscription();
  }, []);

  return (
    <div id="plans" className="border-gray-200 shadow p-4 rounded-lg">
      {/* Plans */}
      <section>
        <div className="mb-8">
          <h2 className="text-2xl text-neutral-800 dark:text-white font-bold mb-4">
            {t("Plan")}
          </h2>
          {loaded ? (
            <div className="space-y-2">
              {subscription.isPremium === false ? (
                <div className="text-sm">
                  {t("You are")}{" "}
                  <strong className="font-medium">{t("Free User")}</strong>.{" "}
                </div>
              ) : (
                <div className="text-sm">
                  {t("You are Premium User. Premium expires on")}{" "}
                  <strong className="font-bold">
                    {moment(subscription.premiumExpires)
                      .local()
                      .format("YYYY-MM-DD")}
                  </strong>
                  .
                </div>
              )}
              {subscription.hasSubscription ? (
                <>
                  <div className="text-sm">
                    {t("Your Current Subscription")}:{" "}
                    <strong className="font-bold text-indigo-600">
                      {subscription.name}
                    </strong>
                  </div>
                  <div className="text-sm">
                    {t("Subscription State")}:{" "}
                    <strong className="font-bold text-indigo-600">
                      {renderStauts(subscription.status)}{" "}
                      {subscription.canceled &&
                        `(${t("Subscription Canceled")})`}
                    </strong>
                  </div>

                  <div className="text-sm">
                    {subscription.canceled
                      ? t("Canceled date")
                      : t("Next billing date")}
                    :{" "}
                    <strong className="font-bold">
                      {moment(
                        subscription?.canceled
                          ? subscription.canceledAtDateUnix
                          : subscription.nextBillingDateUnix
                      )
                        .local()
                        .format("YYYY-MM-DD")}{" "}
                    </strong>
                  </div>
                </>
              ) : (
                <div className="text-sm">
                  {t("You are not enrolled in any subscription plan")}.
                </div>
              )}
            </div>
          ) : (
            `${t("Loading")}...`
          )}
        </div>

        {/* Pricing */}
        <div className="flex items-center space-x-2">
          <Link
            to="/pay"
            className="inline-flex items-center px-8 py-2 border border-transparent text-sm leading-4 font-bold rounded-md shadow-sm text-white bg-sky-500 dark:bg-sky-600 hover:bg-sky-600 dark:hover:bg-sky-700 "
          >
            {!props.user.isPremium
              ? t("Upgrade to Premium")
              : t("Manage your plan")}
          </Link>
          <Link
            to="/redeem"
            className="inline-flex items-center px-8 py-2 border border-transparent text-sm leading-4 font-bold rounded-md shadow-sm text-white bg-sky-500 dark:bg-sky-600 hover:bg-sky-600 dark:hover:bg-sky-700 "
          >
            {t("Redeem your promo code")}
          </Link>
        </div>
      </section>
    </div>
  );
}
