import { useTranslation } from "react-i18next";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";

import CodeCopyBtn from "../markdown/CodeCopyBtn";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import vscDarkPlus from "react-syntax-highlighter/dist/esm/styles/prism/vsc-dark-plus";

// Markdown
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { renderDisplayMarkdown } from "../../utils/Utils";

export default function Component({
  isEdit,
  setIsEdit,
  idx,
  message,
  props,
  copy,
}) {
  const { t } = useTranslation();

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const createDoc = async (message) => {
    // Call post writing endpoint
    try {
      const res = await axiosPrivate.post(
        endpoints.WRITINGS_URL,
        {
          content: message.content,
        },
        {
          signal: controller.signal,
        }
      );

      window.open(`/docs/${res.data.id}`, "rel=noopener noreferrer");
    } catch (error) {
      console.error(error);
    }
  };

  const Pre = ({ children }) => (
    <pre className="blog-pre">
      <CodeCopyBtn>{children}</CodeCopyBtn>
      {children}
    </pre>
  );

  if (isEdit !== idx) {
    return (
      <div>
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          className={`prose overflow-hidden dark:prose dark:text-white overflow-hidden-invert relative text-start text-align-setting`}
          components={{
            pre: Pre,
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || "");
              return !inline && match ? (
                <SyntaxHighlighter
                  children={String(children).replace(/\n$/, "")}
                  style={vscDarkPlus}
                  language={match[1]}
                  PreTag="div"
                  {...props}
                />
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
          }}
        >
          {renderDisplayMarkdown(message.content)}
        </ReactMarkdown>
        {!message.isProcessing && (
          <div className="mt-5 flex items-center space-x-2">
            <button
              type="button"
              onClick={() => {
                createDoc(message);
              }}
              className="inline-flex items-center px-2.5 py-1.5 border border-neutral-300 shadow-sm text-xs font-medium rounded-lg bg-white dark:bg-neutral-800 text-neutral-700 dark:text-white hover:bg-neutral-50 dark:hover:bg-neutral-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z"
                />
              </svg>

              {t("Save to my docs")}
            </button>
            <button
              type="button"
              onClick={() => {
                copy(message.content);
              }}
              className="inline-flex items-center px-2.5 py-1.5 border border-neutral-300 shadow-sm text-xs font-medium rounded-lg bg-white dark:bg-neutral-800 text-neutral-700 dark:text-white hover:bg-neutral-50 dark:hover:bg-neutral-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                />
              </svg>

              {t("Copy")}
            </button>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <>
        <p
          className={`${
            isEdit === idx && "bg-neutral-100 dark:bg-neutral-600 rounded-lg p-4"
          } whitespace-pre-wrap text-neutral-800 dark:text-white relative focus:outline-none focus:border-none`}
          contentEditable={isEdit === idx}
          suppressContentEditableWarning={true}
          onKeyDown={(e) => {
            // if isEdit === idx, when user click esc, then cancel edit
            if (e.key === "Escape" && isEdit === idx) {
              setIsEdit(-1);
              e.target.innerText = message.content;
            }

            // If command + enter, then submit
            if (e.key === "Enter" && e.metaKey) {
              // update chat
              props.editChatHandler(idx, e.target.innerText, false);
              setIsEdit(-1);
            }
          }}
        >
          {message.content}
        </p>

        {isEdit === idx && (
          <div className="sticky bottom-20 mt-5 flex justify-center items-center space-x-4">
            <button
              type="button"
              onClick={(e) => {
                // update chat
                props.editChatHandler(
                  idx,
                  e.target.parentElement.previousSibling.innerText,
                  false
                );
                setIsEdit(-1);
              }}
              className="inline-flex items-center px-8 py-1.5 font-medium rounded-lg bg-sky-600 text-white shadow"
            >
              {t("Save")}
            </button>
            <button
              type="button"
              onClick={(e) => {
                setIsEdit(-1);
                e.target.parentElement.previousSibling.innerText =
                  message.content;
              }}
              className="inline-flex items-center px-8 py-1.5 font-medium rounded-lg bg-black text-white shadow"
            >
              {t("Cancel")}
            </button>
          </div>
        )}
      </>
    );
  }
}
