import React, { useState } from "react";
import Loader from "../../partials/Loader";

import { useTranslation } from "react-i18next";

function WritingCardNew({ setOpen }) {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);

  return (
    <button
      onClick={() => {
        setOpen(true);
      }}
      className="hidden sm:block h-48 group col-span-full sm:col-span-6 xl:col-span-4 rounded-lg bg-white dark:bg-sky-600 ring-1 ring-neutral-900/5 shadow-lg space-y-3 hover:bg-sky-500 hover:ring-sky-500 ease-in-out duration-150"
    >
      <div className="flex flex-col h-full p-10 items-center justify-center group-hover:scale-105 ease-in-out duration-150">
        {isLoading ? (
          <Loader className="w-20 h-20 bg-transparent" />
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-20 h-20 stroke-sky-500 dark:stroke-white group-hover:stroke-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
            />
          </svg>
        )}
        <h1 className="text-2xl font-bold text-sky-500 dark:text-white group-hover:text-white">
          {t("New AI Product Copy")}
        </h1>
      </div>
    </button>
  );
}

export default WritingCardNew;
