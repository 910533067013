export default {
  // Auth
  LOGIN_URL: "/auth/login",
  REGISTER_URL: "/auth/register",
  REFRESH_URL: "/auth/refresh",
  VERIFY_URL: "/auth/verify",
  FORGOT_URL: "/auth/forgot",
  RESETPW_URL: "/auth/reset",
  FORGOTPW_URL: "/auth/forgot",
  VERIFY_RESET_TOKEN_URL: "/auth/reset/verify",
  ERROR_404_URL: "/404",

  // Profile
  PROFILE_URL: "/profile",
  CHANGEPW_URL: "/profile/changePassword",
  SENDEMAILVERIFICATION_URL: "/profile/emailVerification",

  // Writings
  WRITINGS_URL: "/writings",
  WRITINGS_ALL_URL: "/writings/all",

  // Eshops
  ESHOPS_URL: "/eshops",

  // Upload
  UPLOAD_AVATAR: "/upload/avatar",

  // Plan
  PLAN_URL: "/plans",

  // Region
  REGION_URL: "/countries",

  // Languages
  LANGS_URL: "/languages",

  // Feedbacks
  FEEDBACKS_URL: "/feedbacks",

  // Products:
  PRODUCTS_URL: "/products",
  CREDITS_URL: "/credits",

  // Translation
  TRANSLATE: "/ai/translate",

  // Autopilot
  SDXL_URL: "/ai/images",

  // Copywriter
  AI_COPYWRITER_URL:
    "https://cn6hjqshsw6etjbfbq3zl7r5mq0aqevf.lambda-url.ap-southeast-1.on.aws/",

  // AI Chat
  AI_CHAT_URL:
    "https://aziljh4rp6jfquh4gea7j22m7y0jowvh.lambda-url.ap-southeast-1.on.aws/",

  // Users
  USERS_URL: "/users",

  // Chat
  CHAT_URL: "/chat",

  // Bookmark
  BOOKMARK_URL: "/bookmarks",

  // Prompts
  PROMPTS_URL: "/prompts",

  // Google SSO
  GOOGLE_SSO_AUTH: "auth/sso/google",

  // Promocodes
  PROMOCODES_URL: "/promocodes",

  // Redeem
  REDEEM_URL: "/promocodes/redeem",
};
