import { ToastContainer } from "react-toastify";
const ToastMessage = () => {
  // Toastify
  const contextClass = {
    success: "bg-emerald-600",
    error: "bg-rose-600",
    info: "bg-neutral-600",
    warning: "bg-amber-400",
    default: "bg-black",
    dark: "bg-white-600 font-neutral-300",
  };

  return (
    <ToastContainer
      toastClassName={({ type }) =>
        contextClass[type || "default"] +
        " relative flex m-1 p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer shadow-lg w-xl"
      }
      bodyClassName={() =>
        "text-sm flex items-center font-white font-med block p-3 top-5"
      }
      className="sm:top-20"
      closeButton={false}
      position="top-right"
      autoClose={3000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      theme="colored"
    />
  );
};

export default ToastMessage;
