import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDebouncedValue, usePagination } from "@mantine/hooks";
// the hook
import { useTranslation } from "react-i18next";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";
import useAuth from "../../hooks/useAuth";

import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import SearchForm from "../../partials/actions/SearchForm";
import PaginationNumeric from "../../components/PaginationNumeric";
import EshopModal from "../../partials/modals/EshopModal";
import CreateEshopModal from "../../partials/modals/CreateEshopModal";
import EditEshopModal from "../../partials/modals/EditEshopModal";
import DeleteEshopModal from "../../partials/modals/DeleteEshopModal";

import { toast } from "react-toastify";

export default function Eshops() {
  const { t } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  // Get User Profile
  const { auth } = useAuth();
  const user = auth.user;

  // Get Eshops
  const [selectedEshop, setSelectedEshop] = useState();
  const [eshops, setEshops] = useState([]);

  // Get Product
  const [products, setProducts] = useState([]);
  const [selectDocs, setSelectDocs] = useState(false);
  const [checkedIds, setCheckedIds] = useState();
  const [fetching, setFetching] = useState(false);
  const [refetching, setRefetching] = useState("");

  const [searchString, setSearchString] = useState("");
  const [debouncedSearchString] = useDebouncedValue(searchString, 200, {
    leading: true,
  });
  const [page, onChange] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const pagination = usePagination({ total: totalPage, page, onChange });
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);

  // Get Eshop
  const renderEshops = async () => {
    try {
      const res = await axiosPrivate.get(endpoints.ESHOPS_URL, {
        signal: controller.signal,
      });

      setEshops(res?.data);

      console.log(res.data);

      if (res?.data?.length > 0) {
        setSelectedEshop(res?.data[0]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderEshopProducts = async () => {
    try {
      // Check if debouncedSearchString
      let params = {};
      if (debouncedSearchString) {
        params = { filter: debouncedSearchString, fields: "name,description" };
      }

      params = { ...params, page: page, limit: 12 };

      const res = await axiosPrivate.get(
        `${endpoints.ESHOPS_URL}/${selectedEshop.id}/products`,
        {
          signal: controller.signal,
          params: params,
        }
      );

      // Check update page number if total page is less than current page
      if (page > res?.data?.totalPages) {
        onChange(res?.data?.totalPages);
      }

      setProducts(res?.data?.docs);
      setTotalPage(res?.data?.totalPages);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const refetchProducts = async (id) => {
    try {
      setRefetching("loading");
      const res = await axiosPrivate.post(
        `${endpoints.ESHOPS_URL}/${id}/refetch`
      );
      console.log(res.data);

      // Update existing eshops
      const newEshops = eshops.map((eshop) => {
        if (eshop.id === id) {
          return res.data;
        }
        return eshop;
      });

      setEshops(newEshops);

      setRefetching("done");
      setTimeout(() => {
        setRefetching("");
      }, 1000);
    } catch (err) {
      console.log(err);
      setRefetching("");
      toast.error(err);
    }
  };

  useEffect(() => {
    if (selectedEshop && selectedEshop?.id) {
      // Render Writings
      renderEshopProducts();
    }
  }, [selectedEshop, debouncedSearchString, page]);

  useEffect(() => {
    renderEshops();
  }, []);

  // if selectDocs is true and when user press esc key, set selectDocs to false
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        setSelectDocs(false);
      }
    };

    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [selectDocs]);

  const createWriting = async (product) => {
    try {
      const res = await axiosPrivate.post(
        endpoints.WRITINGS_URL,
        { product },
        {
          signal: controller.signal,
        }
      );

      navigate(`/docs/${res.data.id}`);
    } catch (error) {
      console.error(error);
    }
  };

  const renderLoadingWriting = () => {
    // Generate 10 loading writing

    const arr = new Array(12).fill(0);

    return arr.map((item, idx) => {
      return (
        <div
          key={`loading-writing-${idx}`}
          className="group inline-flex flex-col text-left w-auto h-full"
        >
          <div className="relative">
            <div className="relative w-full bg-neutral-200 dark:bg-neutral-800 animate-pulse rounded-md overflow-hidden aspect-w-1 aspect-h-1 flex items-center justify-center">
              <div className="w-full h-full object-center aspect-square object-cover group-hover:brightness-50 transition-all duration-300 ease-in-out" />
            </div>
            <div className="mt-2 h-20"></div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="flex sm:overflow-hidden bg-white dark:bg-neutral-900">
      {/* Sidebar */}
      <Sidebar
        user={user}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />

      {/* Content area */}
      <div className="md:h-screen relative flex flex-col flex-1 sm:overflow-y-auto sm:overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
          title={t("My Copywriting")}
        />

        <main className="pb-20">
          {/* New Selection Modal */}
          <EshopModal
            open={open}
            setOpen={setOpen}
            createOpen={createOpen}
            setCreateOpen={setCreateOpen}
            setSelectedEshop={setSelectedEshop}
            eshops={eshops}
            setEshops={setEshops}
          />
          <CreateEshopModal
            open={createOpen}
            setOpen={setCreateOpen}
            setSelectedEshop={setSelectedEshop}
            eshops={eshops}
            setEshops={setEshops}
          />
          <EditEshopModal
            open={editOpen}
            setOpen={setEditOpen}
            selectedEshop={selectedEshop}
            setSelectedEshop={setSelectedEshop}
            eshops={eshops}
            setEshops={setEshops}
          />

          <DeleteEshopModal
            open={deleteOpen}
            setOpen={setDeleteOpen}
            selectedEshop={selectedEshop}
            setSelectedEshop={setSelectedEshop}
            eshops={eshops}
            setEshops={setEshops}
          />

          <div className="px-4 sm:px-6 lg:px-8 pb-8 sm:py-8 w-full max-w-9xl mx-auto">
            {/* Empty Trash Banner */}

            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="sticky mb-4 sm:mb-0">
                <div className="text-2xl md:text-3xl text-neutral-800 dark:text-white font-bold flex items-center">
                  <div>{t("My Eshop Products")} ✨</div>
                </div>
                {selectedEshop && (
                  <div className="flex flex-col space-y-2">
                    <a
                      href={selectedEshop.url}
                      className="mt-2 font-medium text-sky-500"
                      target="_blank"
                    >
                      {selectedEshop.name} {selectedEshop.url} (
                      {t("Total Products")}: {selectedEshop.productCount})
                    </a>
                    <div className="flex space-x-2 items-center">
                      <button
                        className={`whitespace-pre rounded-full ${
                          refetching === "loading"
                            ? "bg-amber-500"
                            : "bg-emerald-500"
                        } text-sm px-3 py-1 font-medium text-white transition-all`}
                        onClick={() => {
                          refetchProducts(selectedEshop.id);
                        }}
                      >
                        {refetching === "" && t("Refetch Product")}
                        {refetching === "loading" && t("Refetching")}
                        {refetching === "done" && t("Done")}
                      </button>
                      <button
                        className="whitespace-pre rounded-full bg-neutral-500 text-sm px-3 py-1 font-medium text-white transition-all"
                        onClick={() => {
                          setEditOpen(true);
                        }}
                      >
                        {t("Update E-Shop")}
                      </button>
                      <button
                        className="whitespace-pre rounded-full bg-rose-500 text-sm px-3 py-1 font-medium text-white transition-all"
                        onClick={() => {
                          setDeleteOpen(true);
                        }}
                      >
                        {t("Delete Shop")}
                      </button>
                    </div>
                  </div>
                )}
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-between sm:justify-start gap-2">
                {/* Search form */}
                <SearchForm value={searchString} setValue={setSearchString} />
                {/* Filter button */}
                <div className="flex items-center space-x-2">
                  {!selectDocs ? (
                    <>
                      <button
                        onClick={() => {
                          setOpen(true);
                        }}
                        className="btn bg-sky-500 hover:bg-sky-600 dark:bg-sky-600 dark:hover:bg-sky-700 text-white rounded-lg gap-2"
                      >
                        <span className="sm:block">{t("Select E-Shop")}</span>
                      </button>
                      {/* <button
                        onClick={() => {
                          setCheckedIds([]);
                          setSelectDocs(!selectDocs);
                        }}
                        className="btn bg-rose-500 hover:bg-rose-600 dark:bg-rose-600 dark:hover:bg-rose-700 text-white rounded-lg"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5 text-white"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          />
                        </svg>
                      </button> */}
                    </>
                  ) : (
                    <button
                      onClick={() => {
                        setSelectDocs(!selectDocs);
                      }}
                      className="btn bg-amber-500 hover:bg-amber-600 dark:bg-amber-600 dark:hover:bg-amber-700 text-white rounded-lg"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={5}
                        stroke="currentColor"
                        className="w-5 h-5 text-white"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  )}
                  {selectDocs && (
                    <button
                      onClick={async () => {
                        setFetching(true);
                        if (checkedIds.length > 0) {
                          for (const id of checkedIds) {
                            // await moveToTrash(id);
                          }
                        }

                        setSelectDocs(!selectDocs);
                        setFetching(false);
                      }}
                      disabled={fetching}
                      className={`btn ${
                        !fetching
                          ? "bg-rose-500 hover:bg-rose-600 dark:bg-rose-600 dark:hover:bg-rose-700"
                          : "bg-rose-400 dark:bg-rose-500 cursor-wait"
                      } text-white rounded-lg`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5 text-white"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </button>
                  )}
                </div>
              </div>
            </div>

            {/* Cards */}
            {/* <div className="grid grid-cols-12 gap-6"> */}
            <div className="grid gap-y-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-8">
              {products &&
                products.map((product, idx) => {
                  return (
                    <button
                      onClick={() => {
                        createWriting(product);
                      }}
                      key={product.id}
                      className="group inline-flex flex-col text-left w-auto"
                    >
                      <div className="relative">
                        <div className="relative w-full bg-neutral-200 rounded-md overflow-hidden aspect-w-1 aspect-h-1 flex items-center justify-center">
                          <img
                            src={product.coverImg}
                            alt={product.name}
                            className="w-full h-full object-center aspect-square object-cover group-hover:brightness-50 transition-all duration-300 ease-in-out"
                          />
                          <div className="absolute text-lg invisible text-white font-bold group-hover:visible">
                            {t("Generate AI Copywriting")}
                          </div>
                        </div>
                        <div className="mt-2">
                          <p className="text-sm text-neutral-500">
                            {product.color}
                          </p>
                          <h3 className="mt-1 font-semibold text-neutral-900 dark:text-white">
                            <span>
                              <span className="absolute inset-0 line-clamp-2" />
                              {product.name}
                            </span>
                          </h3>
                          <p className="mt-1 text-medium text-neutral-900 dark:text-white">
                            {product.variants
                              .map((variant) => `$${variant.price}`)
                              .join(" ")}
                          </p>
                        </div>
                      </div>
                    </button>
                  );
                })}

              {/* Empty State */}
              {isLoading ? (
                renderLoadingWriting()
              ) : selectedEshop === null ? (
                <div className="group inline-flex flex-col w-auto col-span-12 text-center text-neutral-600 dark:text-neutral-400 py-24">
                  {t("Please create or select a E-Shop")}
                </div>
              ) : (
                products.length === 0 && (
                  <div className="group inline-flex flex-col w-auto col-span-12 text-center text-neutral-600 dark:text-neutral-400 py-24">
                    {t("Empty")}
                  </div>
                )
              )}
            </div>

            {/* Pagination */}
            {totalPage > 1 && (
              <div className="mt-8">
                <PaginationNumeric
                  pagination={pagination}
                  page={page}
                  onChange={onChange}
                />
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}
