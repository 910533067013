import React from "react";
import ReactDOM from "react-dom/client";
import "./css/style.scss";
import App from "./App";
import { AuthProvider } from "./context/AuthProvider";
import { ModalProvider } from "./context/ModalProvider";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import ToastMessage from "./partials/ui/ToastMessage";

import ReactGA from "react-ga4";

import "./i18n";

ReactGA.initialize("G-T10YSKY6GT");
ReactGA.send("pageview");

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ToastMessage />
    <BrowserRouter>
      <AuthProvider>
        <ModalProvider>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </ModalProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
