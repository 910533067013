import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";
import { useDebouncedValue } from "@mantine/hooks";
import { useTranslation } from "react-i18next";

import useAuth from "../../hooks/useAuth";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import CouponTable from "../../partials/coupons/CouponTable";
import SearchForm from "../../partials/actions/SearchForm";
import CreateCouponModal from "../../partials/coupons/CreateCouponModal";
import EditCouponModal from "../../partials/coupons/EditCouponModal";
import DeleteCouponModal from "../../partials/coupons/DeleteCouponModal";
import PaginationClassic from "../../components/PaginationClassic";

export default function AllCoupons() {
  const { t } = useTranslation();

  // Get User Profile
  const { auth } = useAuth();
  const user = auth?.user;

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [coupons, setCoupon] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState();
  const [filteredCoupon, setFilteredCoupon] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [searchString, setSearchString] = useState("");
  const [debouncedSearchString] = useDebouncedValue(searchString, 200, {
    leading: true,
  });
  const [isFetching, setIsFetching] = useState(true);

  const [page, onChange] = useState(1);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  // Select Coupon
  const selectCouponHandler = (coupon) => {
    setSelectedCoupon(coupon);
    setIsEditModalOpen(true);
  };

  // Render Stories
  const getAllCoupon = async () => {
    try {
      setIsFetching(true);
      // Check if debouncedSearchString
      let params = {};
      if (debouncedSearchString) {
        console.log("Search String: " + debouncedSearchString);
        params = { filter: debouncedSearchString, fields: "title,content" };
      }

      params = { ...params, locale: navigator.language, offset, limit };

      const res = await axiosPrivate.get(endpoints.PROMOCODES_URL, {
        signal: controller.signal,
        params: params,
      });

      // Check update page number if total page is less than current page
      if (page > res?.data?.totalPages) {
        onChange(res?.data?.totalPages);
      }

      setCoupon(res?.data.docs);
      setFilteredCoupon(res?.data.docs);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (debouncedSearchString.trim() === "") {
      setFilteredCoupon(coupons);
    } else {
      setFilteredCoupon(
        coupons.filter((coupon) => {
          return coupon.code.match(new RegExp(debouncedSearchString, "i"));
        })
      );
    }
  }, [debouncedSearchString]);

  useEffect(() => {
    getAllCoupon();
  }, []);

  return (
    <div className="flex overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        user={user}
      />

      {/* Content area */}
      <div className="md:h-screen relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header
          title="All Coupons"
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
        />

        {/* Create Coupon Modal */}
        <CreateCouponModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          refresh={getAllCoupon}
        />

        {/* Edit Coupon Modal */}
        <EditCouponModal
          coupon={selectedCoupon}
          isModalOpen={isEditModalOpen}
          setIsModalOpen={setIsEditModalOpen}
          refresh={getAllCoupon}
        />

        {/* Delete Coupon Modal */}
        <DeleteCouponModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          deleteIds={selectedItems}
          setSelectedItems={setSelectedItems}
          refresh={getAllCoupon}
        />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 pb-20 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <h1 className="text-2xl md:text-3xl text-black dark:text-white font-bold mb-8">
              {t("All Coupons")} ✨
            </h1>

            <div className="sm:flex sm:justify-between sm:items-center sm:mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0 grid grid-flow-col sm:auto-cols-max justify-between sm:justify-start gap-2">
                {/* Search form */}
                <SearchForm value={searchString} setValue={setSearchString} />
                {/* New button */}
                <div className="flex items-center space-x-2">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsModalOpen(true);
                    }}
                    className="btn group bg-sky-500 hover:bg-sky-600 dark:bg-sky-600 dark:hover:bg-sky-500 text-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 mr-2 stroke-white-500"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                      />
                    </svg>
                    {t("Create")}
                  </button>

                  {selectedItems.length > 0 && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsDeleteModalOpen(!isDeleteModalOpen);
                      }}
                      className="btn group bg-rose-500 hover:bg-rose-600 dark:bg-rose-600 dark:hover:bg-rose-500 text-white"
                    >
                      <svg
                        className="w-4 h-4 fill-current shrink-0"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5 7h2v6H5V7zm4 0h2v6H9V7zm3-6v2h4v2h-1v10c0 .6-.4 1-1 1H2c-.6 0-1-.4-1-1V5H0V3h4V1c0-.6.4-1 1-1h6c.6 0 1 .4 1 1zM6 2v1h4V2H6zm7 3H3v9h10V5z" />
                      </svg>
                      <span className="ml-2">{t("Delete")}</span>
                    </button>
                  )}
                </div>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-between gap-2">
                {/* Pagination */}
                <PaginationClassic
                  offset={offset}
                  setOffset={setOffset}
                  limit={limit}
                  setLimit={setLimit}
                  result={coupons.length}
                  isFetching={isFetching}
                />
              </div>
            </div>

            {/* Table */}
            <CouponTable
              selectedItems={handleSelectedItems}
              selectCouponHandler={selectCouponHandler}
              coupons={filteredCoupon}
            />
          </div>
        </main>
      </div>
    </div>
  );
}
