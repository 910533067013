import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { toast } from "react-toastify";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";
import Danger from "../../components/alert/Danger";
import ProcessingBtn from "../../components/btn/ProcessingBtn";

export default function DeleteCouponModal({
  isModalOpen,
  setIsModalOpen,
  deleteIds,
  setSelectedItems,
  refresh,
}) {
  function closeModal() {
    setIsModalOpen(false);
  }

  const [warning, setWarning] = useState();
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const [isLoading, setLoading] = useState(false);

  const deleteCouponById = async (id) => {
    await axiosPrivate.delete(endpoints.PROMOCODES_URL + "/" + id, {
      signal: controller.signal,
    });
  };

  const deleteHandler = async () => {
    setLoading(true);
    try {
      await Promise.all(deleteIds.map((id) => deleteCouponById(id)));

      // Refresh
      await refresh();

      // Toast
      toast.success(`${deleteIds.length} Coupon(s) deleted successfully`);

      // Close the modal
      setIsModalOpen(!isModalOpen);
      setSelectedItems([]);
    } catch (err) {
      if (!err?.response) {
        console.log("No Server res");
      } else {
        // API Error
        let error = err?.response?.data?.errors?.msg;

        console.log(err.code);

        setWarning(<Danger title="Invalid Input" />);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={closeModal}>
          {/* The backdrop, rendered as a fixed sibling to the panel container */}
          <div
            className="fixed inset-0 backdrop-blur-sm backdrop-brightness-75 transition-opacity"
            aria-hidden="true"
          />
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl text-black dark:text-white bg-white dark:bg-neutral-900 p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6"
                  >
                    Delete Coupon
                  </Dialog.Title>
                  {/* Warning */}
                  {warning !== null && <div className="py-2">{warning}</div>}

                  <div>
                    Are you sure you want to delete these {deleteIds.length}{" "}
                    coupon codes?
                  </div>

                  <div className="flex items-center justify-between mt-6 space-x-4">
                    <button
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsModalOpen(!isModalOpen);
                      }}
                      className="btn bg-slate-500 hover:bg-slate-600 dark:bg-slate-600 dark:hover:bg-slate-700 text-white w-full"
                    >
                      Cancel
                    </button>
                    {!isLoading && (
                      <button
                        onClick={() => {
                          deleteHandler();
                        }}
                        autoFocus
                        className="btn bg-rose-500 hover:bg-rose-600 dark:bg-rose-600 dark:hover:bg-rose-700 text-white w-full"
                      >
                        Yes, delete them
                      </button>
                    )}
                    {isLoading && (
                      <ProcessingBtn title="Yes, delete them" color="rose" />
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
