import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDebouncedValue, usePagination } from "@mantine/hooks";
// the hook
import { useTranslation } from "react-i18next";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import endpoints from "../../api/endpoints";
import useAuth from "../../hooks/useAuth";

import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import SearchForm from "../../partials/actions/SearchForm";
import WritingCard from "../../partials/writing/WritingCard";
import WritingCardNew from "../../partials/writing/WritingCardNew";
import PaginationNumeric from "../../components/PaginationNumeric";
import WelcomeBanner from "../../partials/dashboard/WelcomeBanner";
import CreateWritingOrChatModal from "../../partials/modals/CreateWritingOrChatModal";

export default function Docs() {
  const { t } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  // Get User Profile
  const { auth } = useAuth();
  const user = auth.user;

  // Get Writings
  const [selectDocs, setSelectDocs] = useState(false);
  const [checkedIds, setCheckedIds] = useState();
  const [fetching, setFetching] = useState(false);
  const [writings, setWritings] = useState();
  const [searchString, setSearchString] = useState("");
  const [debouncedSearchString] = useDebouncedValue(searchString, 200, {
    leading: true,
  });
  const [page, onChange] = useState(1);
  const [totalPage, setTotalPage] = useState(10);
  const pagination = usePagination({ total: totalPage, page, onChange });
  const [open, setOpen] = useState(false);

  // Render Writings
  const renderWritings = async () => {
    try {
      // Check if debouncedSearchString
      let params = {};
      if (debouncedSearchString) {
        params = { filter: debouncedSearchString, fields: "title,content" };
      }

      params = { ...params, page: page, limit: page === 1 ? 11 : 12 };

      const res = await axiosPrivate.get(endpoints.WRITINGS_URL, {
        signal: controller.signal,
        params: params,
      });

      // Check update page number if total page is less than current page
      if (page > res?.data?.totalPages) {
        onChange(res?.data?.totalPages);
      }

      setWritings(res?.data?.docs);
      setTotalPage(res?.data?.totalPages);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Move writing to trash
  const moveToTrash = async (id) => {
    try {
      await axiosPrivate.patch(
        endpoints.WRITINGS_URL + "/" + id,
        { isDeleted: true },
        { signal: controller.signal }
      );
      renderWritings();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // Render Writings
    renderWritings();
  }, [debouncedSearchString, page]);

  const createWriting = async () => {
    try {
      const res = await axiosPrivate.post(endpoints.WRITINGS_URL, {
        signal: controller.signal,
      });

      navigate(`/docs/${res.data.id}`);
    } catch (error) {
      console.error(error);
    }
  };

  // if selectDocs is true and when user press esc key, set selectDocs to false
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        setSelectDocs(false);
      }
    };

    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [selectDocs]);

  const renderLoadingWriting = () => {
    // Generate 10 loading writing

    const arr = new Array(8).fill(0);

    return arr.map((item, idx) => {
      return (
        <div
          key={`loading-writing-${idx}`}
          className="animate-pulse h-20 sm:h-48 group col-span-full sm:col-span-6 xl:col-span-4 rounded-lg bg-white dark:bg-neutral-800 ring-1 ring-neutral-900/5 shadow-lg space-y-3 hover:ring-4 hover:ring-sky-500 ease-in-out duration-150"
        ></div>
      );
    });
  };

  return (
    <div className="flex sm:overflow-hidden bg-white dark:bg-neutral-900">
      {/* Sidebar */}
      <Sidebar
        user={user}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />

      {/* Content area */}
      <div className="md:h-screen relative flex flex-col flex-1 sm:overflow-y-auto sm:overflow-x-hidden">
        {/*  Site header */}
        <Header
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
          title={t("My Copywriting")}
        />

        <main className="pb-20">
          {/* New Selection Modal */}
          <CreateWritingOrChatModal
            open={open}
            setOpen={setOpen}
            createWriting={createWriting}
          />

          <div className="px-4 sm:px-6 lg:px-8 pb-8 sm:py-8 w-full max-w-9xl mx-auto">
            {/* Empty Trash Banner */}
            <div className="relative rounded-sm mb-8">
              <WelcomeBanner user={user} createWriting={createWriting} />
            </div>

            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="sticky mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-neutral-800 dark:text-white font-bold">
                  {t("My Copywriting")} ✨
                </h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-between sm:justify-start gap-2">
                {/* Search form */}
                <SearchForm value={searchString} setValue={setSearchString} />
                {/* Filter button */}
                {/* <FilterButton align="right" /> */}
                <div className="flex items-center space-x-2">
                  {!selectDocs ? (
                    <>
                      <button
                        onClick={() => {
                          createWriting();
                          // setOpen(true);
                        }}
                        className="btn bg-sky-500 hover:bg-sky-600 dark:bg-sky-600 dark:hover:bg-sky-700 text-white rounded-lg gap-2"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5 text-white"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                          />
                        </svg>
                        <span className="hidden sm:block">
                          {t("New AI Product Copy")}
                        </span>
                      </button>
                      <button
                        onClick={() => {
                          setCheckedIds([]);
                          setSelectDocs(!selectDocs);
                        }}
                        className="btn bg-rose-500 hover:bg-rose-600 dark:bg-rose-600 dark:hover:bg-rose-700 text-white rounded-lg"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5 text-white"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          />
                        </svg>
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={() => {
                        setSelectDocs(!selectDocs);
                      }}
                      className="btn bg-amber-500 hover:bg-amber-600 dark:bg-amber-600 dark:hover:bg-amber-700 text-white rounded-lg"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={5}
                        stroke="currentColor"
                        className="w-5 h-5 text-white"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  )}
                  {selectDocs && (
                    <button
                      onClick={async () => {
                        setFetching(true);
                        if (checkedIds.length > 0) {
                          for (const id of checkedIds) {
                            await moveToTrash(id);
                          }
                        }

                        setSelectDocs(!selectDocs);
                        setFetching(false);
                      }}
                      disabled={fetching}
                      className={`btn ${
                        !fetching
                          ? "bg-rose-500 hover:bg-rose-600 dark:bg-rose-600 dark:hover:bg-rose-700"
                          : "bg-rose-400 dark:bg-rose-500 cursor-wait"
                      } text-white rounded-lg`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5 text-white"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </button>
                  )}
                </div>
              </div>
            </div>

            {/* Cards */}
            <div className="grid grid-cols-12 gap-6">
              {/* Draft Card */}
              {page === 1 ? <WritingCardNew setOpen={createWriting} /> : null}
              {writings &&
                writings.map((writing, idx) => {
                  return (
                    <WritingCard
                      key={`writing-${idx}`}
                      writing={writing}
                      selectDocs={selectDocs}
                      isLoading={isLoading}
                      checkedIds={checkedIds}
                      setCheckedIds={setCheckedIds}
                    />
                  );
                })}

              {/* Empty State */}
              {isLoading
                ? renderLoadingWriting()
                : writings.length === 0 && (
                    <div className="sm:hidden col-span-12 text-center text-neutral-600 dark:text-neutral-400 py-24">
                      {t("Empty")}
                    </div>
                  )}
            </div>

            {/* Pagination */}
            {totalPage > 1 && (
              <div className="mt-8">
                <PaginationNumeric
                  pagination={pagination}
                  page={page}
                  onChange={onChange}
                />
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}
