import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import endpoints from "../../api/endpoints";

import ProcessingBtn from "../../components/btn/ProcessingBtn";

import AuthHeader from "../../partials/auths/AuthHeader";
import SignInComponent from "../../partials/auths/SignInComponent";
import GoogleSSOComponent from "../../partials/auths/GoogleSSOComponent";
import GetPromoCodeComponent from "../../partials/redeem/GetPromoCodeComponent";
import useRefreshToken from "../../hooks/useRefreshToken";
import SignUpComponent from "../../partials/auths/SignUpComponent";
import RedeemSuccessModal from "../../partials/modals/RedeemSuccessModal";
import RedeemErrorModal from "../../partials/modals/RedeemErrorModal";

export default function RedeemHome() {
  // i18n
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const user = auth?.user;

  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [mode, setMode] = useState("signin");
  const [coupon, setCoupon] = useState();
  const refresh = useRefreshToken();

  const redeemPromoCodeHandler = async () => {
    try {
      setErrorCode("");
      setLoading(true);

      await axiosPrivate.post(endpoints.REDEEM_URL + "/" + coupon.id, {
        withCredentials: true,
      });

      // Refresh user
      refresh();
    } catch (err) {
      setErrorCode(err.response.data.error);
    } finally {
      setOpen(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  return (
    <main className="bg-white dark:bg-neutral-900 dark:text-white">
      <div className="relative md:flex">
        <div className="w-full">
          <RedeemSuccessModal
            open={open && errorCode === ""}
            setOpen={setOpen}
          />
          <RedeemErrorModal
            open={open && errorCode !== ""}
            setOpen={setOpen}
            errorCode={errorCode}
          />
          <div className="min-h-screen h-full flex flex-col after:flex-1">
            {/* Header */}
            <AuthHeader title={t("Redeem your promo code")} />

            <div className="max-w-sm mx-auto px-2 sm:px-4 py-8 w-96 space-y-4">
              <h1 className="text-3xl text-neutral-800 dark:text-white font-bold mb-6">
                {coupon ? t("Congratulations") : t("Redeem your promo code")} ✨
              </h1>

              {/* Redeem Input box */}
              {!coupon ? (
                <GetPromoCodeComponent setCouponHandler={setCoupon} />
              ) : (
                <div>
                  <div className="mt-2 rounded-md bg-sky-50 text-sky-700 p-4">
                    <div className="flex items-center">
                      {t("Your code")}:
                      <span className="ml-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium font-mono bg-black text-white">
                        {coupon?.code}
                      </span>
                    </div>
                    <div className="text-xl">
                      {coupon?.premiumDays}
                      {t("Days Articue Premium Pass")}
                    </div>
                    {coupon?.expireOn && (
                      <div className="text-sm">
                        *{t("This code will expire on")}
                        {moment(coupon?.expireOn).format("YYYY-MM-DD")}
                      </div>
                    )}
                  </div>
                </div>
              )}

              {coupon && !user && (
                <>
                  <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
                    <div className="flex">
                      <p className="text-sm text-yellow-700">
                        {t("Please sign in to redeem your promo code")}
                      </p>
                    </div>
                  </div>

                  {/* Sign In */}
                  {mode === "signin" ? (
                    <SignInComponent />
                  ) : (
                    <SignUpComponent />
                  )}

                  {/* Footer */}
                  <div className="pt-5 mt-6 border-t border-neutral-200 space-y-6">
                    <div className="flex items-center justify-between">
                      <GoogleSSOComponent />
                    </div>
                    <div className="flex justify-center text-neutral-800 dark:text-white text-sm gap-x-2">
                      {mode === "signin"
                        ? t("Don’t you have an account?")
                        : t("Already have an account?")}
                      {mode === "signin" ? (
                        <button
                          className="font-medium text-sky-500 hover:text-sky-600"
                          onClick={() => setMode("signup")}
                        >
                          {t("Sign Up")}
                        </button>
                      ) : (
                        <button
                          className="font-medium text-sky-500 hover:text-sky-600"
                          onClick={() => setMode("signin")}
                        >
                          {t("Sign In")}
                        </button>
                      )}
                    </div>
                  </div>
                </>
              )}

              {coupon && user && (
                <>
                  <div className="text-xl font-bold">
                    {t("Promo code for")}:
                  </div>
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-sky-100 text-sky-800">
                    {user.email}
                  </span>
                  {/* Redeem now */}
                  <div className="flex justify-center">
                    {!isLoading ? (
                      <button
                        className="btn bg-sky-500 hover:bg-sky-600 text-white w-full"
                        onClick={redeemPromoCodeHandler}
                      >
                        {t("Redeem Now")}
                      </button>
                    ) : (
                      <ProcessingBtn title={t("Redeem Now")} />
                    )}
                  </div>
                </>
              )}
              <div className="flex items-center justify-center mt-6">
                <div className="mr-1">
                  <Link
                    className="text-sm underline hover:no-underline text-neutral-800 dark:text-white"
                    to="/"
                  >
                    {t("Back")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
